import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Navbar from "./Navbar";
import Contenido from "./Contenido";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

export const Home = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div>
      <Navbar
        name={currentUser.displayName}
        photo={currentUser.photoURL}
        caption="aja"
      />
      <Sidebar />
      <div>
        <Contenido />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};
