import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import TopUsers from "./estadisticas/TopUsers";
import TopAsignaciones from "./estadisticas/TopAsignaciones";
import TopAreas from "./estadisticas/TopAreas";
function Areas() {
  return (
    <div>
      <Navbar />
      <Sidebar />
        <div className="statsDiv">
          <TopUsers />
          <TopAsignaciones />
          <TopAreas />
        </div>

      <Footer />
    </div>
  );
}

export default Areas;
