import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import RbAgents from './registerBusiness/RbAgents'

function Colaboradores() {

    
  return (
    <div>
    <Navbar/>
    <Sidebar />
    <RbAgents />
    <Footer />
  </div>
  )
}

export default Colaboradores