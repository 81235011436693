import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faComputer } from "@fortawesome/free-solid-svg-icons";
import { faTrafficLight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import moment from "moment/moment";
import AddUserChat from "./modaChatlMenu/AddUserChat";
import CloseTicket from "./modaChatlMenu/CloseTicket";
import { Draggable } from "react-beautiful-dnd";
import { db } from "./firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { sendMail } from "./SendMail";
import Modal from "react-modal";

Modal.setAppElement("#root");

function TicketProceso(props) {
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const { userType: userTypeFromContext } = useContext(AuthContext);
  const [userType, setUserType] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const hoy = moment().format("DD-MM-YYYY");
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const creador = props.from;

  useEffect(() => {
    setUserType(userTypeFromContext);
  }, []);

  useEffect(() => {
    setUserType(userTypeFromContext);
    if (userTypeFromContext === "admin") {
    }
  }, [userTypeFromContext]);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
  };

  const obtenerDiferenciaEnDias = (hoy, fechaVencimiento) => {
    const fecha1 = new Date(hoy.split("-").reverse().join("-"));
    const fecha2 = new Date(fechaVencimiento.split("-").reverse().join("-"));
    const diferenciaEnMilisegundos = fecha2 - fecha1;
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

    return Math.round(diferenciaEnDias);
  };

  const handleDetails = () => {
    navigate("/TicketDetails", {
      state: {
        title: props.title,
        businessName: props.businessName,
        priority: props.priority,
        vencimiento: props.vencimiento,
        creacion: props.creacion,
        department: props.department,
        status: props.status,
        emailClient: props.emailCreator,
        emailBusiness: props.emailBusiness,
        descripcion: props.descripcion,
        context: props.context,
        skill: props.skill,
        plan: props.plan,
        creator: props.creator,
        id: props.id,
        uid: currentUser.uid,
        to: props.to,
        from: props.from,
        horaCreacion: props.horaCreacion,
      },
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(db, "business", businessEmail, "tickets", props.id));
      console.log("Ticket eliminado con éxito"); // Para propósitos de depuración
      setIsDeleteModalOpen(false); // Suponiendo que usas un estado para controlar la visibilidad del modal
    } catch (error) {
      console.error("Error al eliminar el ticket: ", error);
    } finally {
      sendMail(
        props.from,
        "Ticket Eliminado",
        props.creator +
          ", el ticket: " +
          props.title +
          ", ha sido eliminado por el administrador del sistema " +
          currentUser.displayName +
          ", este proceso es irreversible, por lo que si quieres recuperarlo, tendrás que crear un ticket nuevo."
      );
    }
  };

  const diasRestantes = obtenerDiferenciaEnDias(hoy, props.vencimiento);

  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="ticket"
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: "grab",
          }}
        >
          {menuVisible && (
            <div className="dropdown-menu-proceso">
              <div onClick={handleDetails} className="dropdown-item">
                <FontAwesomeIcon icon={faPencil} />
              </div>
              <div onClick={handleOpenModal} className="dropdown-item">
                <FontAwesomeIcon icon={faShare} />
              </div>
              {userType === "admin" ||
                (creador === currentUser.email && (
                  <div
                    onClick={() => setIsDeleteModalOpen(true)}
                    className="dropdown-item"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                ))}
            </div>
          )}
          <div className="estructuraTicket">
            <div
              className={
                diasRestantes >= 0
                  ? "vencimientoTicketProceso"
                  : "vencidoTicketProceso"
              }
            >
              <span className="venceTituloProceso">
                {diasRestantes >= 0 ? "Vence En:" : "Venció Hace:"}
              </span>
              <span className="venceFechaProceso">{diasRestantes}</span>
              <span className="venceSubtituloProceso">DÍAS</span>
            </div>
            <div className="infoTicketProceso">
              <div className="tituloTicketProceso">
                <span>{props.title}</span>
              </div>
              <div className="rowTicket">
                <div className="columnTicket">
                  <div className="ticketElementProceso">
                    <FontAwesomeIcon color="#FF7A00" icon={faBriefcase} />
                    {props.creator}
                  </div>
                  <div className="ticketElementProceso">
                    <FontAwesomeIcon color="#FF7A00" icon={faTrafficLight} />
                    {props.priority}
                  </div>
                  <div className="ticketElementProceso">
                    <FontAwesomeIcon color="#FF7A00" icon={faComputer} />
                    {props.skill}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddUserChat
            openModal={openModal}
            ticketId={props.id}
            handleCloseModal={handleCloseModal}
          />

          {/* MODAL PARA ELIMINAR TICKETS */}
          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={isDeleteModalOpen}
            onRequestClose={() => setIsDeleteModalOpen(false)}
          >
            <h2 className="titleModal">Eliminar Ticket</h2>
            <div className="descriptionModal">
              <p>
                ¿Seguro que desea Eliminar Ticket? (esta acción no se puede
                deshacer)
              </p>
            </div>
            <div className="row">
              <button className="btnCloseModal" onClick={handleConfirmDelete}>
                Sí
              </button>
              <button
                className="btnCloseModal"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </Modal>
        </div>
      )}
    </Draggable>
  );
}

export default TicketProceso;