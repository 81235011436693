import React from 'react'
import { AuthContext } from '../context/AuthContext'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { useContext } from 'react'
import RbClientsAgents from './registerBusiness/RbClientsAgents'

function Parametrizacion() {

    const { currentUser } = useContext(AuthContext);
    
  return (
    <div>
    <Navbar
      name={currentUser.displayName}
      photo={currentUser.photoURL}
      caption="Img Perfil"
    />

    <Sidebar />
    <RbClientsAgents />
    <Footer />
  </div>
  )
}

export default Parametrizacion