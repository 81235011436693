import React, { useEffect, useRef, useState, useContext } from "react";
import { getTitleGPT } from "../IA/Gpt";
import { createTicketNew } from "../Functions";
import { AuthContext } from "../../context/AuthContext";

function PopUp({ onClose }) {
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const [mensaje, setMensaje] = useState("");
  const textAreaRef = useRef(null);
  const popupContainerRef = useRef(null); // Ref para el contenedor del popup
  const [title, setTitle] = useState("");
  const {
    currentUser,
    category,
    department,
    businessEmail,
    name,
    businessName,
  } = useContext(AuthContext);
  const [fechaVencimientoCorregida, setFechaVencimientoCorregida] =
    useState("");

  // Definir getCurrentDate dentro del componente asegura su disponibilidad
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  function getHoraActual() {
    const ahora = new Date();
    const horas = ahora.getHours().toString().padStart(2, '0');
    const minutos = ahora.getMinutes().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
  }

  function formatDate(date) {
    var dateArray = date.split("-");
    return [dateArray[2], dateArray[1], dateArray[0]].join("-");
  }

  useEffect(() => {
    setFechaVencimientoCorregida(formatDate(fechaVencimiento));
  }, [fechaVencimiento]);


  useEffect(() => {
    textAreaRef.current.focus();

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleClickOutside = (event) => {
    if (
      popupContainerRef.current &&
      !popupContainerRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  const handleClick = async () => {
    try {
      await createTicketNew(
        mensaje, // Asumiendo que este estado guarda el título de la tarea
        businessName, // Este y los siguientes parámetros son ejemplos. Deberás reemplazarlos con tus estados/constantes.
        "Personal", // Prioridad
        fechaVencimientoCorregida,
        new Date().toISOString(), // Fecha de creación, ajusta según tu lógica
        "Personal", // Departamento
        "Pendiente", // Estado del ticket
        currentUser.email, // Email del creador
        businessEmail,
        mensaje, // Descripción de la tarea
        mensaje,
        "Personal",
        mensaje,
        name,
        [currentUser.email],
        currentUser.email,
        getHoraActual()
      );
      onClose(); // Cerrar el modal después de crear el ticket
    } catch (error) {
      console.error("Error al crear el ticket:", error);
      // Manejar el error (p.ej., mostrar un mensaje al usuario)
    }
    onClose();
  };

  const isButtonDisabled = mensaje.trim() === "" || fechaVencimiento === "";

  return (
    <div className="popupBackground" onClick={handleClickOutside}>
      <div
        className="popupContainer"
        ref={popupContainerRef}
        onClick={(e) => e.stopPropagation()}
      >
        <h1 className="titleBot">Agregar Tarea</h1>
        <div className="subContainer">
          <span className="fechaVencimiento">Descripción de la tarea:</span>
          <textarea
            ref={textAreaRef}
            placeholder="Describe la tarea"
            className="inputCreateTicket"
            onChange={(e) => setMensaje(e.target.value)}
          />
          <br />
          <span className="fechaVencimiento">Fecha de vencimiento:</span>
          <input
            onInputCapture={(e) => setFechaVencimiento(e.target.value)}
            className="InputTimeM"
            type="date"
            min={getCurrentDate()}
          />
          <br />
          <button
            onClick={handleClick}
            className="btnAI"
            disabled={isButtonDisabled}
          >
            Agregar Tarea
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
