import React from "react";
import { useRef } from "react";
import AddAreasModal from "../AddAreasModal";
import AddCompetemciasModal from "../AddCompetenciasModal";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getAreas, queryAreas, getSkills, querySkills } from "../Functions";
import { useEffect } from "react";
import { onSnapshot } from "firebase/firestore";
import { doc } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

function RbAreas() {
  const AddAreasRef = useRef(null);
  const AddCompetenciasRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const [areas, setAreas] = useState([]);
  const [competencias, setCompetencias] = useState([]);
  const itemsdelista = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [activeArea, setActiveArea] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { businessEmail } = useContext(AuthContext);

  const handleOpenModalAreas = () => {
    AddAreasRef.current.handleOpen();
  };

  const handleOpenModalCompetencias = () => {
    AddCompetenciasRef.current.handleOpen2();
  };

  async function deleteArea(email, area) {
    try {
      await deleteDoc(doc(db, "business", email, "areas", area)).then(() => {
        console.log("eliminado");
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteSkill(email, area, competencia) {
    try {
      await deleteDoc(
        doc(db, "business", email, "areas", area, "competencias", competencia)
      ).then(() => {
        console.log("eliminado");
      });
    } catch (error) {
      console.log(error);
    }
  }

  function scrollToBottom() {
    itemsdelista.current.scrollTop = itemsdelista.current.scrollHeight;
  }

  useEffect(() => {
    if (businessEmail) {
      if (currentUser && currentUser.email) {
        setLoading(true);
        const q = queryAreas(businessEmail);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = getAreas(querySnapshot);
          setAreas(items);
          scrollToBottom();
          setLoading(false);
        });
        return unsubscribe;
      }
    }
  }, [currentUser, currentUser.email, businessEmail]);

  useEffect(() => {
    if (businessEmail) {
      if (currentUser && currentUser.email) {
        if (activeArea !== null) {
          setLoading2(true);
          const q = querySkills(businessEmail, activeArea);
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items = getSkills(querySnapshot);
            setCompetencias(items);
            setLoading2(false);
          });
          return unsubscribe;
        } else {
          setCompetencias([]);
        }
      }
    }
  }, [activeArea, currentUser, currentUser.email, businessEmail]);

  return (
    <div>
      <div className="bigCard2">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">Áreas y Competencias🛄</h1>
              <div className="divTextAgents">
                <span className="textAgents">
                  A continuación, incluye en la lista las áreas de
                  especialización de tu empresa. Por ejemplo: Recursos Humanos,
                  Administración y Finanzas. Luego, asigna las competencias
                  correspondientes a cada área. Desglosar adecuadamente estas
                  áreas facilitará que la Inteligencia Artificial identifique
                  con precisión los problemas y asigne al agente más adecuado
                  para resolverlos.
                </span>
              </div>
            </div>
            <hr className="hrAgents" />

            <div className="areasCompetencias">
              <div className="listAndButtonContainer">
                <div
                  ref={itemsdelista}
                  onChange={scrollToBottom}
                  className="listScroll"
                >
                  {loading && (
                    <div className="loading-container">
                      <p>Cargando... </p>
                      <div className="spinner"></div>
                    </div>
                  )}
                  {areas.map((area, index) => (
                    <div key={index}>
                      <div
                        onClick={() => setActiveArea(area.name)}
                        className={
                          activeArea === area.name ? "list-active" : "list"
                        }
                      >
                        <div className="listElement">⭐{area.name}</div>
                        <div className="listElement">
                          <button
                            onClick={() => deleteArea(businessEmail, area.name)}
                            className="deleteListArea"
                          >
                            <FontAwesomeIcon icon={faDeleteLeft} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="buttonContainer">
                  <button
                    id="addArea"
                    className="AddItemList"
                    onClick={handleOpenModalAreas}
                  >
                    Agregar
                  </button>
                </div>
              </div>

              <div className="listAndButtonContainer">
                <div className="Competencias">
                  {activeArea === null ? (
                    <span className="textAgents">
                      "Seleccione un área para visualizar sus competencias"
                    </span>
                  ) : (
                    ""
                  )}
                  {loading2 && (
                    <div className="loading-container">
                      <div className="spinner"></div>
                    </div>
                  )}
                  {competencias.map((skill, index) => (
                    <div key={index}>
                      <div
                        onClick={() =>
                          deleteSkill(
                            businessEmail,
                            activeArea,
                            skill.competencia
                          )
                        }
                        className="globeCompetencia"
                      >
                        {skill.competencia} x
                      </div>
                    </div>
                  ))}
                </div>
                {activeArea === null ? (
                  ""
                ) : (
                  <div className="buttonContainer">
                    <button
                      id="addCompetencia"
                      className="AddItemList"
                      onClick={handleOpenModalCompetencias}
                    >
                      Agregar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
        </div>

        <AddAreasModal
          ref={AddAreasRef}
          modalDescription="Escriba el área y presione aceptar:"
          modalTitle="Agregar áreas a la lista"
          label="modalTitle"
          email={businessEmail}
        />

        <AddCompetemciasModal
          ref={AddCompetenciasRef}
          modalDescription="Escriba la compentencia (en una sola palabra) y presione aceptar: ejem: 'contratos', 'ventas', 'marketing'"
          modalTitle="Agregar competencias a la lista"
          label="modalTitle"
          email={businessEmail}
          area={activeArea}
        />
      </div>
    </div>
  );
}
export default RbAreas;
