import { React, useEffect, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { Tab, Tabs, Container, Row, Col, Table } from "react-bootstrap";
import "./tabStyles.css";
import CambiarFotoModal from "./CambiarFotoModal";

function MyProfile() {
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const { userType } = useContext(AuthContext);
  const { category } = useContext(AuthContext);
  const { status } = useContext(AuthContext);
  const { department } = useContext(AuthContext);
  const { businessName } = useContext(AuthContext);
  const { businessAdminEmail } = useContext(AuthContext);
  const { businessPhoto } = useContext(AuthContext);
  const { businessDescription } = useContext(AuthContext);
  const { numberOfTickets } = useContext(AuthContext);
  const [isFotoModalOpen, setIsFotoModalOpen] = useState(false);

  const openFotoModal = () => {
    setIsFotoModalOpen(true);
  };

  const closeFotoModal = () => {
    setIsFotoModalOpen(false);
  };

  return (
    <div>
      <Navbar />

      <Sidebar />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Mi Perfil</h2>
          </div>
        </div>
        <Tabs defaultActiveKey="personal" id="uncontrolled-tab-example">
          <Tab eventKey="personal" title="Personal">
            <div className="containerFicha">
              <Row className="row-cols-1 row-cols-md-2">
                <div className="columnaFicha">
                  <img
                    src={currentUser.photoURL}
                    alt="Foto de perfil"
                    className="imgFicha"
                    onClick={() => {
                      openFotoModal();
                    }}
                  />
                </div>

                <div className="columnaFicha">
                  <Table className="tableFicha">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Nombre:</strong>{" "}
                        </td>
                        <td>{currentUser.displayName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email:</strong>
                        </td>
                        <td>{currentUser.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Teléfono:</strong>
                        </td>
                        <td>+56 xxxxxxx</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Id del Negocio:</strong>
                        </td>
                        <td>{businessEmail}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Privilegios:</strong>
                        </td>
                        <td>{userType}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Área:</strong>
                        </td>
                        <td>{department ? department : "Todas"}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Tipo de usuario:</strong>
                        </td>
                        <td>{category ? category : "Administrador"}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Status:</strong>
                        </td>
                        <td>{status}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Contraseña:</strong>
                        </td>
                        <td>
                          <button className="starndardButton">Cambiar</button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="empresa" title="Empresa">
            <div className="containerFicha2">
              <Row className="row-cols-1 row-cols-md-2">
                <div className="columnaFicha">
                  <img
                    src={businessPhoto}
                    alt="Foto de perfil"
                    className="imgFicha"
                  />
                </div>

                <div className="columnaFicha">
                  <Table className="tableFicha">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Nombre:</strong>
                        </td>
                        <td>{businessName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Administrador:</strong>
                        </td>
                        <td>{businessAdminEmail}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Descripción:</strong>
                        </td>
                        <td>{businessDescription}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Cuota de tickets:</strong>
                        </td>
                        <td>{numberOfTickets}/500</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Plan:</strong>
                        </td>
                        <td>Gratis</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </Tab>
        </Tabs>
      </div>

      <CambiarFotoModal
      openModalTicket={isFotoModalOpen}
      handleCloseTicket={closeFotoModal}
    />
    
      <Footer />
    </div>
  );
}

export default MyProfile;
