import React, { useContext } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Kanban from "./Kanban";

function GestionarTickets() {

  return (
    <div>
      <Navbar/>
      <Sidebar />
      <Kanban />
      <Footer />
    </div>
  );
}

export default GestionarTickets;
