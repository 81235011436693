import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "../../components/firebase";
import { getDoc, doc } from "firebase/firestore";
import { queryAgents, getAgents } from "../Functions";
import { onSnapshot } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getNumberOfTicketsForAgent } from "../Functions";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

function TopAsignaciones() {
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const [ticketsPerAgent, setTicketsPerAgent] = useState([]);
  const [ticketsPerAgentBar, setTicketsPerAgentBar] = useState([]);

  useEffect(() => {
    if (businessEmail) {
      const q = queryAgents(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const agents = getAgents(querySnapshot);

        const ticketsPerAgent = agents.map(async (agent) => {
          const numberOfTickets = await getNumberOfTicketsForAgent(agent.email);
          return {
            agent: agent.name,
            tickets: numberOfTickets,
          };
        });

        Promise.all(ticketsPerAgent).then((results) => {
          results.sort((a, b) => b.tickets - a.tickets); // Sort results by ticket number
          const resultsWithIndex = results.map((result, index) => ({
            ...result,
            index: index + 1,
          }));
          setTicketsPerAgent(resultsWithIndex);
        });
      });
      return () => unsubscribe();
    }
  }, [currentUser, businessEmail]);

  useEffect(() => {
    if (businessEmail) {
      const q = queryAgents(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const agents = getAgents(querySnapshot);

        const ticketsPerAgent = agents.map(async (agent) => {
          const numberOfTickets = await getNumberOfTicketsForAgent(agent.email);
          return {
            name: agent.name, // Esto se usará como la etiqueta
            tickets: numberOfTickets, // Esto se usará para el valor del gráfico de barras
          };
        });

        Promise.all(ticketsPerAgent).then((results) => {
          results.sort((a, b) => b.tickets - a.tickets); // Ordena los resultados por número de tickets
          const resultsWithIndex = results.map((result, index) => ({
            ...result,
            index: index + 1,
          }));
          setTicketsPerAgentBar(resultsWithIndex);
        });
      });
      return () => unsubscribe();
    }
  }, [currentUser, businessEmail]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "index", // accessor is the "key" in the data
      },
      {
        Header: "Nombre",
        accessor: "agent",
      },
      {
        Header: "T. Asignados",
        accessor: "tickets",
      },
      {
        Header: "%",
        accessor: "percent",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    const totalTickets = ticketsPerAgent.reduce(
      (sum, user) => sum + user.tickets,
      0
    );
    return ticketsPerAgent.map((user, index) => ({
      index: index + 1,
      agent: user.agent,
      tickets: user.tickets,
      percent: ((user.tickets / totalTickets) * 100).toFixed(1),
    }));
  }, [ticketsPerAgent]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className="statsContent">
        <h1 className="titleBigCard">Top Asignaciones por Agente</h1>
      </div>
      <BarChart width={300} height={300} data={ticketsPerAgentBar}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="tickets" fill="#03c5c3" />
      </BarChart>
      <div className="table-container">
        <table className="tableTop" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button
            className="buttonPag"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className="buttonPag"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
          <button
            className="buttonPag"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
          <button
            className="buttonPag"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
          <span className="pagNumber">
            Página {pageIndex + 1} de {pageOptions.length}{" "}
          </span>

          <select
            value={pageSize}
            className="pagSelect"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default TopAsignaciones;
