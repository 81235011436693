import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { getTicketContext, getTicketResume } from "./Functions";
import { createTicket } from "./Functions";

function NuevoTicket() {
  const [titulo, setTitulo] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [ntitulo, setNtitulo] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [prioridad, setPrioridad] = useState("");
  const [fechaVencimientoError, setFechaVencimientoError] = useState(false);
  const [context, setContext] = useState("");
  const [valueContext, setValueContext] = useState("");
  const [resume, setResume] = useState("");

  const hoy = moment().format("DD-MM-YYYY");

  function formatDate(date) {
    var dateArray = date.split("-");
    return [dateArray[2], dateArray[1], dateArray[0]].join("-");
  }

  useEffect(() => {
    setFechaVencimientoError(formatDate(fechaVencimiento));
  });

  const getContext = async () => {
    const contexto = await getTicketContext(descripcion).then((res) => {
      setContext(res);
    });

    const resumen = await getTicketResume(descripcion).then((resume) => {
      setResume(resume);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = new Date();
    try {
      if (ntitulo > 0 && ntitulo < 31) {
        setLoading(true);

        createTicket(
          titulo,
          "Sibo",
          prioridad,
          fechaVencimientoError,
          hoy,
          "Contabilidad",
          "Pendiente",
          currentUser.email,
          "aljerr3@gmail.com",
          descripcion,
          hoy,
          "contabilizacion"
        ).then(() => {
          alert("Ticket creado");
          setLoading(false);
        });
      } else alert("Título debe tener menos de 30 caracteres");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setNtitulo(titulo.length);
  }, [titulo]);

  useEffect(() => {
    setValueContext(context);
  }, [context]);

  return (
    <div className="tickeNewWrapper">
      <div className="nuevoTicket">
        <form onSubmit={handleSubmit}>
          <h1 className="TitleNew">{valueContext}</h1>

          <span>Nombre del ticket:</span>

          <input
            onChange={(e) => setTitulo(e.target.value)}
            className="InputT"
            type="text"
            placeholder="Titulo del ticket"
          />
          <p>{ntitulo} / 30</p>

          <span>Prioridad</span>

          <input
            onChange={(e) => setPrioridad(e.target.value)}
            className="InputT"
            type="text"
            placeholder="Prioridad"
          />
          <span>Descripción</span>
          <textarea
            onInputCapture={(e) => setDescripcion(e.target.value)}
            className="InputT"
            type="text"
            cols="10"
            rows="5"
            placeholder="Descripción del problema"
          />
          <span>Fecha de vencimiento:</span>
          <input
            onInputCapture={(e) => setFechaVencimiento(e.target.value)}
            className="InputT"
            type="date"
          />

          <h1>{loading ? "Cargando..." : ""}</h1>
          <button>Enviar</button>

          <Link className="return" to="/GestionarTickets">
            ➜ Regresar
          </Link>
        </form>
      </div>
      <button onClick={getContext}> Analizar</button>
      <h1>{resume}</h1>
    </div>
  );
}

export default NuevoTicket;
