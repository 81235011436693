import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import { db, storage } from "./firebase";
import { doc, setDoc, addDoc, collection, updateDoc } from "firebase/firestore";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { sendMail } from "./SendMail";

export default function BtnCargando(props) {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [subido, setSubido] = useState(false);
  const { setBusinessDescritpion, setBusinessName, setBusinessPhoto } =
    useContext(AuthContext);

  //Create a unique image name
  const date = new Date().getTime();
  const storageRef = ref(storage, `${props.name + date}`);

  async function handleClick(e) {
    const file = props.file;
    setLoading(true);
    await uploadBytesResumable(storageRef, file).then(() => {
      getDownloadURL(storageRef).then(async (downloadURL) => {
        try {
          await updateDoc(doc(db, "business", currentUser.email), {
            businessName: props.name,
            description: props.description,
            photoBusiness: downloadURL,
          }).then(() => {
            setLoading(false);
            setSubido(true);
            sendMail(
              currentUser.email,
              "¡Confirmación de creación de empresa!",
              "Hemos registrado la creación de tu empresa: " +
                props.name +
                ". Éste será el espacio en donde tus clientes interactuarán contigo. Tu dirección de Email será la identificación única de tu negocio, por lo que es importante que conserves el mismo."
            );
          });
        } catch (err) {
          console.log(err);
        }

        try {
          await updateDoc(doc(db, "users", currentUser.email), {
            businessName: props.name,
          });
        } catch (err) {
          console.log(err);
        }
      });
    });
  }

  return (
    <Box>
      <Box sx={{ "& > button": { m: 1 } }}>
        {subido === false && (
          <LoadingButton
            size="small"
            onClick={handleClick}
            style={{ backgroundColor: "#FF3D00", color: "white", width: "95%" }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Guardar</span>
          </LoadingButton>
        )}

        {subido === true && (
          <h2 style={{ textAlign: "center" }}>¡Empresa Creada!✅</h2>
        )}
      </Box>
    </Box>
  );
}
