import React, { useRef, useContext, useEffect, useState } from "react";
import { doc, deleteDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import AddClientsModal from "../AddClientsModal";
import { AuthContext } from "../../context/AuthContext";
import { getClients, queryClients } from "../Functions";
import { getDoc } from "firebase/firestore";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import { sendMail } from "../SendMail";
import { fi } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
Modal.setAppElement("#root");

function RbClients() {
  const addClientsModalRef = useRef(null);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const itemsPerPage = 5;
  const [deleteClient, setDeleteClient] = useState("");
  const { businessEmail } = useContext(AuthContext);

  const handleOpenModalDelete = () => {
    setModalIsOpen(true);
  };

  const handleCloseModalDelete = () => {
    setModalIsOpen(false);
  };

  const handleOpenModal = () => {
    addClientsModalRef.current.handleOpen();
  };

  const handleDeleteClient = async (email) => {
    try {
      await deleteDoc(doc(db, "business", businessEmail, "clients", email));
      await deleteDoc(doc(db, "clients", email, "invitaciones", businessEmail));
      await deleteDoc(doc(db, "clients", email));
      await deleteDoc(doc(db, "users", email)).then(() => {});
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModalDelete();
    }
  };

  const handleReenviarInvitacion = async (businessEmail, email, name) => {
    let userResponse = window.confirm(
      "¿Desea reenviar la invitación a " + name + "?"
    );

    if (userResponse) {
      try {
        sendMail(
          email,
          "Nueva Invitación en Soportick.com",
          `Hola ${name} te han invitado a ser cliente de ${businessEmail}, para hacerlo, ingresa a https://app.soportick.com/registrarusuarios y crea tu cuenta con el correo: ${email} y asigna una contraseña. Una vez creada tu cuenta, ve al menú de invitaciones y acepta la misma. Una vez aceptada, podrás acceder a tu panel de cliente y crear nuevas solicitudes😃. `
        );
      } catch (error) {
        console.log(error);
      } finally {
        alert("invitación Enviada de forma satisfactoria");
      }
    } else {
    }
  };

  useEffect(() => {
    if (businessEmail) {
      setIsLoading(true);
      const q = queryClients(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getClients(querySnapshot);
        setClients(items);
        setIsLoading(false);
      });

      return () => unsubscribe();
    }
  }, [currentUser, currentUser.email, businessEmail]);

  const filteredTickets = clients.filter((client) =>
    client.name.toLowerCase().includes(busqueda.toLowerCase())
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const currentItems = filteredTickets.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      <div className="bigCard3">
        <div className="bigCardContent">
          <h1 className="titleBigCard">Clientes</h1>
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            <div className="agentsContent">
              <div className="divTextAgents">
                <span className="textAgents">
                  Envía invitaciones a tus clientes para que puedan empezar a
                  generar sus solicitudes de soporte a través de la plataforma.
                </span>
                <hr className="hrAgents" />
                <input
                  type="text"
                  placeholder="Buscar Cliente"
                  onChange={(e) => setBusqueda(e.target.value)}
                  onClick={() => setCurrentPage(0)}
                  className="inputBusqueda2"
                  autoComplete="off"
                />
              </div>
              <div className="agents">
                {currentItems.map((client, index) => (
                  <div key={index} className="clientsGlobe">
                    <div className="clientInfo">
                      💼 <strong>Nombre: </strong>&nbsp;{client.name}&nbsp;{" "}
                      <strong>Email: </strong>&nbsp;{client.email}
                    </div>
                    {client.status === "pendiente"
                      ? "Invitación Pendiente🔴"
                      : "Invitación Aceptada🟢"}
                    <button
                      className="BtnReenviar"
                      onClick={() =>
                        handleReenviarInvitacion(
                          businessEmail,
                          client.email,
                          client.name
                        )
                      }
                    >
                      Reenviar invitación
                    </button>
                    <button
                      onClick={() => {
                        handleOpenModalDelete();
                        setDeleteClient(client.email);
                      }}
                      className="deleteListArea"
                    >
                      <FontAwesomeIcon icon={faDeleteLeft} />
                    </button>
                  </div>
                ))}
              </div>

              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Siguiente"}
                breakLabel={""}
                pageCount={Math.ceil(filteredTickets.length / itemsPerPage)}
                marginPagesDisplayed={0}
                pageRangeDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"ticketsNuevos__pagination"}
                activeClassName={"active"}
                previousClassName={"custom-previous-button"}
                nextClassName={"custom-next-button"}
                previousLinkClassName={"btnAnterior"}
                nextLinkClassName={"btnSiguiente"}
                pageClassName={"custom-page-number"}
                pageLinkClassName={"custom-page-link"}
              />
            </div>
          )}
          <button className="AddItemList" onClick={handleOpenModal}>
            Agregar
          </button>
        </div>
      </div>

      <Modal
        overlayClassName={"modal-overlay"}
        className={"modal-content"}
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModalDelete}
      >
        <h2 className="titleModal">¿Desea Eliminar Cliente?</h2>
        <div className="descriptionModal">
          <p>
            Se borrará de todos los registros, aunque puede agregarlo nuevamente
            y enviar una invitación
          </p>
        </div>
        <div className="row">
          <button
            className="btnCloseModal"
            onClick={() => handleDeleteClient(deleteClient)}
          >
            Sí
          </button>
          <button className="btnCloseModal" onClick={handleCloseModalDelete}>
            No
          </button>
        </div>
      </Modal>

      <AddClientsModal
        ref={addClientsModalRef}
        modalDescription="Escriba el nombre del cliente y el email y presione aceptar:"
        modalTitle="Agregar Clientes"
        label="modalTitle"
        email={businessEmail}
      />
    </div>
  );
}
export default RbClients;
