import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { auth, db, storage } from "./firebase";
import { TextField } from "@mui/material";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { sendMail } from "./SendMail";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  Maxwidth: 350,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const AddClientsModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const { businessEmail } = useContext(AuthContext);
  const { businessName } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
    };
  });

  const handleClick = async () => {
    sendMail(email, "Invitación a Soportick.com" , `Hola ${name} te han invitado a registrarte en Soportick.com como Cliente en ${businessName}, para hacerlo, ingresa a https://app.soportick.com/registrarusuarios y crea tu cuenta con el correo: ${email} y asigna una contraseña. Una vez creada tu cuenta, podrás acceder a tu panel de cliente y crear nuevas solicitudes😃.`);
    setLoading(true);
    try {
      await setDoc(doc(db, "business", businessEmail, "clients", email), {
        email: email,
        name: name,
        category: "cliente",
        userType: "cliente",
        status: "pendiente",
      }).then(() => {
        setLoading(false);
        handleClose();
      });
    } catch (error) {
      console.log(error);
    }

    try {
      await setDoc(doc(db, "clients", email), {
        email: email,
        name: name,
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await setDoc(doc(db, "clients", email, "invitaciones", businessEmail), {
        business: businessEmail,
        name: businessName,
        status: "pendiente",
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await setDoc(doc(db, "users", email), {
        userType: "cliente",
        displayName: name,
        businessName: name,
        email: email,
        businessEmail: businessEmail,
        status: "pendiente",
        category: "cliente",
      }).then(() => {
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className={props.label}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {props.modalTitle}
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {props.modalDescription}
            <p></p>
          </Typography>
          <TextField
            id="outlined-basic"
            style={{ width: "100%" }}
            label="Nombre Cliente"
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <hr />
          <TextField
            id="outlined-basic"
            style={{ width: "100%" }}
            label="Email"
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {loading && <p style={{ textAlign: "center" }}>Cargando...</p>}

          <div className="buttonsModal">
            <button className="buttonModalYes" onClick={handleClose}>
              Cancelar
            </button>
            <button className="buttonModalNot" onClick={handleClick}>
              Aceptar
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
});

export default AddClientsModal;
