import React, { useState, useContext, useMemo } from "react";
import { signOut } from "firebase/auth";
import { auth, db } from "./firebase";
import logo from "./img/logo-banner.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBriefcase, faUser, faBuilding, faTrophy, faUsers } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../context/AuthContext";

function Navbar(props) {
  const navigate = useNavigate();
  const { currentUser, userType, numberOfAgents, puntosSoportick2 } = useContext(AuthContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const [notificationMenuVisible, setNotificationMenuVisible] = useState(false);

  const handleClick = () => {
    navigate("/");
  };

  const handleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
  };

  const handleNotificationMenu = () => {
    setNotificationMenuVisible(!notificationMenuVisible);
  };

  const handleNotificationMouseLeave = () => {
    setNotificationMenuVisible(false);
  };

  const userImage = useMemo(
    () => (
      <img
        onClick={handleMenu}
        className="photoNav"
        src={currentUser.photoURL}
        alt={"Foto de " + currentUser.displayName}
      />
    ),
    [currentUser.photoURL]
  );

  return (
    <div>
      <nav>
        <div className="nav-wrapper">
          <img onClick={handleClick} className="logonav" src={logo} alt="logo" />

          {(userType === "admin" || userType === "super" || userType === "agent") && (
            <div className="row">
              <div className="rowNavSP">
                <p>{puntosSoportick2}</p>
                <p className="rankingText">
                  <FontAwesomeIcon style={{ marginRight: "calc(100vh/250)" }} icon={faTrophy} />
                  SP
                </p>
              </div>

              <div className="rowNav">
                <p>{numberOfAgents}</p>
                <p className="rankingText">
                  <FontAwesomeIcon style={{ marginRight: "calc(100vh/250)" }} icon={faUsers} />
                  Agentes
                </p>
              </div>
              <div className="rowNav">
                <p>7</p>
                <p className="rankingText">
                  <FontAwesomeIcon style={{ marginRight: "calc(100vh/250)" }} icon={faTrophy} />
                  Ranking
                </p>
              </div>
            </div>
          )}

          <div className="nav-title">
            {userType === "admin" && <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "10px" }} />}
            {userType === "super" && <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "10px" }} />}
            {userType === "agent" && <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />}
            {userType === "cliente" && <FontAwesomeIcon icon={faBriefcase} style={{ marginRight: "10px" }} />}
            {currentUser.displayName}
          </div>

          <div onMouseLeave={handleMouseLeave}>
            {userImage}
            {menuVisible && (
              <div className="dropdown-navBar">
                <div onClick={() => navigate("/myProfile")} className="dropdown-item-nav">
                  Mi perfil
                </div>
                <div
                  onClick={() => {
                    signOut(auth);
                    navigate("/");
                  }}
                  className="dropdown-item-nav"
                >
                  Cerrar Sesión
                </div>
              </div>
            )}
          </div>

          <div onClick={handleNotificationMenu} onMouseLeave={handleNotificationMouseLeave}>
            <a className="notification">
              <span><FontAwesomeIcon icon={faBell} /></span>
              <span className="badge">1</span>
            </a>
            {notificationMenuVisible && (
              <div className="dropdown-navBar">
                <div className="dropdown-item-nav">Tickets nuevos:1</div>
                <div className="dropdown-item-nav">Por clasificar:2</div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
