import React from "react";
import { Link} from "react-router-dom";

function RbRewards() {
  return (
    <div>
      <div className="bigCard">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">
              ¡Listo!🎉
              </h1>
            </div>
            <p>
             Gracias por registrarte en Soportick, ahora puedes empezar a utilizarlo.
            </p>
            
            <Link className="link" to="/GestionarTickets">
          Empezar
        </Link>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default RbRewards;
