import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { sendMail } from "../SendMail";
import { AuthContext } from "../../context/AuthContext";
Modal.setAppElement("#root");

function CloseTicket(props) {
  const [modalIsOpenTicket, setModalIsOpenTicket] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  function updateDocTicket(ticketNumber) {
    const docRef = doc(db, "business", props.emailBusiness, "tickets", ticketNumber);
    updateDoc(docRef, {
      status: "Terminado",
      cerradoporemail: currentUser.email,
      cerradopornombre: currentUser.displayName,
    });
  }

  useEffect(() => {
    if (props.openModalTicket) {
      setModalIsOpenTicket(true);
    }
  }, [props.openModalTicket]);

  const handleOpenModalTicket = () => {
    setModalIsOpenTicket(true);
  };
  const handleCloseModalTicket = () => {
    setModalIsOpenTicket(false);
    props.handleCloseTicket(); // Notificar al componente padre que el modal está cerrado
  };
  const closeTicket = () => {
    sendMail(
      props.from,
      "¡Ticket Resuelto!",
       props.creator + ", el ticket: " + props.title + ", ha sido resuelto por " + props.agente + ", para ver el resultado ingresa a la plataforma en https://app.soportick.com. Adicionmalmente te recomendamos calificar a tu agente, ya que esto permitirá mejorar nuestro servicio y premiarlo en función a su rendimiento. Si no lo calificas, en los próximos 3 días, el sistema lo calificará por ti con 5 estrellas. Gracias por usar Soportick"
    );

      //envío mail a los agentes 
      props.to.map((email) => {
        sendMail(
          email,
          "¡Ticket Resuelto!",
          email + ", el ticket: " + props.title + " asignado a ti, ha sido resuelto por " + props.agente + ", puedes reabrir el mismo en el momento que lo desees, pero una vez calificado por tu cliemte, será archivado."
          );
      });

    alert(
      "El ticket: " +
        props.title +
        " se cerró correctamente, queda a la espera de la evaluación del cliente para determinar la puntuación del agente"
    );
    updateDocTicket(props.ticketId);
    console.log("ticket cerrado");
    navigate("/GestionarTickets");
  };
  return (
    <Modal
      overlayClassName={"modal-overlay"}
      className={"modal-content"}
      isOpen={modalIsOpenTicket}
      onRequestClose={handleCloseModalTicket}
    >
      <h2 className="titleModal">Cerrar Ticket</h2>

      <div className="descriptionModal">
        <p>¿Desea cerrar el ticket?</p>
      </div>

      <div className="row">
        <button className="btnCloseModal" onClick={closeTicket}>
          Sí
        </button>
        <button className="btnCloseModal" onClick={handleCloseModalTicket}>
          No
        </button>
      </div>
    </Modal>
  );
}

export default CloseTicket;
