import React, { useState, useEffect, useRef, useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import TicketTerminado from "./TicketTerminado";

const DraggableTicketTerminado = ({ ticket, index }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [click, setClick] = useState(false);
  const ticketRef = useRef(null);
  const containerRef = useRef(null);

  const handleMouseMove = useCallback(
    (e) => {
      if (click) {
        setIsDragging(true);
      }
      if (isDragging) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const ticketRect = ticketRef.current.getBoundingClientRect();
        setPosition({
          x: e.clientX - containerRect.left - ticketRect.width / 7,
          y: e.clientY - containerRect.top - ticketRect.height / 3,
        });
      }
    },
    [click, isDragging]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setClick(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      if (isDragging) {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const handleMouseDown = useCallback(() => {
    setClick(true);
  }, []);

  return (
    <>
      <Draggable draggableId={ticket.id} index={index}>
        {(provided) => (
          <div
            ref={(el) => {
              provided.innerRef(el);
              ticketRef.current = el;
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onMouseDown={handleMouseDown}
            style={{
              ...provided.draggableProps.style,
              position: isDragging ? "fixed" : "relative",
              left: isDragging ? "-9999px" : "auto",
              top: isDragging ? "-9999px" : "auto",
              zIndex: isDragging ? 9999 : "auto",
            }}
          >
            <TicketTerminado {...ticket} />
          </div>
        )}
      </Draggable>
      {click && (
        <div
          ref={containerRef}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            opacity: 1,
          }}
        >
          <div

          >
          </div>
        </div>
      )}
    </>
  );
};

export default DraggableTicketTerminado;