import React from "react";
import Cards from "./Cards";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { db } from "./firebase";
import { query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { collection, orderBy, limit } from "firebase/firestore";
import { getCountFromServer } from "firebase/firestore";
import ProgressGoals from "./ProgressGoals";
import Box from "@mui/material/Box";
import { StyledEngineProvider } from "@mui/material";
import DistribucionMensual from "./estadisticas/DistribucionMensual";
import DistribucionMensualEmpresa from "./estadisticas/DistribucionMensualEmpresa";
import CierresMes from "./estadisticas/CierresMes";
import CierresMesEmpresa from "./estadisticas/CierresMesEmpresa";
import TopUsers from "./estadisticas/TopUsers";
import { getDocs } from "firebase/firestore";
import ConsultarTicketsArchivados from "./ConsultarTicketsArchivados";
import { onSnapshot } from "firebase/firestore";
import GraficoTortaTickets from "./estadisticas/GraficoTortaTickets";
import { getPuntosSoportick } from "./Functions";
import Bot from "./bot/Bot";

function Contenido() {
  const { currentUser, puntosSoportick2 } = useContext(AuthContext);

  const [idUser, setIdUser] = useState(currentUser.uid);
  const [numberTickets, setNumberTickets] = useState(0);
  const [ticketsProcess, setTicketsProcess] = useState(0);
  const { businessName } = useContext(AuthContext);
  const { userType } = useContext(AuthContext);
  const { averageRating } = useContext(AuthContext);
  const [newTickets, setNewTickets] = useState(0);
  const [processTickets, setProcessTickets] = useState(0);
  const [closeTickets, setClosetTickets] = useState(0);
  const [satisfaccion, setSatisfaccion] = useState(4.8);
  const [totalTickets, setTotalTickets] = useState(0);
  const { businessEmail } = useContext(AuthContext);
  const { emailUser } = useContext(AuthContext);
  const [puntosSoportick, setPuntosSoportick] = useState(0);

  useEffect(() => {
    const getNewTickets = async () => {
      const coll = collection(db, "business", businessEmail, "tickets");
      let query_;

      if (userType === "cliente") {
        query_ = query(
          coll,
          where("from", "==", currentUser.email),
          where("status", "==", "Pendiente")
        );
      } else {
        query_ = query(
          coll,
          where("to", "array-contains", currentUser.email),
          where("status", "==", "Pendiente")
        );
      }

      const snapshot = await getDocs(query_);
      setNewTickets(snapshot.size);
    };

  

    const getProcessTickets = async () => {
      const coll = collection(db, "business", businessEmail, "tickets");
      let query_;

      if (userType === "cliente") {
        query_ = query(
          coll,
          where("from", "==", currentUser.email),
          where("status", "==", "Proceso")
        );
      } else {
        query_ = query(
          coll,
          where("to", "array-contains", currentUser.email),
          where("status", "==", "Proceso")
        );
      }

      const snapshot = await getDocs(query_);
      setProcessTickets(snapshot.size);
    };

    const getCloseTickets = async () => {
      const coll = collection(db, "business", businessEmail, "tickets");
      let query_;

      if (userType === "cliente") {
        query_ = query(
          coll,
          where("from", "==", currentUser.email),
          where("status", "==", "Terminado")
        );
      } else {
        query_ = query(
          coll,
          where("to", "array-contains", currentUser.email),
          where("status", "==", "Terminado")
        );
      }

      const snapshot = await getDocs(query_);
      setClosetTickets(snapshot.size);
    };

    if (currentUser) {
      getNewTickets();
      getProcessTickets();
      getCloseTickets();
    }
  }, [currentUser, userType]);

  useEffect(() => {
    const ticketstotales = newTickets + processTickets + closeTickets;
    setTotalTickets(ticketstotales);
  }, [newTickets, processTickets, closeTickets]); // Aquí recalculamos el total cada vez que uno de estos estados cambia

  useEffect(() => {
    console.log(totalTickets);
  }, [totalTickets]); // Aquí imprimimos el total cada vez que cambia

  return (
    <div>
      <div className="statsDiv">
        <span className="titleStatDiv">Dashboard de {businessName}</span>
        <Bot/>

        {(userType === "admin" ||
          userType === "super" ||
          userType === "agent") && (
          <div className="card-position">
            <Cards
              title="Puntos Soportick"
              content={puntosSoportick2 + " pts"}
              color="card-green"
              faq="Son los puntos que has acumulado por resolver solicitudes"
            />
            <Cards
              title="Solicitudes Nuevas"
              content={newTickets}
              color="card-blue"
              faq="Corresponde a las solicitudes que te asignaron y están pendientes de resolución. Resuélvelos para acumular puntos"
            />
            <Cards
              title="Solicitudes en Proceso"
              content={processTickets}
              color="card-purple"
              faq="Solicitudes que aceptaste y que estás en proceso de resolverlos. Procura cumplir con los tiempos para recibir la máxima calificación"
            />

            <Cards
              title="Pendientes por calificar"
              content={closeTickets}
              color="card-red"
              faq="Solicitudes que ya resolviste y están pendientes de calificación. Recuerda a tus clientes que la calificación es importante para mejorar nuestro servicio"
            />
            <Cards
              title="Índice de satisfacción"
              content={averageRating + " pts"}
              color="card-orange"
              faq="Mide el índice de satisfacción de las solicitudes que resolviste en una escala de 0 a 5 pts"
            />
          </div>
        )}

        {userType === "cliente" && (
          <div className="card-position">
            <Cards
              title="Solicitudes Nuevas"
              content={newTickets}
              color="card-blue"
              faq="Son las solicitudes que se crearon pero todavía no están en proceso de resolución"
            />
            <Cards
              title="Solicitudes en Proceso"
              content={processTickets}
              color="card-purple"
              faq="Son las solicitudes que están en proceso de resolución"
            />

            <Cards
              title="Pendientes por calificar"
              content={closeTickets}
              color="card-red"
              faq="Solicitudes pendientes de calificación. Califícalas para que podamos mejorar nuestro servicio"
            />
            <Cards
              title="Calificación otorgada"
              content={ticketsProcess}
              color="card-orange"
              faq="Mide el índice de satisfacción de las solicitudes resueltas que has calificado"
            />

          </div>
        )}

        {userType === "cliente" && (
          <div>
            <h2></h2>
            <div className="table-content-home">
              <div className="table-column">
                <DistribucionMensual />
              </div>
              <div className="table-column">
                <GraficoTortaTickets
                  totalTickets={totalTickets}
                  newTickets={newTickets}
                  processTickets={processTickets}
                  calif={closeTickets}
                />
              </div>
            </div>
            <ConsultarTicketsArchivados />
          </div>
        )}

        {userType === "agent" && (
          <div>
            <h2></h2>
            <div className="table-content-home">
              <div className="table-column">
                <DistribucionMensualEmpresa />
              </div>
              <div className="table-column-cierres">
                <GraficoTortaTickets
                  totalTickets={totalTickets}
                  newTickets={newTickets}
                  processTickets={processTickets}
                  calif={closeTickets}
                />{" "}
              </div>
            </div>
            <TopUsers />
          </div>
        )}

        {(userType === "admin" || userType === "super") && (
          <div>
            <h2>Distribución de las solicitudes por empresa</h2>
            <div className="table-content-home">
              <div className="table-column">
                <DistribucionMensualEmpresa />
              </div>
              <div className="table-column-cierres">
                <CierresMesEmpresa />
              </div>
            </div>
            <TopUsers />
          </div>
        )}
      </div>
    </div>
  );
}

export default Contenido;
