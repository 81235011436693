import React from 'react';

function TablaArchivados(props) {
  const { calificacion, title, creator, fechacierre, cerradopornombre,  comentario, horaCierre} = props;
  var comment = "";


  let displayCalificacion;

  switch(calificacion) {
    case 1:
      displayCalificacion = "⭐";
      break;
    case 2:
      displayCalificacion = "⭐⭐";
      break;
    case 3:
      displayCalificacion = "⭐⭐⭐";
      break;
    case 4:
      displayCalificacion = "⭐⭐⭐⭐";
      break;
    case 5:
      displayCalificacion = "⭐⭐⭐⭐⭐";
      break;
    default:
      displayCalificacion = "Sin Calificar";
  }

  if (!comentario) {
    comment = "sin comentarios";
  } else {
    comment = comentario;
  }

  return (
      <tr>
          <td>{title}</td>
          <td>{creator}</td>
          <td>{fechacierre}</td>
          <td>{horaCierre}</td>
          <td>{displayCalificacion}</td>
          <td>{cerradopornombre}</td>
          <td>{comment}</td>
      </tr>
  );
}

export default TablaArchivados;
