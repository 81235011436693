import React, { useState } from "react";

function AddBoardModal({ onClose, onAddBoard }) {
  const [boardName, setBoardName] = useState("");
  const [boardColor, setBoardColor] = useState("#ffffff");
  const [isAdding, setIsAdding] = useState(false); // Estado para controlar el indicador de carga

  const handleAdd = async () => {
    if (boardName.trim()) {
      setIsAdding(true); // Inicia el indicador de carga
      try {
        const refDoc = await onAddBoard({ name: boardName, color: boardColor });
        sessionStorage.setItem("lastAddedBoardId", refDoc.id); // Guardar el ID en sessionStorage
        onClose();
      } catch (error) {
        console.error("Error al añadir el tablero:", error);
        alert("Error al añadir el tablero."); // Informar al usuario del error
      }
      setIsAdding(false); // Detiene el indicador de carga
    } else {
      alert("Por favor, ingresa un nombre para el tablero.");
    }
  };

  return (
    <div className="popupBackground" onClick={onClose}>
      <div className="popupContainer" onClick={(e) => e.stopPropagation()}>
        <h1 className="titleBot">Agregar Nuevo Tablero</h1>
        <div className="subContainer">
          <input
            type="text"
            placeholder="Nombre del Tablero"
            className="inputCreateBoard"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            disabled={isAdding}
          />
          <p>Color</p>
          <input
            type="color"
            value={boardColor}
            onChange={(e) => setBoardColor(e.target.value)}
            disabled={isAdding}
          />
          <button className="btnAI" onClick={handleAdd} disabled={isAdding}>
            {isAdding ? "Agregando..." : "Agregar Tablero"}
          </button>
          <button onClick={onClose} disabled={isAdding}>Cerrar</button>
        </div>
      </div>
    </div>
  );
}

export default AddBoardModal;
