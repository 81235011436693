import React, { useEffect, useState, useContext } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { AuthContext } from '../../context/AuthContext';
import { queryAreas, getAreas, getNumberTicketsAreas } from '../Functions';
import PieChartComponent from './PieChartComponent';
import TableComponent from './TableComponent';

function TopAreas() {
  const { businessEmail } = useContext(AuthContext);
  const [ticketsPerArea, setTicketsPerArea] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);

  const colors = [
    "#FF6384", "#36A2EB", "#FFCE56", "#E38627", "#C13C37",
    "#6A2135", "#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600",
  ];

  useEffect(() => {
    if (!businessEmail) return;

    const q = queryAreas(businessEmail);
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const areas = getAreas(querySnapshot);
      const dataPromises = areas.map(async (area) => ({
        name: area.name,
        value: await getNumberTicketsAreas(area.name),
      }));

      const resolvedData = await Promise.all(dataPromises);
      resolvedData.sort((a, b) => b.value - a.value);

      setTicketsPerArea(resolvedData);
      const totalTickets = resolvedData.reduce((sum, current) => sum + current.value, 0);
      setDataForTable(resolvedData.map((area, index) => ({
        index: index + 1,
        agent: area.name,
        tickets: area.value,
        percent: ((area.value / totalTickets) * 100).toFixed(1) + '%',
      })));
    });

    return () => unsubscribe();
  }, [businessEmail]);

  const columns = React.useMemo(
    () => [
      { Header: "#", accessor: "index" },
      { Header: "Área", accessor: "agent" },
      { Header: "T. Asignados", accessor: "tickets" },
      { Header: "%", accessor: "percent" }
    ],
    []
  );

  return (
    <div>
      <div className="statsContent">
        <h1 className="titleBigCard">Top Asignaciones por Áreas</h1>
        <PieChartComponent data={ticketsPerArea} colors={colors} />
        <TableComponent columns={columns} data={dataForTable} />
      </div>
    </div>
  );
}

export default TopAreas;
