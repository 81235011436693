import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import Default from "./img/default_user.jpg";
import Dropzone from "react-dropzone";
import  Business  from "./img/business.png";

function ImageUploader(props) {
  const { onImageChange } = props;
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0.7);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(Default);
  const [showCropTools, setShowCropTools] = useState(true); // nuevo estado

  useEffect(() => {
    if(props.type === "1"){
      setCroppedImage(Business);
    } else {
      setCroppedImage(Default);
    }
  }, [props.type]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
        setShowCropTools(true);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Solo se permiten archivos PNG y JPG.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = async () => {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob); // devolver el objeto Blob en lugar de la URL del objeto Blob
        }, "image/jpeg");
    });
  };

  const handleCropImage = async () => {
    if (croppedAreaPixels) {
      const croppedImageBlob = await getCroppedImg(); // obtener el objeto Blob en lugar de la URL del objeto Blob
      const croppedImageURL = URL.createObjectURL(croppedImageBlob); // crear una URL para el objeto Blob para mostrarlo en la vista previa
      setCroppedImage(croppedImageURL);
      setShowCropTools(false);
      onImageChange(croppedImageBlob); // enviar el objeto Blob al componente padre en lugar de la URL del objeto Blob
    }
  };

  return (
    <div>
      <Dropzone onDrop={onDrop} multiple={false} maxFiles={1}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps({ accept: ".png,.jpg,.jpeg" })} />
            <p className="imageUp">
              Arrastra y suelta una imagen aquí o haz clic para seleccionar una
            </p>
          </div>
        )}
      </Dropzone>
      {imageSrc && showCropTools && (
        <>
          <div className="crop-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              cropSize={{ width: 200, height: 200 }}
              cropShape="round"
              onCropComplete={onCropComplete}
            />
            <div className="controls">
              <input
                type="range"
                min={0.5}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(parseFloat(e.target.value))}
                className="slider"
              />
            </div>
          </div>
          <div>
            <button className="btnImgUpload" type="button" onClick={handleCropImage}>Aceptar imagen recortada</button>
          </div>
        </>
      )}
      <div className="centerImage">
      <img className="imgRegister" onClick={()=>{setShowCropTools(true)}} src={croppedImage} alt="perfil" />
      </div>
    </div>
  );
}

export default ImageUploader;
