import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";



function Cards(props) {
  const { userType } = useContext(AuthContext);

  let cardClass = '';
  
  if (props.content > 0 && props.color === "card-red" && (userType === 'cliente')) {
    cardClass = "red-bg shake-animation";
  } 
  if (props.content > 0 && props.color === "card-red" && (userType === 'agent' || userType === 'admin' || userType === 'super')) {
    cardClass = "red-bg";
  } 
  if (props.content > 0 && props.color === "card-green" && (userType === 'agent' || userType === 'admin' || userType === 'super')) {
    cardClass = "gray-bg";
  } 
  else if (props.content > 0 &&  props.color === "card-purple") {
    cardClass = "yellow-bg";
  }
  else if (props.content > 0 && props.color !== "card-orange" && props.color !== "card-red" && (userType === 'agent' || userType === 'admin' || userType === 'super') ) {
    cardClass = "green-bg shake-animation";
  }
  else if (props.content > 0 && props.color !== "card-red" && (userType === 'cliente') ) {
    cardClass = "green-bg";
  }
  else if (props.content > 0 && props.color !== "card-green" && (userType === 'cliente') ) {
    cardClass = "green-bg";
  }

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/GestionarTickets");
  };

  return (
    <div className={`card ${cardClass}`} onClick={handleCardClick}>
      <div className="hover-message">{props.faq}</div> {/* Línea agregada */}
      <div className={props.color}>{props.title}</div>
      <div className="card-body">{props.content}</div>
    </div>
  );
}

export default Cards;
