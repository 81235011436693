import React, { useEffect, useState, useContext, useRef } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  addDoc,
  onSnapshot,
  Timestamp,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  getFirestore,
  deleteDoc,
  arrayRemove,
} from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { db } from "./firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCog,
  faUsers,
  faBook,
  faPaperclip,
  faBuilding,
  faMagicWandSparkles,
  faPencil,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import ProgressBar from "./ProgressBar";
import Footer from "./Footer";
import AddUserChat from "./modaChatlMenu/AddUserChat";
import CloseTicket from "./modaChatlMenu/CloseTicket";
import AdjuntarArchivosModal from "./modaChatlMenu/AdjuntarArchivosModal";
import {
  getMessages,
  convertChatDate,
  queryMessages,
  convertDateFormat,
  sendWhatsAppCustom,
} from "./Functions.js";
import { getCountFromServer } from "firebase/firestore";
import { sendMail } from "./SendMail";

function Chat(props) {
  const [text, setText] = useState("<p><br></p>");
  const [input, setInput] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  const [nroMensajes, setNroMensajes] = useState(0);
  const contenidoChat = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCloseTicket, setOpenModalCloseTicket] = useState(false);
  const [openModalAdjuntar, setOpenModalAdjuntar] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const { businessEmail } = useContext(AuthContext);
  const defaultImageURL =
    "https://firebasestorage.googleapis.com/v0/b/chat-e4572.appspot.com/o/avatar.jpg?alt=media&token=41dc2376-4dfe-4a09-9000-b6fc8f865698"; // imagen de registro predeterminada
  const customToolbarOptions = [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      { align: [] },
      { color: [] },
      { background: [] },
      { header: [3, 4, 5, 6, false] },
      { header: 1 },
      { header: 2 },
      { font: [] },
      { list: "ordered" },
      { list: "bullet" },
    ],
  ];

  const { userType: userTypeFromContext } = useContext(AuthContext);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    setUserType(userTypeFromContext);
  }, []);

  function scrollToBottom() {
    contenidoChat.current.scrollTop = contenidoChat.current.scrollHeight;
  }

  const handleTextChange = (value) => {
    setText(value);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleCloseModalCloseTicket = () => {
    setOpenModalCloseTicket(true);
  };

  const handleCloseTicket = () => {
    setOpenModalCloseTicket(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModalAdjuntar = () => {
    setOpenModalAdjuntar(!openModalAdjuntar);
  };

  const handleCloseModalAdjuntar = () => {
    console.log("handleCloseModalAdjuntar invoked");

    setOpenModalAdjuntar(false);
  };

  async function handleDeleteMember(idTicket, member) {
    let userResponse = window.confirm(
      "¿Deseas eliminar a " + member + "del ticket?"
    );

    if (userResponse) {
      const db = getFirestore();
      const ticketRef = doc(db, "business", props.emailBusiness, "tickets", idTicket);

      try {
        await updateDoc(ticketRef, {
          to: arrayRemove(member),
        });
        console.log(`Miembro ${member} eliminado del ticket ${idTicket}.`);
      } catch (e) {
        console.error(
          `Error al eliminar miembro ${member} del ticket ${idTicket}: `,
          e
        );
      }
      alert("Usuario Eliminado");
    } else {
    }
  }

  function htmlToPlainText(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.innerText;
  }

  function sanitizeHTML(html) {
    return DOMPurify.sanitize(html);
  }
  useEffect(() => {
    async function fetchFiles() {
      const db = getFirestore();
      const ticketRef = doc(db, "business", props.emailBusiness, "tickets", props.id);
      const ticketSnap = await getDoc(ticketRef);

      if (ticketSnap.exists()) {
        setFileUrls(ticketSnap.data().adjuntos);
      } else {
        console.log("No such document!");
      }
    }

    fetchFiles();
  }, [handleCloseModalAdjuntar]);

  const formatFileName = (url) => {
    // Extraer el nombre del archivo de la URL
    let fileName = url.split("/").pop();
    // Eliminar los parámetros de consulta de la URL
    fileName = fileName.split("?")[0];
    // Dividir el nombre del archivo en la base y la extensión
    const fileBase = fileName.split(".").slice(0, -1).join(".");
    const fileExtension = fileName.split(".").pop();
    // Recortar la base a los primeros 10 caracteres y volver a unir con la extensión
    return `${fileBase.substring(0, 10)}.${fileExtension}`;
  };

  useEffect(() => {
    setLoading(true);
    const q = queryMessages(props.emailBusiness, props.id);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = getMessages(querySnapshot);
      if (items.length > 0) {
        setChats(items);
        console.log("Cr");
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const date = Timestamp.now();

  const handleSend = useCallback(async () => {
    setInput("");
    try {
      if (text !== "") {
        sendWhatsAppCustom(105088769230115, 56976045123, htmlToPlainText(text));
        await addDoc(collection(db, "business", props.emailBusiness, "tickets", props.id, "messages"), {
          name: currentUser.displayName,
          photo: currentUser.photoURL,
          uid: props.uid,
          date: date,
          message: text,
        });

        //envío mail a los agentes
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        if (userType === "cliente") {
          for (let email of props.to) {
            await sendMail(
              email,
              "¡Nuevo Mensaje! - " + props.title,
              currentUser.displayName +
                ", ha dicho: " +
                htmlToPlainText(text) +
                " en el ticket: " +
                props.title +
                " para responder ingresa a: https://app.soportick.com y busca el ticket en la sección de Gestión de Tickets."
            );

            await delay(1000); // Espera 1 segundo antes de enviar el siguiente correo
          }
        } else {
          sendMail(
            props.from,
            "¡Nuevo Mensaje! - " + props.title,
            currentUser.displayName +
              ", ha dicho: " +
              htmlToPlainText(text) +
              " en el ticket: " +
              props.title +
              " para responder ingresa a: https://app.soportick.com y busca el ticket en la sección de Gestión de Tickets."
          );
        }
      }
      setText("");
    } catch (e) {
      console.log(e);
    }
  }, [text, props.uid, props.id, date]);

  return (
    <div className="fade-in">
      <div className="bigContent">
        <div className="contenedorChat">
          <div className="div-left-chat">
            <div className="titleChatSegmentLeft">
              <span>Adjuntos</span>
            </div>

            <div className="ticketLbl">
              {fileUrls &&
                fileUrls.map((url, index) => (
                  <a
                    href={url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link2"
                  >
                    {formatFileName(url)}
                  </a>
                ))}
            </div>

            <div className="titleChatSegmentWhite">
              <span>
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{ marginRight: "10px" }}
                />{" "}
                Miembros{" "}
              </span>
            </div>
            {props.to &&
              props.to.map((member, index) => (
                <li
                  className="ticketLbl"
                  key={index}
                  onClick={() => {
                    handleDeleteMember(props.id, member);
                  }}
                >
                  {member}
                </li>
              ))}

            <div className="titleChatSegmentWhite">
              <span>
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ marginRight: "10px" }}
                />
                Departamento
              </span>
            </div>
            <div className="ticketLbl">{props.department}</div>

            <div className="titleChatSegmentWhite">
              <span>
                <FontAwesomeIcon
                  icon={faMagicWandSparkles}
                  style={{ marginRight: "10px" }}
                />
                Actividades
              </span>
            </div>
            <div className="ticketLbl">{props.skill}</div>

            <div className="titleChatSegmentWhite">
              <span>
                <FontAwesomeIcon
                  icon={faPencil}
                  style={{ marginRight: "10px" }}
                />
                Creador
              </span>
            </div>
            <div className="ticketLbl">
              {props.creator} | {props.from}
            </div>

            <div className="titleChatSegmentWhite">
              <span>
                <FontAwesomeIcon
                  icon={faPencil}
                  style={{ marginRight: "10px" }}
                />
                Hora de creación
              </span>
            </div>
            <div className="ticketLbl">
                {props.horaCreacion}
            </div>
          </div>

          

          <div className="div-content-chat">
            <div className="titleChat">{props.title}</div>

            <div ref={contenidoChat} id="chatContent" className="chatContent">
              {loading && (
                <div className="loading-container">
                  <p>Cargando Mensajes... </p>
                  <div className="spinner"></div>
                </div>
              )}

              <div className="mensajeOriginal">
                <div className="mensaje">
                  <div className="row">
                    <strong>
                      <p className="hraChat">{props.creator} </p>{" "}
                    </strong>
                  </div>

                  <div className="mensaje"> {props.descripcion}</div>
                  <strong>
                    <p className="hraChat">{props.department}</p>
                  </strong>
                </div>
              </div>

              {chats.map((chat, index) => (
                <div key={index}>
                  <div></div>
                  <div>
                    <img
                      className="imgChat"
                      src={chat.photo ? chat.photo : defaultImageURL}
                      alt="Foto de perfil"
                      onError={(e) => {
                        if (e.target.src !== defaultImageURL) {
                          // Evitar bucles infinitos en caso de que defaultImageURL también falle
                          e.target.src = defaultImageURL;
                        }
                      }}
                    />
                  </div>
                  <div
                    className={
                      chat.uid === props.uid
                        ? "manesajeUsuario"
                        : "mensajeCliente"
                    }
                  >
                    <div className="row">
                      <strong>
                        <p className="hraChat">{chat.name}: </p>{" "}
                      </strong>
                    </div>

                    <div
                      className="mensaje"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(chat.message),
                      }}
                    ></div>
                    <p className="hraChat">{convertChatDate(chat.date)}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="chatInput">
              <div className="quillContainer">
                <ReactQuill
                  className="quillEditor ql-snow ql-container ql-toolbar custom-quill-editor custom-quill-toolbar"
                  theme="snow"
                  value={text}
                  onChange={handleTextChange}
                  placeholder="Redacta el contenido del mensaje..."
                  modules={{ toolbar: customToolbarOptions }}
                />
              </div>
              {text !== "<p><br></p>" && (
                <button className="bchat" onClick={handleSend}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              )}
            </div>
          </div>

          <div className="div-right-chat">
            <div className="titleChatSegment">
              {(userType === "admin" ||
                userType === "agent" ||
                userType === "super") && (
                <>
                  <div className="circular-menu">
                    <div
                      onClick={handleOpenModalAdjuntar}
                      className="circular-button"
                    >
                      <FontAwesomeIcon icon={faPaperclip} />
                    </div>
                    <div onClick={handleOpenModal} className="circular-button">
                      <FontAwesomeIcon icon={faUser} />
                    </div>

                    <div
                      onClick={handleCloseModalCloseTicket}
                      className="circular-button"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  </div>{" "}
                </>
              )}

              {userType === "cliente" && (
                <>
                  <div className="circular-menu">
                    <div
                      onClick={handleOpenModalAdjuntar}
                      className="circular-button"
                    >
                      <FontAwesomeIcon icon={faPaperclip} />
                    </div>
                  </div>{" "}
                </>
              )}
            </div>{" "}
            <div className="titleChatSegment">
              <span>Empresa</span>
            </div>
            <div className="DescriptionMessage">{props.businessName}</div>
            <div className="titleChatSegment">
              <span>Resumen</span>
            </div>
            <div className="DescriptionMessage">{props.context}</div>
            <div className="titleChatSegment">
              <span>Plan de trabajo</span>
            </div>
            <div className="DescriptionMessage">{props.plan}</div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
      <AddUserChat
        openModal={openModal}
        ticketId={props.id}
        handleCloseModalTicket={handleCloseModal}
      />

      <CloseTicket
        openModalTicket={openModalCloseTicket}
        ticketId={props.id}
        title={props.title}
        from={props.from}
        creator={props.creator}
        to={props.to}
        emailBusiness={props.emailBusiness}
        agente={currentUser.displayName}
        handleCloseTicket={handleCloseTicket}
      />

      <AdjuntarArchivosModal
        openModalAdjuntar={openModalAdjuntar}
        idTicket={props.id}
        emailBusiness={props.emailBusiness}
        handleCloseModalAdjuntar={handleCloseModalAdjuntar}
      />
    </div>
  );

  function openMenu() {
    let side = document.getElementById("chatDropMenu");
    side.classList.add("active");
  }
}

export default Chat;
