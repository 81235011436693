import React from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useState } from "react";
import { auth, db, storage } from "./firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, Link } from "react-router-dom";
import FileUploader from "./FileUploader";
import moment from "moment/moment";

export const Register = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [validate, setValidate] = useState("");
  const [email, setEmail] = useState("");

  //funcion para obtener datos de formulario y enviarlos al backend

  //validadores de contraseña y correo*********************************
  let mensajePass = "";
  let mensajeValidate = "";
  let flag = false;

  if (pass.length < 6) {
    mensajePass = "La contraseña debe ser al menos de 6 dígitos";
  } else {
    mensajePass =
      "Contraseña correcta✅";
  }

  if (pass.length < 1) {
    mensajePass = "";
  }

  if (pass === validate) {
    mensajeValidate =
      "Contraseñas coinciden✅";
  } else {
    mensajeValidate = "Las contraseñas no coinciden";
  }

  if (validate === "") {
    mensajeValidate = "";
  }

  if (pass === validate && pass.length > 5) {
    flag = true;
  }

  const handleSubmit = async (e) => {
    if (flag === true) {
      e.preventDefault();
      const displayName = e.target[0].value;
      const email = e.target[1].value;
      const password = e.target[2].value;
      const passwordValidate = e.target[3].value;
      const file = e.target[4].files[0];

      //función para crear usuario con email y password en firebase auth
      try {
        setLoading(true);
        //Create user
        const res = await createUserWithEmailAndPassword(auth, email, password);

        //Create a unique image name
        const date = new Date().getTime();
        const storageRef = ref(storage, `${displayName + date}`);

        await uploadBytesResumable(storageRef, file).then(() => {
          getDownloadURL(storageRef).then(async (downloadURL) => {
           

            try {
              //Update profile
              await updateProfile(res.user, {
                displayName,
                photoURL: downloadURL,
              });
              //create user on firestore
              await setDoc(doc(db, "users", res.user.email), {
                uid: res.user.uid,
                businessEmail: res.user.email,
                displayName,
                email,
                photoURL: downloadURL,
                department: "RRHH",
                userType: "user",
                date: date,
              });

                //create Business on firestore
                await setDoc(doc(db, "business", res.user.email), {
                  uid: res.user.uid,
                  adminemail: email,
                  photoBusiness: "",
                  date: date,
                  businessName: "",
                  businessEmail: email,
                  description: "",
                  photoBusiness:"",
                });

              const hoy = moment().format("DD-MM-YYYY");
              const tomorrow = moment().add(1, "days");
              //create empty user chats on firestore
              await addDoc(collection(db, "tickets"), {
                uid: res.user.uid,
                title: "¡Bienvenido a la plataforma!",
                businessName: "Pied Piper",
                priority: "alta",
                vencimiento: tomorrow.format("DD-MM-YYYY"),
                creacion: hoy,
                department: "RRHH",
                status: "Pendiente",
                email: res.user.email,
                today: date,
                descripcion: "Ticket de bienvenida a la plataforma",
              });

              setLoading(false);
              navigate("/");
            } catch (err) {
              setErr(true);
              
            }
          });
        });
      } catch (e) {
        setErr(true);
        setLoading(false);
      }
    } else {
      alert("Todavía no ha validado todos los campos");
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo"> Registrar📝 </span>
        <form onSubmit={handleSubmit}>
          <input
            onChange={(e) => setName(e.target.value)}
            name="nombre"
            required={true}
            type="text"
            placeholder="Nombre"
          />
          <input
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            required={true}
            type="text"
            placeholder="Email"
          />
          <input
            onChange={(e) => setPass(e.target.value)}
            required={true}
            type="password"
            placeholder="contraseña"
            name="pass"
          />
          <p className={pass.length > 5 ? "errOk" : "errRed"}>{mensajePass}</p>

          <input
            onChange={(e) => setValidate(e.target.value)}
            required={true}
            type="password"
            name="confirmar"
            placeholder="Confirmar Contraseña"
          />
          <p className={pass === validate ? "errOk" : "errRed"}>
            {mensajeValidate}
          </p>

          <FileUploader />
          <button type="submit">Registrar 🚀</button>
        </form>
        <p>{loading ? "Cargando" : ""}</p>
        <p>
          ¿Ya tienes cuenta? <Link to="../login"> Inicia sesión aquí </Link>
        </p>
      </div>
    </div>
  );
};
