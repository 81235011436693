import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faComputer } from "@fortawesome/free-solid-svg-icons";
import { faTrafficLight } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import moment from "moment/moment";
import { Draggable } from "react-beautiful-dnd";
import { db } from "./firebase";
import { updateDoc, doc } from "firebase/firestore";
import { sendMail } from "./SendMail";
import Modal from "react-modal";
import Rating from "react-rating";

Modal.setAppElement("#root");

function TicketTerminado(props) {
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const { userType: userTypeFromContext } = useContext(AuthContext);
  const [userType, setUserType] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const hoy = moment().format("DD-MM-YYYY");
  const [openModal, setOpenModal] = useState(false);
  const [modalIsOpenCalificar, setModalIsOpenCalificar] = useState(false);
  const [openModalCalificar, setOpenModalCalificar] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    setUserType(userTypeFromContext);
  }, []);

  useEffect(() => {
    setUserType(userTypeFromContext);
    if (userTypeFromContext === "admin") {
    }
  }, [userTypeFromContext]);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
  };

  const obtenerDiferenciaEnDias = (hoy, fechaVencimiento) => {
    const fecha1 = new Date(hoy.split("-").reverse().join("-"));
    const fecha2 = new Date(fechaVencimiento.split("-").reverse().join("-"));
    const diferenciaEnMilisegundos = fecha2 - fecha1;
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

    return Math.round(diferenciaEnDias);
  };

  const handleDetails = () => {
    navigate("/TicketDetails", {
      state: {
        title: props.title,
        businessName: props.businessName,
        priority: props.priority,
        vencimiento: props.vencimiento,
        creacion: props.creacion,
        department: props.department,
        status: props.status,
        emailClient: props.emailCreator,
        emailBusiness: props.emailBusiness,
        descripcion: props.descripcion,
        context: props.context,
        skill: props.skill,
        plan: props.plan,
        creator: props.creator,
        id: props.id,
        uid: currentUser.uid,
        to: props.to,
        from: props.from,
        horaCreacion: props.horaCreacion,
      },
    });
  };

  function updateReOpenTicket(NroTicket) {
    updateDoc(doc(db, "business", businessEmail, "tickets", NroTicket), {
      status: "Proceso",
    });
  }

  function calificarTicket(NroTicket) {
    updateDoc(doc(db, "business", businessEmail, "tickets", NroTicket), {
      calificacion: rating,
      comentario: comment,
      status: "Archivado",
      fechacierre: hoy,
    });
    alert("Ticket calificado con éxito con pts: " + rating);
    sendMail(
      props.cerradoporemail,
      "¡Ticket Calificado!",
      "El ticket: " +
        props.title +
        ", ha sido calificado por el cliente: " +
        props.creator +
        " con: " +
        rating +
        " estrellas ⭐con el comentario: " +
        comment
    );
  }

  function calificarTicketAutomatic(NroTicket) {
    updateDoc(doc(db, "business", businessEmail, "tickets", NroTicket), {
      calificacion: 5,
      comentario: "Calificado automáticamente",
      status: "Archivado",
      fechacierre: hoy,
    });
  }

  const handleOpenModalCalificar = () => {
    setModalIsOpenCalificar(true);
  };

  const handleCloseModalCalificar = () => {
    setModalIsOpenCalificar(false);
    setOpenModalCalificar(false);
  };

  const diasRestantes = obtenerDiferenciaEnDias(hoy, props.vencimiento);

  if (diasRestantes < -5) {
    console.log(
      `Ticket: ${props.title} ${props.id}, Ticket calificado Automáticamente`
    );
    calificarTicketAutomatic(props.id);
  }

  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="ticket"
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: "grab",
          }}
        >
          {menuVisible && (
            <div className="dropdown-menu-terminado">
              <div onClick={handleDetails} className="dropdown-item">
                <FontAwesomeIcon icon={faPencil} />
              </div>
              <div onClick={handleOpenModal} className="dropdown-item">
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>
            </div>
          )}
          <div className="estructuraTicket">
            <div
              className={
                diasRestantes >= 0
                  ? "vencimientoTicketTerminado"
                  : "vencidoTicketTerminado"
              }
            >
              <span className="venceTituloTerminado">
                {diasRestantes >= 0 ? "Vence En:" : "Venció Hace:"}
              </span>
              <span className="venceFechaTerminado">{diasRestantes}</span>
              <span className="venceSubtituloTerminado">DÍAS</span>
            </div>
            <div className="infoTicketTerminado">
              <div className="tituloTicketTerminado">
                <span>{props.title}</span>
              </div>
              <div className="rowTicket">
                <div className="columnTicket">
                  <div className="ticketElementTerminado">
                    <FontAwesomeIcon color="#8ECC3E" icon={faBriefcase} />
                    {props.creator}
                  </div>
                  <div className="ticketElementTerminado">
                    <FontAwesomeIcon color="#8ECC3E" icon={faTrafficLight} />
                    {props.priority}
                  </div>
                  <div className="ticketElementTerminado">
                    <FontAwesomeIcon color="#8ECC3E" icon={faComputer} />
                    {props.skill}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Opciones */}
          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={openModal}
            onRequestClose={handleCloseModal}
          >
            <h2 className="titleModal">Opciones Disponibles</h2>

            <div className="descriptionModal">
              <p>Seleccione una de las siguientes opciones disponibles</p>
            </div>

            <div className="row">
              {userType === "cliente" && (
                <button
                  className="btnCloseModal"
                  onClick={(e) => {
                    handleOpenModalCalificar();
                    handleCloseModal();
                  }}
                >
                  Calificar
                </button>
              )}

              <button
                className="btnCloseModal"
                onClick={() => updateReOpenTicket(props.id)}
              >
                Reabrir
              </button>

              <button className="btnCloseModal" onClick={handleCloseModal}>
                Cancelar
              </button>
            </div>
          </Modal>

          {/* Modal Calificar */}
          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpenCalificar}
            onRequestClose={handleCloseModalCalificar}
          >
            <h2 className="titleModal">Opciones Disponibles</h2>

            <div className="descriptionModal">
              <p>
                ¿Dada la resolución del ticket, que calificación le otorgaría al
                agente?
              </p>
            </div>

            <div className="starsRating">
              <Rating
                emptySymbol={
                  <FontAwesomeIcon icon={faStar} style={{ color: "#d9d9d3" }} />
                }
                fullSymbol={
                  <FontAwesomeIcon
                    icon={faStar}
                    bounce
                    style={{ color: "#FFD700" }}
                  />
                }
                fractions={1}
                initialRating={rating}
                onClick={(rate) => setRating(rate)}
              />
            </div>

            <div>
              <p>Comentario</p>
              <input
                className="comment"
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Escribe tu comentario aquí"
              />
            </div>

            <div className="row">
              <button
                className="btnCloseModal"
                onClick={handleCloseModalCalificar}
              >
                Cancelar
              </button>

              <button
                className="btnCloseModal"
                onClick={() => {
                  calificarTicket(props.id);
                  handleCloseModalCalificar();
                }}
              >
                Calificar
              </button>
            </div>
          </Modal>
        </div>
      )}
    </Draggable>
  );
}

export default TicketTerminado;