import React from 'react';
import "./Boards.css"

function Spinner() {
  return (
    <div className="spinner-container">
      <div className="spinner-bubble">
        <div>Cargando tableros...</div>
      </div>
    </div>
  );
}

export default Spinner;
