import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ConsultarTicketsNuevos from "./ConsultarTicketsNuevos";
import ConsultarTicketsEnProceso from "./ConsultarTicketsEnProceso";
import ConsultarTicketsTerminados from "./ConsultarTicketsTerminados";
import PopUp from "./addTarea/AddTarea";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import { AuthContext } from "../context/AuthContext";
import { sendMail } from "./SendMail";

function Kanban(props) {
  const [showPopUp, setShowPopUp] = useState(false);
  const [isDroppableReady, setIsDroppableReady] = useState(false);
  const [activeColumnIndex, setActiveColumnIndex] = useState(null);
  const { businessEmail } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);

  const handleOpenPopUp = () => {
    setShowPopUp(true);
  };

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  const onDragStart = (start) => {
    const columnIndex = getColumnIndex(start.source.droppableId);
    setActiveColumnIndex(columnIndex);
  };

  const onDragEnd = async (result) => {
    setActiveColumnIndex(null); // Reset the active column index when drag ends
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      return;
    }
    await handleChangeStatus(draggableId, getNewStatus(destination.droppableId));
  };

  const handleChangeStatus = async (ticketId, newStatus) => {
    try {
      const ticketRef = doc(db, "business", businessEmail, "tickets", ticketId);
      await updateDoc(ticketRef, { status: newStatus });
      const ticketSnapshot = await getDoc(ticketRef);
      const ticketData = ticketSnapshot.data();
      sendMail(
        ticketData.from,
        "¡Cambio de status de ticket!",
        `${ticketData.creator}, el ticket: ${ticketData.title}, ha sido actualizado a status ${newStatus} por ${currentUser.displayName}, te avisaremos cuando haya novedades...`
      );
    } catch (error) {
      console.error("Error al actualizar el status del ticket:", error);
    }
  };

  const getColumnIndex = (droppableId) => {
    switch (droppableId) {
      case "pendientes":
        return 0;
      case "enProceso":
        return 1;
      case "terminados":
        return 2;
      default:
        return null;
    }
  };

  const getNewStatus = (droppableId) => {
    switch (droppableId) {
      case "pendientes":
        return "Pendiente";
      case "enProceso":
        return "Proceso";
      case "terminados":
        return "Terminado";
      default:
        return null;
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDroppableReady(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="contenedorTickets">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className={`columnaKanban ${activeColumnIndex === 0 ? "active" : ""}`} style={{ zIndex: activeColumnIndex === 0 ? 1000 : 1 }}>
          <h2 className="titleKanbanNuevo">Tareas Pendientes</h2>
          {isDroppableReady && (
            <Droppable droppableId="pendientes">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <ConsultarTicketsNuevos />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          <div className="addTarea" onClick={handleOpenPopUp}>
            Agregar nueva tarea personal
          </div>
        </div>
        <div className={`columnaKanban ${activeColumnIndex === 1 ? "active" : ""}`} style={{ zIndex: activeColumnIndex === 1 ? 1000 : 1 }}>
          <h2 className="titleKanbanProceso">Tareas En Proceso</h2>
          {isDroppableReady && (
            <Droppable droppableId="enProceso">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <ConsultarTicketsEnProceso />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
        <div className={`columnaKanban ${activeColumnIndex === 2 ? "active" : ""}`} style={{ zIndex: activeColumnIndex === 2 ? 1000 : 1 }}>
          <h2 className="titleKanbanTerminado">Tareas Terminadas</h2>
          {isDroppableReady && (
            <Droppable droppableId="terminados">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <ConsultarTicketsTerminados />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
      </DragDropContext>
      {showPopUp && <PopUp onClose={handleClosePopUp} />}
    </div>
  );
}

export default Kanban;