import React from "react";
import TextField from "@mui/material/TextField";
import SendNameBusiness from "../SendNameBusiness";
import { useState, useEffect } from "react";
import ImageUploader from "../ImageUploader";

function RbDescription(props) {
  const [businessName, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (businessName !== "" && description !== "" && file.length !== 0) {
      props.handleAvanzar(true);
    } else {
      props.handleAvanzar(false);
    }
  }, [businessName, description, file]);

  return (
    <div>
      <div className="bigCard">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">Cuéntanos sobre tu empresa:🤓</h1>
            </div>
          </div>
          <hr />
          <div className="colums">
              <input type="text" placeholder="Nombre de su negocio" className="inputRegister" onChange={(e)=>{setBusinessName(e.target.value)}} />

            <input type="text"  placeholder="Descripción de su negocio" className="inputRegister" onChange={(e)=>{setDescription(e.target.value)}} />

            <ImageUploader onImageChange={setFile} type="1"/>

            {businessName !== "" && description !== "" && (
              <SendNameBusiness
                name={businessName}
                description={description}
                file={file}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RbDescription;
