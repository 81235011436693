import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getInvitacionesPendientes } from "../Functions";
import { queryInvitacionesPendientes } from "../Functions";
import { getInvitacionesAceptadas } from "../Functions";
import { queryInvitacionesAceptadas } from "../Functions";

function TablaInvitaciones() {
  const [clientEmail, setClientEmail] = useState("clientesoportick@gmail.com");
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [acceptedInvitations, setAcceptedInvitations] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [businessEmail, setBusinessEmail] = useState("");

  async function aceptarInvitacion(id, clientEmail) {
    await updateDoc(doc(db, "clients", clientEmail, "invitaciones", id), {
      status: "aceptada",
    });
    await updateDoc(doc(db, "business", id, "clients", clientEmail), {
      status: "aceptada",
    });
  }

  async function cancelarInvitación(id, clientEmail) {
    await updateDoc(doc(db, "clients", clientEmail, "invitaciones", id), {
      status: "pendiente",
    });
    await updateDoc(doc(db, "business", id, "clients", clientEmail), {
      status: "pendiente",
    });
  }

  useEffect(() => {
    if (clientEmail) {
      const q = queryInvitacionesPendientes(currentUser.email);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getInvitacionesPendientes(querySnapshot);
        setPendingInvitations(items);
      });
      return unsubscribe;
    }
  }, [clientEmail]);

  useEffect(() => {
    if (clientEmail) {
      const q = queryInvitacionesAceptadas(currentUser.email);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getInvitacionesAceptadas(querySnapshot);
        setAcceptedInvitations(items);
      });
      return unsubscribe;
    }
  }, [clientEmail]);

  return (
    <div className="bigCard">
      <div className="column">
        <h3>Pendientes</h3>
        {pendingInvitations.length === 0 ? (
          <p>Sin invitaciones pendientes</p>
        ) : (
          pendingInvitations.map((invitation, index) => (
            <div key={index} className="invitaciones">
              <h5>
                {invitation.name} | {invitation.business}
              </h5>
              <div className="buttons-container">
                <button
                  className="button-invitation1"
                  onClick={() =>
                    aceptarInvitacion(invitation.id, currentUser.email)
                  }
                >
                  <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                <button className="button-invitation2">
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
          ))
        )}
        <h3>Aceptadas</h3>
        {acceptedInvitations.length === 0 ? (
          <p>Sin invitaciones aceptadas</p>
        ) : (
          acceptedInvitations.map((invitation, index) => (
            <div key={index} className="invitacionesAceptadas">
              <h5>
                {invitation.name} | {invitation.business}
              </h5>
              <div className="buttons-container">
                <button
                  className="button-invitation2"
                  onClick={() =>
                    cancelarInvitación(invitation.id, currentUser.email)
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default TablaInvitaciones;
