import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import {  db } from "./firebase";
import { TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  Maxwidth: 350,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const AddCompentenciasModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen2 = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpen2: handleOpen2,
    };
  });

  const handleClick = async () => {
    setLoading(true);
    try {
      await setDoc(doc(db, "business", props.email, "areas", props.area, "competencias", input), {
        competencia: input,
      }).then(() => {
        console.log("click" + input);
        setLoading(false);
        handleClose();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className={props.label}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {props.modalTitle}
          </Typography>
          <Typography style={{textAlign: "center"}} id="modal-modal-description" sx={{ mt: 2 }}>
            {props.modalDescription}
            <p></p>
          </Typography>
          <TextField
            id="outlined-basic"
            style={{ width: "100%" }}
            label="Competencia"
            variant="outlined"
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />           
            {loading && <p style={{textAlign:"center"}}>Cargando...</p>}

          <div className="buttonsModal">
          
            <button className="buttonModalYes" onClick={handleClose}>
              Cancelar
            </button>
            <button className="buttonModalNot" onClick={handleClick}>
              Aceptar
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
});

export default AddCompentenciasModal;