import React, { useMemo } from "react";
import { createContext, useEffect, useState } from "react";
import { auth } from "../components/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "../components/firebase";
import { getDoc, doc, orderBy, limit } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { getPuntosSoportick } from "../components/Functions";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [businessEmail, setBusinessEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [department, setDepartment] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAdminEmail, setBusinessAdminEmail] = useState("");
  const [businessPhoto, setBusinessPhoto] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [numberOfClients, setNumberOfClients] = useState(0);
  const [numberOfAgents, setNumberOfAgents] = useState(0);
  const [businessData, setBusinessData] = useState({});
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [averageRating, setAverageRating] = useState(null);
  const [puntosSoportick2, setPuntosSoportick2] = useState(0);
  const [name, setName] = useState(0);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => {
      unsub();
    };
  }, []);

  function isInCurrentMonth(dateStr) {
    const dateParts = dateStr.split("-");
    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    const now = new Date();

    return (
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    );
  }

  useEffect(() => {
    const getUserInfo = async () => {
      if (currentUser && currentUser.email) {
        const docRef = doc(db, "users", currentUser.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setBusinessEmail(data.businessEmail);
          setUserType(data.userType);
          setCategory(data.category);
          setStatus(data.status);
          setDepartment(data.department);
          setName(data.name);

          if (!data.userType) {
            setTimeout(getUserInfo, 2000);
          }
        } else {
          console.log("no existe");
        }
      }
    };

    getUserInfo();
  }, [currentUser]);

  useEffect(() => {
    const getBusinessInfo = async () => {
      if (businessEmail) {
        const docRef = doc(db, "business", businessEmail);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBusinessName(docSnap.data().businessName);
          setBusinessAdminEmail(docSnap.data().businessEmail);
          setBusinessPhoto(docSnap.data().photoBusiness);
          setBusinessDescription(docSnap.data().description);
        } else {
          console.log("no existe businessEmail");
        }

        const q = query(
          collection(db, "users"),
          where("category", "==", "agent")
        );
        const querySnapshot = await getDocs(q);
        setNumberOfAgents(querySnapshot.docs.length);
      }
    };
    getBusinessInfo();
  }, [businessEmail, currentUser]);

  useEffect(() => {
    const getTicketInfo = async () => {
      if (businessEmail) {
        const q = query(
          collection(db, "business", businessEmail, "tickets"),
          where("emailBusiness", "==", businessEmail)
        );

        const querySnapshot = await getDocs(q);
        const ticketsThisMonth = querySnapshot.docs.filter((doc) =>
          isInCurrentMonth(doc.data().creacion)
        );
        setNumberOfTickets(ticketsThisMonth.length);
      }
    };

    getTicketInfo();
  }, [businessEmail, currentUser]);

  useEffect(() => {
    const getAverageOfLast50Archived = async () => {
      const coll = collection(db, "business", businessEmail, "tickets");
      const query_ = query(
        coll,
        where("to", "array-contains", currentUser.email),
        where("status", "==", "Archivado"),
        orderBy("creacion", "desc"), // Ordena por fecha de creación en orden descendente
        limit(50)
      );

      const snapshot = await getDocs(query_);
      const total = snapshot.docs.reduce(
        (acc, doc) => acc + doc.data().calificacion,
        0
      );

      // Redondear a dos decimales
      const average = parseFloat((total / snapshot.size).toFixed(2));

      setAverageRating(average);
    };

    if (currentUser && currentUser.email) {
      getAverageOfLast50Archived();
    }
  }, [currentUser]);

  useEffect(() => {
    async function mostrarPuntosSoportick() {
      if (currentUser?.email && businessEmail && category) {
        console.log("el tipo de usuario es" + category)
        const puntos = await getPuntosSoportick(
          businessEmail,
          currentUser.email, 
          category
        );

        console.log("el tipo de usuario es " + category)

        setPuntosSoportick2(puntos); 
      }
    }
    mostrarPuntosSoportick();
  }, [businessEmail, currentUser, category]);

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      businessEmail,
      setBusinessEmail,
      userType,
      setUserType,
      category,
      status,
      businessName,
      setBusinessName,
      businessAdminEmail,
      setBusinessPhoto,
      businessPhoto,
      setBusinessDescription,
      businessDescription,
      department,
      numberOfClients,
      numberOfAgents,
      businessData,
      numberOfTickets,
      averageRating,
      puntosSoportick2,
      name,
    }),
    [
      currentUser,
      businessEmail,
      userType,
      category,
      status,
      businessName,
      businessAdminEmail,
      businessPhoto,
      businessDescription,
      department,
      numberOfClients,
      numberOfAgents,
      businessData,
      numberOfTickets,
      averageRating,
      puntosSoportick2,
      name,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
