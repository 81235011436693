import React from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

const opcionesCanjeo = [
  {
    nombre: "Día Libre",
    puntos: 2400,
  },
  {
    nombre: "Dinero",
    puntos: 2400,
  },
  {
    nombre: "Dinero",
    puntos: 2400,
  },

  {
    nombre: "Dinero",
    puntos: 2400,
  },

  {
    nombre: "Dinero",
    puntos: 2400,
  },
];

const historialCanjes = [
  {monto: "$10.000", fecha: "12-12-2023"},
  {monto: "$10.000", fecha: "12-12-2023"},
  {monto: "$10.000", fecha: "12-12-2023"},
  {monto: "$10.000", fecha: "12-12-2023"},
];


function CanjearContainer({ opciones }) {
  return (
    <div className="puntos-canje-recompensas container2">
      <h3 className="canje-title">Canjear</h3>
      <div className="grid-container">
        {opciones.map((opcion, index) => (
          <div key={index} className="grid-item">
            {opcion.nombre} - {opcion.puntos} pts
          </div>
        ))}
      </div>
    </div>
  );
}


// Componente para la sección de Historial de Canjes
function HistorialCanjesContainer({canjes}) {
  return (
    <div className="puntos-historial-recompensas container2">
      <h3 className="historial-title">Historial de canjes</h3>
      <table className="tableTop">
        <thead>
          <tr>
            <th>Monto</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {canjes.map(canje => (
            <tr>
              <td>{canje.monto}</td>
              <td>{canje.fecha}</td>
            </tr>  
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Componente principal Recompensas
function Recompensas() {
  return (
    <div>
      <Navbar />
      <Sidebar />
        <div className="puntos-container-recompensas">
          <div className="puntos-header-recompensas">
            <h2>Puntos Soportick</h2>
            <span className="puntos-total-recompensas">3.230 Pts</span>
          </div>
          <div className="row-space">
            <CanjearContainer opciones={opcionesCanjeo} />
            <HistorialCanjesContainer canjes={historialCanjes} />
          </div>
        </div>
      </div>
  );
}

export default Recompensas;
