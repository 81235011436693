import React from "react";
import { sendWhatsapp } from "./Functions";
import { sendWhatsAppCustom } from "./Functions";
import { useState } from "react";

function Whatsapp() {
  const [mensaje, setMensaje] = useState();

  const handleClick = () => {
    sendWhatsapp();
  };

  const handleclick2 = () => {
    sendWhatsAppCustom(105088769230115, 56976045123, mensaje);
  };

  return (
    <div>
      <button onClick={handleClick}> Enviar</button>
      <input
        type="text"
        name="text"
        id=""
        onChange={(e) => setMensaje(e.target.value)}
      />

      <button onClick={handleclick2}> Enviar2</button>
    </div>
  );
}

export default Whatsapp;
