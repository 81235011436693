import React, { useState } from "react";

export async function getTitleGPT(mensaje) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const completion = await openai.createChatCompletion({
    model: "gpt-4",
    messages: [
      {
        role: "assistant",
        content:
          "eres un agente especialista en gestionar tickets de soporte de solicitudes de usuario en Soportick.com",
      },
      {
        role: "user",
        content:
          "del siguiente mensaje extraer el título que mejor sirva para identificar la solicitud en un máximo de 40 caracteres, no escribas el numero de caracteres al final del titulo, tampoco devuelvas el título en comillas sin en texto limpio" +
          mensaje,
      },
    ],
  });
  console.log(completion.data.choices[0].message);
  return completion.data.choices[0].message.content;
}

export async function getContextGPT(text, hoy, fechaVencimiento) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const completion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo-0125",
    max_tokens: 200,
    temperature: 0.7,
    messages: [
      {
        role: "assistant",
        content: "eres un agente de gestión de tickets de soporte",
      },
      {
        role: "user",
        content:
          "sintetiza la siguiente solicitud para que sea más fácil que otro agente llegue a la solución:" +
          text +
          "hoy es: " +
          hoy +
          " y la solicitud vence el: " +
          fechaVencimiento,
      },
    ],
  });
  console.log(completion.data.choices[0].message);
  return completion.data.choices[0].message.content;
}

export async function getStepsGpt(text) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const completion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo-0125",
    max_tokens: 200,
    temperature: 0.7,
    messages: [
      {
        role: "assistant",
        content: "eres un agente de gestión de tickets de soporte",
      },
      {
        role: "user",
        content:
          "identifica y enumera 3 tareas muy resumidas sintetizadas y lo más corto posible, necesarias para resolver la siguiente solicitud: " +
          text,
      },
    ],
  });
  console.log(completion.data.choices[0].message);
  return completion.data.choices[0].message.content;
}

const { Configuration, OpenAIApi } = require("openai");
const stringSimilarity = require("string-similarity");

export async function getArea(text, area) {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const completion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo-0125",
    max_tokens: 20,
    temperature: 1,
    messages: [
      {
        role: "assistant",
        content: "eres una IA que solo escoge una opción de una lista",
      },
      {
        role: "user",
        content:
          "Del siguiente texto: " +
          text +
          "selecciona de la siguiente lista el área más apropiada para resolver la solicitud" +
          area +
          "solo puedes escoger de esa lista, no puede ser otra área. Si no logras idenficar algún área apropiada escribe: Por Definir",
      },
    ],
  });
  console.log(completion.data.choices[0].message);
  const result = completion.data.choices[0].message.content;

  // Encontrar el área más parecida en el array de áreas
  const bestMatch = stringSimilarity.findBestMatch(result, area);

  // Si la coincidencia es suficientemente alta, devolver el área correspondiente
  const similarityThreshold = 0.8;
  if (bestMatch.bestMatch.rating >= similarityThreshold) {
    return bestMatch.bestMatch.target;
  } else {
    return "área por definir";
  }
}

export async function getCompetencia(text, competencias) {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const completion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo-0125",
    max_tokens: 20,
    temperature: 1,
    messages: [
      {
        role: "assistant",
        content: "eres una IA que solo escoge una opción de una lista",
      },
      {
        role: "user",
        content:
          "Del siguiente texto: " +
          text +
          "selecciona de la siguiente lista exclusivamente una actividad que realizará el agente para resolver el ticket:" +
          competencias +
          "tu selección debe ser estrictamente de esa lista. Si no logras idenficar una actividad apropiada dentro de la lista escribe: Por Definir",
      },
    ],
  });
  console.log(completion.data.choices[0].message);
  const result = completion.data.choices[0].message.content;

  // Verificar si el array de competencias está vacío
  if (competencias.length === 0) {
    return "Por Definir";
  }

  // Encontrar la competencia más parecida en el array de competencias
  const bestMatch = stringSimilarity.findBestMatch(result, competencias);

  // Si la coincidencia es suficientemente alta, devolver la competencia correspondiente
  const similarityThreshold = 0.8;
  if (bestMatch.bestMatch.rating >= similarityThreshold) {
    return bestMatch.bestMatch.target;
  } else {
    return "Por Definir";
  }
}

export async function welcomeBot(nombre, tickets, fecha) {
  const { Configuration, OpenAIApi } = require("openai");
  let IaResponse = "";
  let message = "";
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const completion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo-0125",
    max_tokens: 200,
    temperature: 0.9,
    messages: [
      {
        role: "assistant",
        content:
          "eres una IA que habla chileno y eres un experto en aumento de productividad organizacional",
      },
      {
        role: "user",
        content:
          "de las siguientes tareas (no las menciones de nuevo) simplemente analiza profundamente y escoge cuál es la más idonea para resolver. Considera la fecha de vencimiento, y considera que los agentes deben ser muy eficientes" +
          tickets +
          "Todo de forma sintetizada sin alargarte mucho y en dialecto chileno cuico" +
          "considera que hoy es: " +
          fecha +
          "si hay tickets en los que la fecha de vencimiento sea menos que la fecha actual, dale prioridad a esos",
      },
    ],
  });
  IaResponse = completion.data.choices[0].message.content;
  message = IaResponse;
  return message;
}

export async function consejoProductivo() {
  try {
    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    const openai = new OpenAIApi(configuration);

    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-0125",
      max_tokens: 200,
      temperature: 0.9,
      messages: [
        {
          role: "assistant",
          content: "eres un experto en aumento de productividad organizacional",
        },
        {
          role: "user",
          content: "proporciona un consejo que sirva para mejorar la productividad, Todo de forma sintetizada sin alargarte mucho, pero sin exagerar. Empieza directamente con el consejo"
        },
      ],
    });
    return completion.data.choices[0].message.content;
  } catch (error) {
    console.error("Error al obtener el consejo productivo: ", error);
    return "Hubo un error al obtener un consejo productivo. Por favor, intenta de nuevo más tarde.";
  }
}