import React, { useState } from "react";

function FileUploader() {
  const [files, setFiles] = useState({});
  const [size, setSize] = useState({});
  const [cargado, setCargado] = useState(false);

  const handleFile = (e) => {
    setCargado(true);
    setFiles(e.target.files[0].size);
    setSize(e.target.files[0].name);
  };

  if (!cargado) {
    return (
      <div>
        <input
          accept="image/png,image/jpeg"
          style={{ display: "none" }}
          type="file"
          id="file"
          onChange={handleFile}
        />
        <label htmlFor="file">
          <span className="foto">Agregar foto📸</span>
        </label>
      </div>
    );
  } else {
    return (
      <div>
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/png,image/jpeg"
          id="file"
          onChange={handleFile}
        />
        <label htmlFor="file">
          <span className="fotocargada">Foto Cargada ✅</span>
          <p>Nombre: {size}</p>
          <p>Peso: {files}</p>
        </label>
      </div>
    );
  }
}

export default FileUploader;
