import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import ReactPaginate from "react-paginate";
import { queryGetTickets, getTickets } from "./Functions.js";
import { onSnapshot } from "firebase/firestore";
import TicketProceso from "./TicketProceso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Draggable } from "react-beautiful-dnd";
import DraggableTicket from "./DraggableTicket";

function ConsultarTicketsEnProceso(props) {
  const [busqueda, setBusqueda] = useState("");
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { department } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const { category } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);

  const itemsPerPage = 5;

  useEffect(() => {
    if (category && businessEmail && currentUser && currentUser.email) {
      const { q1, q2, q3 } = queryGetTickets(
        category,
        businessEmail,
        department,
        "Proceso",
        currentUser.email
      );

      let items1 = [];
      let items2 = [];
      let items3 = [];

      const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
        items1 = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTickets([...items1, ...items2, ...items3]);
        setIsLoading(false);
      });

      let unsubscribe2 = () => {};
      if (q2) {
        unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
          items2 = querySnapshot2.docs
            .map((doc) => {
              if (!items1.some((ticket) => ticket.id === doc.id)) {
                return { id: doc.id, ...doc.data() };
              }
            })
            .filter(Boolean);
          setTickets([...items1, ...items2, ...items3]);
          setIsLoading(false);
        });
      }

      let unsubscribe3 = () => {};
      if (q3) {
        unsubscribe3 = onSnapshot(q3, (querySnapshot2) => {
          items3 = querySnapshot2.docs
            .map((doc) => {
              if (
                !items1.some((ticket) => ticket.id === doc.id) &&
                !items2.some((ticket) => ticket.id === doc.id)
              ) {
                return { id: doc.id, ...doc.data() };
              }
            })
            .filter(Boolean);
          setTickets([...items1, ...items2, ...items3]);
          setIsLoading(false);
        });
      }

      return () => {
        unsubscribe1();
        unsubscribe2();
        unsubscribe3();
      };
    }
  }, [category, businessEmail, department, currentUser]);

  const filteredTickets = tickets.filter((ticket) =>
    ticket.title.toLowerCase().includes(busqueda.toLowerCase())
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const currentItems = filteredTickets.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const ticketsWithIndex = currentItems.map((ticket, index) => ({
    ...ticket,
    index,
  }));

  return (
    <div className="ticket-container" style={{ position: 'relative' }}>
      <div className="center">
        <div className="inputIconContainer">
          <div className="iconWrapperOrange">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <input
            type="text"
            placeholder="Buscar Ticket"
            onChange={(e) => setBusqueda(e.target.value)}
            className="inputBusqueda"
          />
        </div>
      </div>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="loading-container">
            <p>Cargando...</p>
            <div className="spinner"></div>
          </div>
        </div>
      ) : tickets.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p className="noTicket">No tienes tickets en proceso 🙃</p>
        </div>
      ) : (
        <div>
          {ticketsWithIndex.map((ticket, index) => (
            <DraggableTicket key={ticket.id} ticket={ticket} index={index} />
          ))}
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={""}
            pageCount={Math.ceil(tickets.length / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"ticketsNuevos__pagination"}
            activeClassName={"active"}
            previousClassName={"custom-previous-button"}
            nextClassName={"custom-next-button"}
            previousLinkClassName={"btnAnterior"}
            nextLinkClassName={"btnSiguiente"}
            pageClassName={"custom-page-number"}
            pageLinkClassName={"custom-page-link"}
          />
        </div>
      )}
    </div>
  );
}

export default ConsultarTicketsEnProceso;
