import React, { useEffect, useState } from "react";
import { onSnapshot } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { queryTop10Agents, getAgents } from "../Functions";

function TopUsers() {
  const { businessEmail } = useContext(AuthContext);
  const { userType } = useContext(AuthContext)
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true); // Inicia con true para mostrar el spinner al cargar

  useEffect(() => {
    if (businessEmail) {
      const q = queryTop10Agents(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAgents(querySnapshot);
        setAgents(items);
        setLoading(false); // Oculta el spinner una vez cargados los datos
      });
      return () => unsubscribe(); // Asegura la limpieza al desmontar
    }
  }, [businessEmail]);

  return (
    <div>
      <div className="statsContent">
        <h2 className="titleBigCard">Top 10 Agentes🏆</h2>
      </div>
      <div className="table-container">
        {loading ? (
          <div className="spinner"></div> // Muestra el spinner si está cargando
        ) : agents.length > 0 ? (
          <table className="tableTop">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Puntos</th>
              </tr>
            </thead>
            <tbody>
              {agents.map((user, index) => (
                <tr key={user.name}>
                  <td>{index + 1}</td> {/* Añade index para número de fila */}
                  <td>{user.name}</td>
                  <td>{user.puntos || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No hay agentes para mostrar</p> // Mensaje cuando no hay datos
        )}
      </div>
    </div>
  );
}

export default TopUsers;
