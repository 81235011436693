import React from "react";
import Navbar from "../Navbar";
import Contenido from "../Contenido";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import TablaInvitaciones from "./TablaInvitaciones";

function Invitaciones() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <TablaInvitaciones />
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Invitaciones;
