import React from "react";

function Footer() {
  return (
    <div>
      <footer className="footer">
        Soportick.com Todos los derechos reservados 2024{" "}
      </footer>
    </div>
  );
}

export default Footer;
