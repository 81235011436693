import React, { useState, useEffect, useContext } from "react";
import { welcomeBot, consejoProductivo } from "../IA/Gpt";
import { AuthContext } from "../../context/AuthContext";
import { queryGetTickets } from "../Functions.js";
import { db } from "../firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

function PopUp({ onClose, welcomeMessage, consejosProductivos, tickets, isDataLoaded }) {
  const [displayedMessage, setDisplayedMessage] = useState("");
  const typingSpeed = 5;
  const { currentUser, name } = useContext(AuthContext);

  useEffect(() => {
    let typingTimer;
    if (welcomeMessage.length > displayedMessage.length) {
      typingTimer = setTimeout(() => {
        setDisplayedMessage(
          welcomeMessage.substring(0, displayedMessage.length + 1)
        );
      }, typingSpeed);
    }
    return () => clearTimeout(typingTimer);
  }, [displayedMessage, welcomeMessage]);

  return (
    <div className="popupBackground">
      <div className="popupContainer">
        <h1 className="titleBot">Bienvenido</h1>
        <div className="subContainer">
          {isDataLoaded ? (
            <>
              <div className="task-list">
                <strong>🤖¡Hola! {name}</strong>
                <p>Estas son las tareas que tenemos hoy:</p>
                {tickets.map((ticket, index) => (
                  <div key={index}>
                    <p>
                      <strong>Tarea {index + 1}:</strong> {ticket.title} -{" "}
                      {ticket.vencimiento}
                    </p>
                  </div>
                ))}
              </div>
              <hr />
              <strong>¿Por dónde empezar?</strong>
              <br />
              <br />
              {displayedMessage}
              <hr />
              <strong>Consejo Productivo del día</strong>
              <br />
              <br />
              {consejosProductivos}
              <hr />
              <button onClick={onClose} className="btnAI">
                ¡Empezar!
              </button>
            </>
          ) : (
            <div className="centerSpinner">
              <div className="spinner2"></div>
              <p>Cargando...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Bot() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [consejosProductivos, setConsejosProductivos] = useState("");
  const [tickets, setTickets] = useState([]);
  const { currentUser, category, department, businessEmail, name } =
    useContext(AuthContext);

  useEffect(() => {
    const lastPopupDate = localStorage.getItem("lastPopupDate");
    const today = new Date().toISOString().slice(0, 10);

    if (lastPopupDate !== today) {
      setShowPopUp(true);
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    async function fetchData() {
      if (category && businessEmail && currentUser && currentUser.email) {
        const { q1, q2, q3 } = queryGetTickets(
          category,
          businessEmail,
          department,
          "Pendiente",
          currentUser.email
        );

        const queries = [q1, q2, q3].filter((query) => !!query);

        const fetchTickets = queries.map(
          (query) =>
            new Promise((resolve) => {
              const unsubscribe = onSnapshot(query, (querySnapshot) => {
                const items = querySnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                resolve(items);
                return unsubscribe;
              });
            })
        );

        const results = await Promise.all(fetchTickets);
        const allTickets = results.flat();
        const uniqueTickets = allTickets.filter(
          (ticket, index, self) =>
            index === self.findIndex((t) => t.id === ticket.id)
        );

        const consejo = await consejoProductivo();
        setConsejosProductivos(consejo);

        if (uniqueTickets.length > 0) {
          const ticketsText = uniqueTickets
            .map(
              (ticket, index) =>
                `Tarea ${index + 1}: ${ticket.title}, Fecha: ${
                  ticket.vencimiento
                }`
            )
            .join(". ");

          const message = await welcomeBot(name, ticketsText);
          if (isSubscribed) {
            setWelcomeMessage(message);
            setTickets(uniqueTickets);
            setIsDataLoaded(true);
          }
        } else {
          if (isSubscribed) {
            setWelcomeMessage(
              "No tienes tareas pendientes, concéntrate en las tareas en progreso.🦾"
            );
            setIsDataLoaded(true);
          }
        }
      }
    }

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [category, businessEmail, department, currentUser, name]);

  const handleClose = () => {
    const today = new Date().toISOString().slice(0, 10);
    localStorage.setItem("lastPopupDate", today);
    setShowPopUp(false);
  };

  return (
    <div>
      {showPopUp && (
        <PopUp
          onClose={handleClose}
          welcomeMessage={welcomeMessage}
          consejosProductivos={consejosProductivos}
          tickets={tickets}
          isDataLoaded={isDataLoaded}
        />
      )}
    </div>
  );
}

export default Bot;