import React, { useEffect, useState, useContext } from "react";
import AddBoardModal from "./AddBoardModal"; 
import { AuthContext } from "../../context/AuthContext";
import "./Boards.css";
import { createBoard } from "../Functions";



function Boards(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [boards, setBoards] = useState([]);
  const { businessEmail } = useContext(AuthContext);

  const handleBoardClick = (boardId) => {
    sessionStorage.setItem("selectedBoardId", boardId);
  };

  useEffect(() => {
    if (props.boards) {
      setBoards(props.boards);
    }
  }, [props.boards]); // Se actualiza cuando props.boards cambia

  const handleAddBoard = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const addNewBoard = async (newBoard) => {
    try {
      const refDoc = await createBoard(
        businessEmail,
        newBoard.name,
        newBoard.color
      );
      console.log("Tablero creado con éxito con ID:", refDoc.id);
      setBoards(currentBoards => [...currentBoards, { ...newBoard, id: refDoc.id }]);
      handleCloseModal();
    } catch (error) {
      console.error("Error al crear el tablero:", error);
    }
  };

  return (
    <div className="boardsContainer">
      {boards.map((board, index) => (
        <div
          key={board.id} // Cambiado a usar board.id para un mejor manejo de las keys
          className="board"
          style={{ backgroundColor: board.color }}
          onClick={() => handleBoardClick(board.id)} // Maneja el clic aquí
        >
          <div className="boardTitle">{board.name}</div>
        </div>
      ))}
      <div className="board addNewBoard" onClick={handleAddBoard}>
        <span className="addBoardText">Crear un tablero nuevo</span>
      </div>

      {isModalOpen && (
        <AddBoardModal onClose={handleCloseModal} onAddBoard={addNewBoard} />
      )}
    </div>
  );
}

export default Boards;
