import React, { useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInAnonymously,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  getAuth,
} from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { auth, db, storage } from "./firebase";
import { doc, setDoc, addDoc, collection, updateDoc } from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import logoLogin from "./img/logo-login.png";
import ImageUploader from "./ImageUploader";
import moment from "moment/moment";
import { getDoc } from "firebase/firestore";
import { sendMail } from "./SendMail";

//validadores de contraseña y correo*********************************

const passwordValidationSchema = yup
  .string()
  .required("La contraseña es obligatoria")
  .matches(
    /^(?=.*[A-Z])(?=.*[!_@.#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
    "La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo especial"
  );

const schema = yup.object().shape({
  username: yup
    .string()
    .required("El nombre de usuario es obligatorio")
    .min(3, "El nombre de usuario debe tener al menos 3 caracteres")
    .max(30, "El nombre de usuario debe tener máximo 30 caracteres"),
  email: yup
    .string()
    .required("El email es obligatorio")
    .email("Debe ser un email válido"),
  password: passwordValidationSchema,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de contraseña es obligatoria"),
});

const hoy = moment().format("DD-MM-YYYY");

function RegistroUsuarios(e) {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [emailRegistrado, setEmailRegistrado] = useState(false);
  const [userCategory, setUserCategory] = useState("");
  const auth = getAuth();
  const defaultImageURL = "https://firebasestorage.googleapis.com/v0/b/chat-e4572.appspot.com/o/avatar.jpg?alt=media&token=41dc2376-4dfe-4a09-9000-b6fc8f865698";  // imagen de registro predeterminada


  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  //*************************funciones de registro*********************************

  // 1. Verifica si el correo electrónico ya está registrado, si no está registrado verifica que tenga invitaciones internas de registro. Si las tiene confirma qué tipo de invitación es, si es agente registra como agente, si es cliente, registra como cliente.
  const verificarEmailRegistrado = async (email) => {
    try {
      setLoading(true);
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        alert("El correo ya está registrado");
        setLoading(false);
        setEmailRegistrado(true);
        return;
      } else {
        // Inicia sesión como usuario anónimo
        await signInAnonymously(auth);

        // Consulta el documento en Firestore
        const userDoc = await getDoc(doc(db, "users", email));
        let userCategory2;

        if (userDoc.exists() && userDoc.data().category === "agent") {
          console.log("El usuario es un agente");
          userCategory2 = "agent";
        }
        if (userDoc.exists() && userDoc.data().category === "cliente") {
          console.log("El usuario es un cliente");
          userCategory2 = "cliente";
        }

        setUserCategory(userCategory2);
        console.log("userCategory", userCategory2);
        setEmailRegistrado(false);

        if (userCategory2 === "cliente") {
          await createUser("cliente", "Cliente");
        } else if (userCategory === "agent") {
          await createUser("agent", "Agente");
        }

        // Now, based on the category, create the user
        if (userCategory2 === "cliente") {
          await createUser("cliente", "cliente");
        } else if (userCategory2 === "agent") {
          await createUser("agent", "Agente");
        } else if (userCategory2 === "super") {
          await createUser("super", "Supervisor");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createUser = async (category, message) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await sendEmailVerification(user);
  
    const date = new Date().getTime();
    let finalImageURL = defaultImageURL; // Establecer la URL de la imagen en la imagen predeterminada primero
  
    if (file !== null) { // Si se proporcionó una imagen, intenta subirla y usar su URL
      const storageRef = ref(storage, `${name + date}`);
      try {
        await uploadBytesResumable(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        finalImageURL = downloadURL; // Si la subida de la imagen es exitosa, actualiza la URL de la imagen
      } catch (err) {
        console.error("Error al cargar la imagen:", err);
      }
    }
  
    try {
      // Update profile
      await updateProfile(user, {
        displayName: name,
        photoURL: finalImageURL, // Usar la URL final de la imagen aquí
      });
  
      // create user on firestore
      await updateDoc(doc(db, "users", user.email), {
        uid: user.uid,
        displayName: name,
        category,
        photoURL: finalImageURL, // Y también aquí
        userType: category,
        status: "activo",
        date,
      });
  
      sendMail(
        email,
        "¡Confirmación de Registro en Soportick!",
        `Felicidades ${name} te has registrado como ${message} en Soportick.com, ahora puedes iniciar sesión y comenzar a gestionar tus solicitudes.`
      );
  
      onAuthStateChanged(auth, (user) => {
        if (user) {
          if (!user.emailVerified) {
            navigate("/verificarEmail");
          }
          else {
            navigate("/");
          }
        }
      });
      setLoading(false);
    } catch (err) {
      setErr(true);
      setLoading(false);
    }
  };
  
  

  const onSubmit = async () => {
    try {
      await verificarEmailRegistrado(email);
      setLoading(true);
    } catch (e) {
      console.log(e);
      setErr(true);
      setLoading(false);
    }
  };

  //*************************funciones de registro*********************************
  return (
    <div className="formContainer">
      <div className="formWrapper">
        <img className="logoLogin" src={logoLogin} alt="" />
        <span className="titleLogin">Usuarios</span>
        <span
          style={{
            fontSize: "12px",
            textAlign: "center",
            padding: "calc(100vh/150)",
          }}
        >
          Ingresa los datos de usuario
        </span>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="username">Nombre y Apellido</label>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  id="username"
                  placeholder="Nombre y Apellido"
                  onBlur={(e) => trigger("username") && setName(e.target.value)}
                />
              )}
            />
            {errors.username && (
              <p className="errRed">{errors.username.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="email"
                  id="email"
                  placeholder="Email"
                  onBlur={(e) => trigger("email") && setEmail(e.target.value)}
                />
              )}
            />
            {errors.email && <p className="errRed">{errors.email.message}</p>}
          </div>
          <div>
            <label htmlFor="password">Contraseña</label>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  onBlur={(e) =>
                    trigger("password") && setPassword(e.target.value)
                  }
                />
              )}
            />
            {errors.password && (
              <p className="errRed">{errors.password.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="confirmPassword">Confirmar contraseña</label>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirmar contraseña"
                  onBlur={() => trigger("confirmPassword")}
                />
              )}
            />
            {errors.confirmPassword && (
              <p className="errRed">{errors.confirmPassword.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="avatar">Foto de Perfil</label>
            <ImageUploader onImageChange={setFile} />
          </div>
          <button type="submit">Registrar</button>
          {loading && (
            <div className="centerSpinner">
              <div className="spinner"> </div>
              <p>Cargando...</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default RegistroUsuarios;
