import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { eachDayOfInterval, startOfMonth, endOfMonth, format } from "date-fns";

function CierresMesEmpresa() {
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [dailyTicketCounts, setDailyTicketCounts] = useState([]);

  useEffect(() => {
    if (businessEmail) {
      setLoading(true);

      const start = startOfMonth(new Date());
      const end = endOfMonth(new Date());
      const datesOfMonth = eachDayOfInterval({ start, end });

      const fetchAndCountTickets = async () => {
        const newDailyTicketCounts = [];

        const promises = datesOfMonth.map(async (date) => {
          const formattedDate = format(date, "dd-MM-yyyy");
          const ticketsCollection = collection(db, "tickets");
          const q = query(
            ticketsCollection,
            where("emailBusiness", "==", businessEmail),
            where("closingTime", "==", formattedDate)
          );
          const querySnapshot = await getDocs(q);
          newDailyTicketCounts.push({
            date: formattedDate,
            Cantidad: querySnapshot.size,
          });
        });

        await Promise.all(promises);
        setDailyTicketCounts(newDailyTicketCounts);
        setLoading(false);
      };

      fetchAndCountTickets();
    }
  }, [currentUser, businessEmail]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h2>Cerrados en el mes</h2>
      {loading && <div>Cargando...</div>}
      {!loading && dailyTicketCounts.length > 0 && (
        <div  style={{
          width: "100%",
          height: "calc(100vh/3)",
          margin: "auto", // Centra el gráfico
        }}>
          <ResponsiveContainer>
            <BarChart
              width={650}
              height={300}
              data={dailyTicketCounts}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis tickFormatter={(tick) => Math.floor(tick)} />
              <Tooltip />
              <Legend />
              <Bar dataKey="Cantidad" fill="#1799b2" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}

export default CierresMesEmpresa;
