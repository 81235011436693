import React from "react";

function RbIntro() {
  return (
    <div>
      <div className="bigCard">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">
                ¡Hola! Bienvenido a Soportick👋🎉
              </h1>
            </div>
            <span className="textAgents">Primero lo primero...😃</span>
            <div className="divTextAgents">
              <span className="textAgents">
                Para poder empezar a utilizar Soportick, necesitamos que nos
                proporciones algunos datos de tu empresa.
              </span>
              <span className="textAgents">
                Dale Click en siguiente y ve siguiendo las instrucciones
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RbIntro;
