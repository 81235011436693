import {
  collection,
  doc,
  query,
  where,
  getDoc,
  getDocs,
  setDoc
} from "firebase/firestore";
import { db } from "./firebase";
import { orderBy, limit, startAfter, addDoc } from "firebase/firestore";



export async function getTicketContext(descripcion) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const response = await openai.createCompletion({
    model: "text-davinci-003",
    prompt:
      "del siguente texto:" +
      descripcion +
      "selecciona a que departamento va dirigido en una sola palabra: finanzas, RRHH, informática, contabilidad, tesorería, administracion, legal, ventas, compras, produccion, calidad, logistica",
    temperature: 0,
    max_tokens: 10,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  });
  return response.data.choices[0].text;
}

export async function getTicketResume(descripcion) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const response = await openai.createCompletion({
    model: "gpt-3.5-turbo",
    prompt: "cuál sería la solución a la siguiente solicitud:" + descripcion,
    temperature: 0.9,
    max_tokens: 50,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  });
  return response.data.choices[0].text;
}

export function getImageUrl(place) {
  return "https://i.imgur.com/" + place.imageId + "l.jpg";
}

export function getImageUrl2(place) {
  return console.log(place);
}

export function daysBetween(fechaInicio, fechaFin) {
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = fechaFin.getTime() - fechaInicio.getTime();
  const diffInDays = Math.round(diffInTime / oneDay) + 1;
  return diffInDays;
}

export function convertDateFormat(dateStr) {
  // separar la fecha en partes usando una expresión regular
  const parts = dateStr.match(/(\d{2})-(\d{2})-(\d{4})/);
  // reordenar las partes y unirlas de nuevo
  const newDateStr = `${parts[2]}-${parts[1]}-${parts[3]}`;
  return newDateStr;
}

export function convertChatDate(hrs) {
  const timestamp = hrs.seconds * 1000 + hrs.nanoseconds / 1000000; // convertir a milisegundos
  const date = new Date(timestamp); // objeto de fecha

  const day = date.getDate(); // día del mes (1-31)
  const month = date.getMonth() + 1; // mes (0-11, sumar 1 para ajustar el índice)
  const year = date.getFullYear(); // año (4 dígitos)

  const hours = date.getHours(); // hora (0-23)
  const minutes = date.getMinutes(); // minutos (0-59)

  // Formato fecha y hora
  const formattedDateTime = ` ${hours}:${minutes} del ${day}-${month}-${year}`;

  return formattedDateTime;
}

export function getQuery(userType, department, idUser, status) {
  if (userType === "admin") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      orderBy("today", "asc"),
      limit(4)
    );
    return q;
  } else if (userType === "user") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      where("email", "==", idUser),
      orderBy("today", "asc"),
      limit(4)
    );
    return q;
    console.log(q);
  }
}

export function queryMessages(emailBusiness, idTicket) {
  const q = query(
    collection(db, "business", emailBusiness, "tickets", idTicket, "messages"),
    orderBy("date", "asc")
  );
  return q;
}

export function queryAreas(emailBusiness) {
  const q = query(collection(db, "business", emailBusiness, "areas"));
  return q;
}

export function queryUserArea(emailBusiness) {
  const q = query(collection(db, "business", emailBusiness, "areas"));
  return q;
}

export function getUserArea(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryAgents(emailBusiness) {
  const q = query(collection(db, "business", emailBusiness, "agents"));
  return q;
}

export function queryCompetencias(emailBusiness, agentArea) {
  const q = query(
    collection(
      db,
      "business",
      emailBusiness,
      "areas",
      agentArea,
      "competencias"
    )
  );
  return q;
}

export function queryTop10Agents(emailBusiness) {
  return query(
    collection(db, `business/${emailBusiness}/agents`),
    orderBy("puntos", "desc"), // Ordena los documentos por el campo 'puntos' de mayor a menor
    limit(10) // Limita los resultados a los 10 primeros documentos
  );
}

export function queryCompetenciasTop10Agents(emailBusiness, agentArea) {
  const q = query(
    collection(
      db,
      "business",
      emailBusiness,
      "areas",
      agentArea,
      "competencias"
    )
  );
  return q;
}

export function getCompetencias(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

// Función para obtener las áreas asignadas a un usuario
/* export async function getAssignedAreas(userEmail) {
  const areasRef = collection(db, "users", userEmail, "department");
  const snapshot = await getDocs(areasRef);
  return snapshot.docs.map((doc) => doc.id); // Obtiene los IDs de los subdocumentos que son los nombres de las áreas
}
 */

export function queryCompetenci(businessEmail, area) {
  const q = query(
    collection(db, "business", businessEmail, "areas", area, "compentencias"),
  );
  return q;
}

// Función para obtener las competencias de una área específica
export async function getCompetenciasForArea(emailBusiness, area) {
  try {
    // Referencia a la colección de competencias para una área específica
    const competenciasRef = collection(
      db,
      "business",
      emailBusiness,
      "areas",
      area,
      "competencias"
    );
    // Obtener los documentos (competencias) de la colección
    const snapshot = await getDocs(competenciasRef);
    // Mapear los documentos a un array de objetos con los datos de las competencias
    const competencias = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    
    return competencias;
  } catch (error) {
    // Si hay un error, imprimirlo en consola y retornar un array vacío o manejar el error como prefieras
    console.error("Error al obtener competencias para el área:", area, error);
    return [];
  }
}

export function queryGetCompentenciasDelAgente(selectedAgent, selectedEmpresa) {
  const q = query(
    collection(
      db,
      "users",
      selectedAgent,
      "businessPartner",
      selectedEmpresa,
      "competencias"
    )
  );
  return q;
}

/* export function queryAgentCompetencias(selectedEmpresa, area, selectedAgent) {
  const q = query(
    collection(
      db,
      "clients",
      selectedEmpresa,
      area,
      selectedAgent,
      "competencias"
    )
  );
  return q;
} */

export function queryAgentCompetencias(selectedEmpresa, businessEmail, selectedAgent) {
  const q = query(
    collection(
      db,
      "business",
      businessEmail,
      "agents",
      selectedAgent,
      "businessPartner",
      selectedEmpresa,
      "competencias"
    )
  );
  return q;
}

export function getAgentCompetencias(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function getAgents(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryClients(emailBusiness) {
  const q = query(collection(db, "business", emailBusiness, "clients"));
  return q;
}

export function getClients(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryInvitacionesAceptadas(clientEmail) {
  const q = query(
    collection(db, "clients", clientEmail, "invitaciones"),
    where("status", "==", "aceptada")
  );
  return q;
}

export function getInvitacionesPendientes(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryInvitacionesPendientes(clientEmail) {
  const q = query(
    collection(db, "clients", clientEmail, "invitaciones"),
    where("status", "==", "pendiente")
  );
  return q;
}

export function getInvitacionesAceptadas(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function getAreas(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function querySkills(emailBusiness, area) {
  const q = query(
    collection(db, "business", emailBusiness, "areas", area, "competencias")
  );
  return q;
}

export function getSkills(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

/* export function queryBusinessPartner(emailUser) {
  const q = query(collection(db, "users", emailUser, "businessPartner"));
  return q;
} */

export function queryBusinessPartner(activeAgent, businessEmail) {
  const q = query(collection(db, "business", businessEmail, "agents", activeAgent, "businessPartner"));
  return q;
}

/* export function queryAreasAgent(emailUser) {
  const q = query(collection(db, "users", emailUser, "department"));
  return q;
}
 */
export function queryAreasAgent(activeAgent, businessEmail) {
  const q = query(collection(db, "business", businessEmail, "agents", activeAgent, "areas"));
  return q;
}

export function getAreaAgent(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id }); // Aseguramos de incluir todos los datos del documento y su ID.
  });
  return items;
}

export function getBusinessPartner(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function getQueryAfter(
  userType,
  department,
  idUser,
  status,
  firstVisible
) {
  if (userType === "admin") {
    const q = query(
      collection(db, "tickets",),
      where("status", "==", status),
      where("department", "==", department),
      orderBy("today", "desc"),
      limit(4),
      startAfter(firstVisible)
    );
    return q;
  } else if (userType === "user") {
    const q = query(
      collection(db, "tickets",),
      where("status", "==", status),
      where("department", "==", department),
      where("email", "==", idUser),
      orderBy("today", "desc"),
      limit(4),
      startAfter(firstVisible)
    );
    return q;
  }
}

export function getQueryNext(
  userType,
  department,
  idUser,
  status,
  lastVisible
) {
  if (userType === "admin") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      orderBy("today", "asc"),
      limit(4),
      startAfter(lastVisible)
    );
    return q;
  } else if (userType === "user") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      where("email", "==", idUser),
      orderBy("today", "asc"),
      limit(4),
      startAfter(lastVisible)
    );
    return q;
  }
}

export function getTickets(querySnapshot) {
  const items_aux = [];
  const items = [];
  querySnapshot.forEach((doc) => {
    items_aux.push({ ...doc.data(), id: doc.id });
  });
  items_aux.forEach((item) => {
    const fechaInicio = new Date();
    const fechaFin = new Date(convertDateFormat(item.vencimiento));
    daysBetween(fechaInicio, fechaFin);
    const diff = daysBetween(fechaInicio, fechaFin);
    items.push({ ...item, restante: diff });
  });
  return items;
}

export function getMessages(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });

  return items;
}

export function sendWhatsapp() {
  var botId = "105088769230115";
  var phoneNbr = "56976045123";
  var bearerToken =
    "EAANFMsGcZAroBAF4chqYAwMddxMnmJOZB3srENHxEIESJLXaMh4zJbut0tgaPKszNxB41R78iry73ZBUGxDF3hO8ABmOAKW6iHWkOK4fnb9qFyen7e8KA6MWcOZBnb5SCkdZCIsRVnrsOfZCBM4iEGCa2xZC3TnSrXmtGPexXAo6Wyj6oNbwzDAfpGZAHMnDGov6GRA8WzRMBQZDZD";
  var url = "https://graph.facebook.com/v15.0/" + botId + "/messages";
  var data = {
    messaging_product: "whatsapp",
    to: phoneNbr,
    type: "template",
    template: {
      name: "hello_world",
      language: { code: "en_US" },
    },
  };
  var postReq = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearerToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    json: true,
  };
  fetch(url, postReq)
    .then((data) => {
      return data.json();
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
}

export function sendWhatsAppCustom(botId, number, message) {
  var bearerToken =
    "EAANFMsGcZAroBAF4chqYAwMddxMnmJOZB3srENHxEIESJLXaMh4zJbut0tgaPKszNxB41R78iry73ZBUGxDF3hO8ABmOAKW6iHWkOK4fnb9qFyen7e8KA6MWcOZBnb5SCkdZCIsRVnrsOfZCBM4iEGCa2xZC3TnSrXmtGPexXAo6Wyj6oNbwzDAfpGZAHMnDGov6GRA8WzRMBQZDZD";
  var url = "https://graph.facebook.com/v15.0/" + botId + "/messages";
  var data = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: number,
    type: "text",
    text: {
      preview_url: false,
      body: message,
    },
  };

  var postReq = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearerToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    json: true,
  };
  fetch(url, postReq)
    .then((data) => {
      return data.json();
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
}

// funciones para devolver tickets en base a condiciones preestablecidas:

async function filterByAgentId(documentsSnapshot, emailAgent) {
  const filteredDocs = [];
  documentsSnapshot.forEach((doc) => {
    const data = doc.data();
    const to = data.to;
    if (to && to.some((obj) => obj.agenteId === emailAgent)) {
      filteredDocs.push(doc);
    }
  });
  return filteredDocs;
}

export async function getFilteredTickets(privileges, status, emailAgent, db) {
  if (privileges === "agent") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      orderBy("today", "desc")
    );

    const querySnapshot = await getDocs(q);
    const filteredDocs = await filterByAgentId(querySnapshot, emailAgent);
    return filteredDocs;
  }
}

export function queryGetTickets(
  privileges,
  emailBusiness,
  department,
  status,
  emailAgent
) {
  if (privileges === "admin") {
    const q = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("emailBusiness", "==", emailBusiness),
      where("priority", "in", ["Alta", "Media", "Baja"]),
      where("status", "==", status),
      orderBy("creacion", "desc")
    );
    return { q1: q, q2: null, q3: null };
  }

  if (privileges === "supervisor") {
    const q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );

    const q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    const q3 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("emailBusiness", "==", emailBusiness),
      where("department", "==", department),
      orderBy("creacion", "desc")
    );

    return { q1, q2, q3 };
  }

  if (privileges === "agent") {
    const q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );

    const q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    return { q1, q2, q3: null };
  }

  if (privileges === "cliente") {
    const q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );

    const q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    return { q1, q2, q3: null };
  }
}

export async function createTicket(
  title,
  businessName,
  priority,
  vencimiento,
  creacion,
  department,
  status,
  emailCreator,
  emailBusiness,
  description,
  context,
  skill,
  plan,
  creator,
  to,
  from,
  horaCreacion
) {
  const refDoc = await addDoc(collection(db, "tickets"), {
    title: title,
    businessName: businessName,
    priority: priority,
    vencimiento: vencimiento,
    creacion: creacion,
    department: department,
    status: status,
    emailClient: emailCreator,
    emailBusiness: emailBusiness,
    descripcion: description,
    context: context,
    skill: skill,
    plan: plan,
    creator: creator,
    to: to,
    from: from,
    horaCreacion: horaCreacion,
  });
  return refDoc;
}

//función nueva para crear tickets dentro del negocio

export async function createTicketNew(
  title,
  businessName,
  priority,
  vencimiento,
  creacion,
  department,
  status,
  emailCreator,
  emailBusiness,
  description,
  context,
  skill,
  plan,
  creator,
  to,
  from,
  horaCreacion
) {
  const refDoc = await addDoc(collection(db, "business", emailBusiness, "tickets"), {
    title: title,
    businessName: businessName,
    priority: priority,
    vencimiento: vencimiento,
    creacion: creacion,
    department: department,
    status: status,
    emailClient: emailCreator,
    emailBusiness: emailBusiness,
    descripcion: description,
    context: context,
    skill: skill,
    plan: plan,
    creator: creator,
    to: to,
    from: from,
    horaCreacion: horaCreacion,
  });
  return refDoc;
}

export async function createBoard(businessEmail, boardName, boardColor) {
  const refDoc = await addDoc(collection(db, "business", businessEmail, "boards"), {
    name: boardName,
    color: boardColor,
    id: new Date().toISOString() 
  });
  return refDoc;
}

export async function getBoards(businessEmail) {
  const q = query(collection(db, "business", businessEmail, "boards"));
  const querySnapshot = await getDocs(q);
  const boards = [];
  querySnapshot.forEach((doc) => {
    boards.push({ id: doc.id, ...doc.data() });
  });
  return boards;
}

export async function getResponsable(
  emailEmpresa,
  dpto,
  competencia,
  emailResponsable
) {
  // Referencia a la colección de agentes
  const agentesRef = collection(db, `business/${emailEmpresa}/agents`);
  const querySnapshot = await getDocs(agentesRef);
  const responsables = [];

  for (const docSnap of querySnapshot.docs) {
    // Referencia a la subcolección de áreas del agente
    const areasRef = collection(
      db,
      `business/${emailEmpresa}/agents/${docSnap.id}/areas`
    );
    const areasSnapshot = await getDocs(areasRef);

    // Verificar si alguna de las áreas coincide con 'dpto'
    let areaCoincide = false;
    areasSnapshot.forEach((areaDoc) => {
      if (areaDoc.data().area === dpto) {
        areaCoincide = true;
        console.log(areaCoincide);
      }
    });

    if (areaCoincide) {
      console.log("competencias conicnden")
      const competenciasRef = collection(
        db,
        `business/${emailEmpresa}/agents/${docSnap.id}/businessPartner/${emailResponsable}/competencias`
      );
      console.log(competenciasRef)
      const competenciasSnapshot = await getDocs(competenciasRef);

      let competenciaCoincide = false;
      competenciasSnapshot.forEach((compDoc) => {
        // Asumiendo que cada documento dentro de 'competencias' representa una competencia
        // y tiene un campo 'nombre' que especifica el nombre de la competencia.
        if (compDoc.id === competencia) {
          // O compDoc.data().nombre si el nombre está en los datos
          competenciaCoincide = true;
        }
      });

      if (competenciaCoincide) {
        // Si el agente cubre la competencia, agrega su correo electrónico al array de responsables
        responsables.push(docSnap.id); // Asumiendo que el ID del documento es el correo electrónico del agente
      }
    }
  }

  if (responsables.length === 0) {
    console.log("No hay responsables");
    return "Por definir agente";
  } else {
    console.log("Los responsables son: ", responsables);
    return responsables; // Devuelve los correos de los agentes responsables
  }
}
/* export async function getResponsable(emailEmpresa, dpto, competencia) {
  // Define la competencia que quieres filtrar
  // Obtiene todos los agentes que están en el departamento deseado
  const agentesRef = collection(db, "clients", emailEmpresa, dpto);
  const agentesSnapshot = await getDocs(agentesRef);
  const responsables = [];

  // Para cada agente, verifica si la competencia especificada está presente
  for (const agente of agentesSnapshot.docs) {
    const competenciaRef = doc(db, "users", agente.id, "businessPartner", emailEmpresa, "competencias", competencia);
    const competenciaSnapshot = await getDoc(competenciaRef);
    if (competenciaSnapshot.exists()) {
      // Cambia esta línea para agregar solo el ID del agente al array
      responsables.push(agente.id);
    }
  }
  // Si el array responsables está vacío, devuelve "Por definir agente"
  if (responsables.length === 0) {
    return;
    console.log("no hat responsables")
  } else {
    return responsables;
    console.log("los responsables son" +responsables);
  }
} */

export async function getNumberOfTicketsForAgent(emailAgent) {
  const q = query(
    collection(db, "tickets"),
    where("to", "array-contains", emailAgent)
  );

  const querySnapshot = await getDocs(q);

  // El número de tickets es el número de documentos en la QuerySnapshot
  const numberOfTickets = querySnapshot.size;

  return numberOfTickets;
}

export async function getNumberTicketsAreas(area) {
  const q = query(collection(db, "tickets"), where("department", "==", area));

  const querySnapshot = await getDocs(q);

  // El número de tickets es el número de documentos en la QuerySnapshot
  const numberOfTickets = querySnapshot.size;

  return numberOfTickets;
}

export async function getPuntosSoportick(emailBusiness, emailUser, usertype) {
  // Verifica si el usuario es un agente antes de proceder.
  if (usertype !== "agent" && usertype !== "admin") {
    console.log("La función está restringida solo a agentes.");
    return "Acceso denegado"; // O maneja este caso como mejor te parezca.
  }

  const q = query(
    collection(db, `business/${emailBusiness}/tickets`),
    where("status", "==", "Archivado"),
    where("to", "array-contains", emailUser)
  );

  const querySnapshot = await getDocs(q);
  
  if (querySnapshot.empty) {
    await actualizarPuntosAgente(emailBusiness, emailUser, 0);
    return "0";
  }

  let sumatoriaCalificaciones = 0;
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.calificacion) {
      sumatoriaCalificaciones += data.calificacion;
    }
  });

  if (sumatoriaCalificaciones === 0 && !querySnapshot.empty) {
    await actualizarPuntosAgente(emailBusiness, emailUser, 0);
    return "sin calificaciones para mostrar";
  }

  // Llama a la función para actualizar los puntos del agente en la base de datos
  await actualizarPuntosAgente(emailBusiness, emailUser, sumatoriaCalificaciones);

  return sumatoriaCalificaciones;
}

async function actualizarPuntosAgente(emailBusiness, emailUser, puntos, category) {
  // Verificar si el tipo de usuario es permitido para realizar la actualización.
  if (category !== "agent" && category !== "admin") {
    return "0"; // O maneja este caso como mejor te parezca.
  }

  if (category == "agent" && category == "admin") {
    const agentRef = doc(db, `business/${emailBusiness}/agents/${emailUser}`);
  
  try {
    await setDoc(agentRef, { puntos }, { merge: true });

  } catch (error) {
  }
}
}

