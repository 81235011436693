import React, { useContext, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";
import { queryClients, getClients } from "./../Functions";
import { useState } from "react";
import { db } from "./../firebase";
import { doc, getDoc } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";

function AutoCompleteUsers( {usuarioSeleccionado}) {
const [selectedUser, setSelectedUser] = React.useState(null); // Estado para guardar el valor del input
const [empresas, setEmpresas] = useState([]);
const { currentUser } = useContext(AuthContext);
const { businessEmail } = useContext(AuthContext);




const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "calc(100vh/70)",
            fontFamily: "Poppins",

          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "calc(100vh/70)",
            fontFamily: "Poppins",

          },
        },
      },
    },
  });

  useEffect(() => {
    if (businessEmail) {
      const q = queryClients(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getClients(querySnapshot);
        setEmpresas(items);
      });
      setSelectedUser(empresas[1]);
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  const handleAreaChange = (event, newValue) => {
    setSelectedUser(newValue);
    if (typeof usuarioSeleccionado === 'function') {
      usuarioSeleccionado(newValue);
    }
  };
  return (
    <div className="autocomplete2">
    <ThemeProvider theme={theme}>
      <div className="autocomplete2">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={empresas}
          getOptionLabel={(option) => option.name}
          sx={{
            width: "100%",
            fontFamily: "Poppins",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onChange={handleAreaChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "100%" }}
              label="Usuarios?"
            />
          )}
        />
      </div>
    </ThemeProvider>
  </div>  )
}

export default AutoCompleteUsers