import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";

function GraficoTortaTickets(props) {
  const data = [
    { name: "Tickets en Proceso", value: props.processTickets },
    { name: "Tickets Nuevos", value: props.newTickets },
    { name: "Por calificar", value: props.calif },
  ];

  const COLORS = ["#1b134f", "#aa86c7", "#d0b4fe"];

  return (
    <div className="pieChartContainer" style={{ width: '100%', height: 'calc(100vh/2.43)', margin: 'auto' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius="40%" 
            fill="#8884d8"
            dataKey="value"
            label={(entry) => `${entry.name}: ${entry.value}`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GraficoTortaTickets;
