import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faComputer } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faTrafficLight } from "@fortawesome/free-solid-svg-icons";
import { db } from "./firebase";
import { doc, updateDoc } from "firebase/firestore";
import Modal from "react-modal";
import moment from "moment/moment";
import AutoCompleteUsers from "./modaChatlMenu/AutoCompleteUsers";
import AddUserChat from "./modaChatlMenu/AddUserChat";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { sendMail } from "./SendMail";
import { deleteDoc } from "firebase/firestore";
import { Draggable } from "react-beautiful-dnd";

Modal.setAppElement("#root");

function TicketNuevo(props) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [ticketType, setTicketType] = useState("tituloTicketNuevo");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenConsulta, setModalIsOpenConsulta] = useState(false);
  const [modalIsOpenShare, setModalIsOpenShare] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const hoy = moment().format("DD-MM-YYYY");
  const [openModal, setOpenModal] = useState(false);
  const { userType: userTypeFromContext } = useContext(AuthContext);
  const [userType, setUserType] = useState(null);
  const { businessEmail } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const creador = props.from; // Asumiendo que 'tickets' es un array

  useEffect(() => {
    setUserType(userTypeFromContext);
  }, []);

  useEffect(() => {
    setUserType(userTypeFromContext);
    if (userTypeFromContext === "admin") {
    }
  }, [userTypeFromContext]);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setOpenModal(false);
  };

  const handleOpenModalConsulta = () => {
    setModalIsOpenConsulta(true);
  };

  const handleCloseModalConsulta = () => {
    setModalIsOpenConsulta(false);
  };

  const handleOpenModalShare = () => {
    setOpenModal(true);
    setOpenModal(!openModal);
  };

  const handleCloseModalShare = () => {
    setModalIsOpenShare(false);
  };

  const handleClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
    setOpenModal(false);
  };

  const handleOpenModalConsultaRightClick = (event) => {
    event.preventDefault(); // Prevenir el menú contextual predeterminado del navegador
    handleOpenModal(); // Llamar a tu función que abre el modal de consulta
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(db, "business", businessEmail, "tickets", props.id));
      console.log("Ticket eliminado con éxito"); // Para propósitos de depuración
      setIsDeleteModalOpen(false); // Suponiendo que usas un estado para controlar la visibilidad del modal
    } catch (error) {
      console.error("Error al eliminar el ticket: ", error);
    } finally {
      sendMail(
        props.from,
        "Ticket Eliminado",
        props.creator +
          ", el ticket: " +
          props.title +
          ", ha sido eliminoado por el administrador del sistema" +
          currentUser.displayName +
          ", este proceso es irreversible, por lo que si quieres recuperarlo, tendrás que crear un ticket nuevo."
      );
    }
  };

  const obtenerDiferenciaEnDias = (hoy, fechaVencimiento) => {
    const fecha1 = new Date(hoy.split("-").reverse().join("-"));
    const fecha2 = new Date(fechaVencimiento.split("-").reverse().join("-"));
    const diferenciaEnMilisegundos = fecha2 - fecha1;
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

    return Math.round(diferenciaEnDias);
  };
  const diasRestantes = obtenerDiferenciaEnDias(hoy, props.vencimiento);

  const handleChangeStatus = async () => {
    try {
      await updateDoc(doc(db, "business", businessEmail, "tickets", props.id), {
        status: "Proceso",
      }).then(() => {
        setModalIsOpenConsulta(false);
      });
    } catch (error) {
      console.log(error);
    } finally {
      sendMail(
        props.from,
        "¡Cambio de status de ticket!",
        props.creator +
          ", el ticket: " +
          props.title +
          ", ha sido actualizado a status en Proceso por " +
          currentUser.displayName +
          ", te avisaremos cuando haya novedades..."
      );
    }
  };

  useEffect(() => {
    if (!modalIsOpen && !modalIsOpenConsulta && !modalIsOpenShare) {
      handleMouseLeave();
    }
  }, [modalIsOpen, modalIsOpenConsulta, modalIsOpenShare]);

  return (
        <Draggable draggableId={props.id} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          className="ticket"
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: "grab",
          }}
        >
          {menuVisible && (
            <div className="dropdown-menu-nuevo">
              <div
                onClick={() => {
                  handleOpenModal();
                }}
                onMouseOver={() => {
                  if (openModal) {
                    setOpenModal(false);
                  }
                }}
                className="dropdown-item"
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>

              {(userType === "admin" ||
                userType === "agent" ||
                userType === "super") && (
                <>
                  <div onClick={handleOpenModalShare} className="dropdown-item">
                    <FontAwesomeIcon icon={faShare} />
                  </div>
                  <div
                    onClick={handleOpenModalConsulta}
                    className="dropdown-item"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} bounce />
                  </div>
                </>
              )}

              {userType === "admin" ||
                (creador === currentUser.email && (
                  <div
                    onClick={() => setIsDeleteModalOpen(true)}
                    className="dropdown-item"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                ))}
            </div>
          )}

          <div className="estructuraTicket">
            <div
              className={
                diasRestantes >= 0 ? "vencimientoTicket" : "vencidoTicket"
              }
            >
              <span className="venceTitulo">
                {" "}
                {diasRestantes >= 0 ? "Vence En:" : "Venció Hace:"}
              </span>
              <span className="venceFecha">{diasRestantes}</span>
              <span className="venceSubtitulo">DÍAS</span>
            </div>
            <div className="infoTicket">
              <div className={ticketType}>
                <span>{props.title}</span>
              </div>{" "}
              <div className="rowTicket">
                <div className="columnTicket">
                  <div className="ticketElement">
                    {" "}
                    <FontAwesomeIcon color="#3464CC" icon={faBriefcase} />{" "}
                    {props.creator}
                  </div>
                  <div className="ticketElement">
                    {" "}
                    <FontAwesomeIcon color="#3464CC" icon={faTrafficLight} />
                    {props.priority}
                  </div>
                  <div className="ticketElement">
                    {" "}
                    <FontAwesomeIcon color="#3464CC" icon={faComputer} />{" "}
                    {props.skill}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
          >
            <h2 className="titleModal">{props.title}</h2>
            <div className="descriptionModal">
              <p>{props.descripcion}</p>
            </div>
            <div className="fichaTicketModal">
              {" "}
              <p>
                <strong>Creado Por:</strong> {props.creator} | {props.from}
              </p>
              <p>
                <strong>Empresa: </strong> {props.creator}
              </p>
              <p>
                {" "}
                <strong>Contexto</strong> {props.context}
              </p>
              <p>
                {" "}
                <strong>Prioridad:</strong> {props.priority}
              </p>
              <p>
                <strong>Fecha de Elaboración</strong> {props.creacion}
              </p>
              <p>
                <strong>Fecha de Vencimiento</strong>
                {props.vencimiento}
              </p>
              <p>
                <strong>Estado:</strong> {props.status}
              </p>
              <p>
                <strong>Área</strong> {props.department}
              </p>
              <p>
                <strong>Actividad:</strong> {props.skill}
              </p>
              <p>
                <strong>Miembros:</strong> {props.to}
              </p>
              <p>
                <strong>Hora de Creación:</strong> {props.horaCreacion}
              </p>
            </div>
            <button className="btnCloseModal" onClick={handleCloseModal}>
              Cerrar
            </button>
          </Modal>

          {/* Modal para consultar si desea cambiar el estado del ticket */}

          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpenConsulta}
            onRequestClose={handleCloseModalConsulta}
          >
            <h2 className="titleModal">Iniciar Ticket</h2>

            <div className="descriptionModal">
              <p>¿Seguro que desea iniciar Ticket?</p>
            </div>

            <div className="row">
              <button className="btnCloseModal" onClick={handleChangeStatus}>
                Sí
              </button>
              <button
                className="btnCloseModal"
                onClick={handleCloseModalConsulta}
              >
                No
              </button>
            </div>
          </Modal>

          {/* MODAL PARA ELIMINAR TICKETS */}

          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={isDeleteModalOpen}
            onRequestClose={() => setIsDeleteModalOpen(false)}
          >
            <h2 className="titleModal">Eliminar Ticket</h2>

            <div className="descriptionModal">
              <p>
                ¿Seguro que desea Eliminar Ticket? (esta acción no se puede
                deshacer)
              </p>
            </div>

            <div className="row">
              {/* Suponiendo que usas una clase 'row' para alinear los botones */}
              <button className="btnCloseModal" onClick={handleConfirmDelete}>
                Sí
              </button>
              <button
                className="btnCloseModal"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </Modal>

          <AddUserChat
            openModal={openModal}
            ticketId={props.id}
            handleCloseModal={handleCloseModal}
          />

          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpenShare}
            onRequestClose={handleCloseModalShare}
          >
            <h2 className="titleModal">Iniciar Ticket</h2>

            <div className="descriptionModal">
              <p>
                Selecciona el nombre de la persona con la que desees compartir
                el ticket
              </p>
            </div>

            <div className="column">
              <button className="btnCloseModal" onClick={handleChangeStatus}>
                Compartir
              </button>
              <button className="btnCloseModal" onClick={handleCloseModalShare}>
                Cancelar
              </button>
            </div>
          </Modal>
        </div>
      )}
    </Draggable>
  );
}

export default TicketNuevo;