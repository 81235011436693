import React, { useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./CreateTicket.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import {
  getTitleGPT,
  getContextGPT,
  getStepsGpt,
  getArea,
  getCompetencia,
} from "./Gpt.jsx";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment/moment";
import {
  getAreas,
  queryAreas,
  getSkills,
  querySkills,
  createTicket,
  getResponsable,
  getClients,
  queryClients,
  getInvitacionesAceptadas,
  queryInvitacionesAceptadas,
  createTicketNew,
} from "../Functions";
import { onSnapshot } from "firebase/firestore";
import Modal from "react-modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import firebase from "firebase/compat/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { sendMail } from "../SendMail";

Modal.setAppElement("#root");

function CreateTicket() {
  const [mensaje, setMensaje] = useState("");
  const [title, setTitle] = useState("Identificando título...");
  const [contexto, setContexto] = useState("Sintetizando solicitud...");
  const [cargando, setCargando] = useState(false);
  const [pasos, setPasos] = useState("Identificando plan de trabajo...");
  const [botonPresionado, setBotonPresionado] = useState(false);
  const [fechavencimiento, setFechaVencimiento] = useState("");
  const [fechaVencimientoCorregida, setFechaVencimientoCorregida] =
    useState("");
  const [mostrarAceptarCancelar, setMostrarAceptarCancelar] = useState(false);
  const [cargandoTitulo, setCargandoTitulo] = useState(false);
  const [cargandoContexto, setCargandoContexto] = useState(false);
  const [cargandoPasos, setCargandoPasos] = useState(false);
  const [cargandoAreas, setCargandoAreas] = useState(false);
  const [cargandoCompetencias, setCargandoCompetencias] = useState(false);
  const [cargandoResponsables, setCargandoResponsables] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const { businessName } = useContext(AuthContext);
  const { userType } = useContext(AuthContext);
  const [creator, setCreator] = useState("");
  const [idAreas, setIdAreas] = useState([]);
  const [areas, setAreas] = useState("Identificando áreas...");
  const hoy = moment().format("DD-MM-YYYY");
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [idCompetencias, setIdCompetencias] = useState([]);
  const [competencias, setCompetencias] = useState(
    "identificando competencias..."
  );
  const [loadingCompetencias, setLoadingCompetencias] = useState(false);
  const [flagCompetencia, setFlagCompetencia] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [editingContexto, setEditingContexto] = useState(false);
  const [editingPasos, setEditingPasos] = useState(false);
  const [editingAreas, setEditingAreas] = useState(false);
  const [editingCompetencias, setEditingCompetencias] = useState(false);
  const [modalAdjuntosIsOpen, setModalAdjuntosIsOpen] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [responsables, setResponsables] = useState("");
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showEditIconContexto, setShowEditIconContexto] = useState(false);
  const [showEditIconPasos, setShowEditIconPasos] = useState(false);
  const [showEditIconAreas, setShowEditIconAreas] = useState(false);
  const [actividades, setActividades] = useState("");
  const [showEditIconCompetencias, setShowEditIconCompetencias] =
    useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [horaCreacion, setHoraCreacion] = useState("");
  const [empresas_clientes, setEmpresas_clientes] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [idEmpresa, setIdEmpresa] = useState("");
  const [emailEpresaResponsable, setEmailEmpresaResponsable] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [areasOptions, setAreasOptions] = useState([]);
  const navigate = useNavigate();

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "calc(100vh/70)",
            fontFamily: "Poppins",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "calc(100vh/70)",
            fontFamily: "Poppins",
          },
        },
      },
    },
  });

  // función para medir el tiempo que tarda intentando conseguir el contexto por IA
  function withTimeout(promise, ms) {
    const timeout = new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        reject("Tiempo de espera agotado");
      }, ms);
    });

    return Promise.race([promise, timeout]);
  }

  // Función para obtener la hora actual en formato deseado
  const obtenerHoraActual = () => {
    const ahora = new Date();
    return (
      ahora.getHours() + ":" + ahora.getMinutes().toString().padStart(2, "0")
    );
  };

  const handleAreaChange = (event, newValue) => {
    setSelectedEmpresa(newValue); // Actualiza manualmente el valor del estado
  };

  const handleOpenModalAdjuntos = () => {
    setModalAdjuntosIsOpen(true);
  };

  const handleCloseModalAdjuntos = () => {
    setModalAdjuntosIsOpen(false);
  };

  function formatDate(date) {
    var dateArray = date.split("-");
    return [dateArray[2], dateArray[1], dateArray[0]].join("-");
  }

  // Obtener la fecha actual en formato "YYYY-MM-DD"
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0!
    const yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };
  //obtengo las áreas para cargarlas en el select

  useEffect(() => {
    if (selectedEmpresa && userType === "cliente") {
      const q = queryAreas(selectedEmpresa);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreas(querySnapshot);
        setAreasOptions(items);
      });
      return unsubscribe;
    }
  }, [selectedEmpresa]);

  //obtengo fecha de vencimiento
  useEffect(() => {
    setFechaVencimientoCorregida(formatDate(fechavencimiento));
  }, [fechavencimiento]);

  // cargo clientes en el select ---------------------

  useEffect(() => {
    if (businessEmail) {
      const q = queryClients(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getClients(querySnapshot);
        setEmpresas(items);
      });
      setSelectedEmpresa(empresas[1]);
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  // cargo clientes en el select del cliente---------------------

  useEffect(() => {
    if (businessEmail) {
      const q = queryInvitacionesAceptadas(currentUser.email);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getInvitacionesAceptadas(querySnapshot);
        setEmpresas_clientes(items);
      });
      setSelectedEmpresa(empresas_clientes[1]);
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  useEffect(() => {
    if (businessEmail) {
      setLoadingAreas(true);
      const q = queryAreas(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreas(querySnapshot);
        const idsAreas = items.map((item) => item.id);
        setIdAreas(idsAreas);
        setLoadingAreas(false);
      });
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  // una vez que obtengo las áreas con GPT ahora consulto por las competencias

  useEffect(() => {
    if (areas !== "Identificando áreas...") {
      setLoadingCompetencias(true);
      const q = querySkills(businessEmail, areas);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getSkills(querySnapshot);
        const idsCompetencias = items.map((item) => item.id);
        setIdCompetencias(idsCompetencias);
        setFlagCompetencia(true);
        setActividades(items);
        setLoadingCompetencias(false);
      });
      return unsubscribe;
    }
  }, [areas]);

  useEffect(() => {
    if (flagCompetencia === true) {
      const obtenerCompetencia = async () => {
        setCargandoCompetencias(true);
        const competencia = await getCompetencia(mensaje, idCompetencias);
        setCompetencias(competencia);
        setCargandoCompetencias(false);
      };
      obtenerCompetencia();
    }
  }, [flagCompetencia, areas, idCompetencias]);

  const constructTicket = async () => {
    if (mensaje && fechavencimiento && selectedEmpresa) {
      setBotonPresionado(true);

      if (
        userType === "agent" ||
        userType === "admin" ||
        userType === "supervisor"
      ) {
        setCreator(selectedEmpresa.name);
        setEmailEmpresaResponsable(selectedEmpresa.email);
        console.log("email responsable", emailEpresaResponsable);
      } else {
        setCreator(currentUser.displayName);
        setEmailEmpresaResponsable(currentUser.email);
        console.log("email responsable", emailEpresaResponsable);
        console.log("el creador es el cliente", creator);
      }

      setCargandoTitulo(true);
      const title = await getTitleGPT(mensaje);
      setTitle(title);
      setCargandoTitulo(false);

      setCargandoContexto(true);
      const contexto = await getContextGPT(
        mensaje,
        hoy,
        fechaVencimientoCorregida
      );
      setContexto(contexto);
      setCargandoContexto(false);

      setCargandoPasos(true);
      const pasos = await getStepsGpt(mensaje);
      setPasos(pasos);
      setCargandoPasos(false);

      setCargandoAreas(true);
      const area = await getArea(mensaje, idAreas);
      setAreas(area);
      setCargandoAreas(false);

      setMostrarAceptarCancelar(true);
    } else {
      alert("Debe completar todos los campos");
    }
  };

  //indentifico al responsable del ticket

  useEffect(() => {
    // Obtener y establecer la hora de creación
    const horaActual = obtenerHoraActual();
    setHoraCreacion(horaActual);

    if (competencias !== "identificando competencias...") {
      setCargandoResponsables(true);
      console.log(
        "empresa responsable",
        emailEpresaResponsable,
        areas,
        competencias
      );
      withTimeout(
        getResponsable(
          businessEmail,
          areas,
          competencias,
          emailEpresaResponsable
        ),
        10000
      ) // 10 segundos de timeout
        .then((responsablesData) => {
          if (Array.isArray(responsablesData)) {
            setResponsables(responsablesData);
            console.log("responsablesData", responsablesData);
          } else {
            setResponsables([]);
            console.log("no se encontraron coincidencias");
          }
          setCargandoResponsables(false);
        })
        .catch((error) => {
          console.error("Error obteniendo los responsables:", error);
          setCargandoResponsables(false);
        });
    }
  }, [competencias]);

  const handleAceptar = async () => {
    if (responsables.length > 0) {
      let ticketRef = null;

      setLoadingTicket(true);

      if (
        userType === "agent" ||
        userType === "admin" ||
        userType === "supervisor"
      ) {
        try {
          ticketRef = await createTicketNew(
            title,
            businessName,
            "Media",
            fechaVencimientoCorregida,
            hoy,
            areas,
            "Pendiente",
            emailEpresaResponsable,
            businessEmail,
            mensaje,
            contexto,
            competencias,
            pasos,
            creator,
            responsables,
            currentUser.email,
            horaCreacion
          );

          // Enviando correo a cada responsable
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));

          responsables.map(async (email) => {
            sendMail(
              email,
              "¡Te han asignado un ticket en Soportick.com!",
              `<!DOCTYPE html>
              <html>
              <head>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <meta name="x-apple-disable-message-reformatting">
                  <style>
                      /* Estilos adicionales si son necesarios */
                  </style>
              </head>
              <body>
                  <div style="font-family: 'Arial', sans-serif; color: #333; padding: 30px; background: linear-gradient(to right, #ffffff, #bac7e554, #03c5c340);">
                      <strong style="color: #33487e; text-align:center; font-size: 30px;">Tienes la siguiente solicitud:</strong>
                      <p style="text-align: left; margin-left: 20px; font-size: 20px; color: #5723aa;">${mensaje}</p>
                      <p style="color: #4b66ad; text-align: left;">y fue creado por: <strong>${creator}</strong></p>
                      <p style="color: #b24fb1; text-align: left;">Para responder inicia sesión en <a href="https://app.soportick.com" style="color: #038b89; text-decoration: none;">Soportick.com</a>.</p>
                  </div>
                  <table role="presentation" width="100%" style="border-collapse: collapse; background-color: #092174;">
                      <tr>
                          <td style="text-align: center; padding: 20px;">
                              <img src="https://app.soportick.com/static/media/logo-banner.4f57a37547ff0be301d0.png" alt="Logo" width="150" style="width: 150px; height: auto; border: 0;">
                          </td>
                      </tr>
                  </table>
              </body>
              </html>
              `
            );
          });
        } catch (error) {
          console.log(error);
        } finally {
          alert("Ticket creado con éxito");
          navigate("/ticketResume", {
            state: {
              ticketId: ticketRef.id,
              title: title,
              businessName: businessName,
              priority: "Media",
              today: hoy,
              areas: areas,
              businessEmail: businessEmail,
              mensaje: mensaje,
              contexto: contexto,
              competencias: competencias,
              pasos: pasos,
              creator: creator,
              responsables: responsables,
              from: currentUser.email,
              horaCreacion: horaCreacion,
            },
          });
          setLoadingTicket(false);
          handleCloseModalAdjuntos();
        }
      }

      if (userType === "cliente") {
        try {
          ticketRef = await createTicketNew(
            title,
            businessName,
            "Media",
            fechaVencimientoCorregida,
            hoy,
            areas,
            "Pendiente",
            currentUser.email,
            businessEmail,
            mensaje,
            contexto,
            competencias,
            pasos,
            creator,
            responsables,
            currentUser.email,
            horaCreacion
          );

          // Enviando correo a cada responsable
          responsables.map(async (email) => {
            sendMail(
              email,
              "¡Te han asignado un ticket en Soportick.com!",
              `<!DOCTYPE html>
              <html>
              <head>
                  <style>
                      .footer-logo img {
                          width: auto;
                          height: 35px;
                      }
                  </style>
              </head>
              <body>
              <div style="font-family: 'Arial', sans-serif; color: #333; padding: 30px; background: linear-gradient(to right, #ffffff, #bac7e554, #03c5c340);">
              <strong style="color: #33487e; text-align:center; font-size: 30px; ">Tienes la siguiente solicitud:</strong>
              <p style="text-align: left; margin-left: 20px; font-size: 20px; color: #5723aa;">${mensaje}</p>
              <p style="color: #4b66ad; text-align: left; ">y fue creado por: <strong>${creator}</strong></p>
              <p style="color: #b24fb1; text-align: left; ">Para responder inicia sesión en <a href="https://app.soportick.com" style="color: #038b89; text-decoration: none;">Soportick.com</a>.</p>

          </div>
          <div class="footer-logo" style="background-color: #092174; text-align: center; padding: 20px; height: 35px; align-items: center; justify-content: center;">
          <img src="https://app.soportick.com/static/media/logo-banner.4f57a37547ff0be301d0.png" alt="Logo">
      </div>
              </body>
              </html>`
            );
          });
        } catch (error) {
          console.log(error);
        } finally {
          alert("Ticket creado con éxito. El ID del ticket es " + ticketRef.id);
          navigate("/ticketResume", {
            state: {
              ticketId: ticketRef.id,
              title: title,
              businessName: businessName,
              priority: "Media",
              today: hoy,
              areas: areas,
              businessEmail: businessEmail,
              mensaje: mensaje,
              contexto: contexto,
              competencias: competencias,
              pasos: pasos,
              creator: creator,
              responsables: responsables,
              from: currentUser.email,
              horaCreacion: horaCreacion,
            },
          });
          setLoadingTicket(false);
          handleCloseModalAdjuntos();
        }
      }
    } else {
      alert(
        "No se ha seleccionado ningún agente responsable. Verifique el contexto de su solicitud, procure ser más claro en el mensaje o seleccione un área manualmente para que la IA escoja un agente."
      );
    }
  };

  const handleCancelar = () => {
    window.location.reload();
  };

  return (
    <div className="containerCreateTicket">
      <h3 className="createTicketTitle">Menú de creación de Solicitud</h3>
      <div className="estructuraTicketIA">
        {botonPresionado ? (
          <div className="iaResult">
            <h4>Información Relevante</h4>
            <div className="DescriptionMessageTicket">
              <span>
                Creado por: <strong>{currentUser.displayName}</strong> con
                características tipo: <strong>{userType}</strong> el día:{" "}
                <strong>{hoy}</strong> para la empresa{" "}
                <strong>
                  {creator} y {emailEpresaResponsable}
                </strong>{" "}
                con fecha de vencimiento establecida el día:{" "}
                <strong>{fechaVencimientoCorregida}</strong>{" "}
                <span>Hora de creación: {horaCreacion}</span>
              </span>
            </div>

            <h4>Titulo Sugerido IA</h4>
            <div
              className="DescriptionMessageTicket"
              onClick={() => setEditingTitle(true)}
              onMouseEnter={() => setShowEditIcon(true)}
              onMouseLeave={() => setShowEditIcon(false)}
            >
              {cargandoTitulo ? (
                <div className="centerSpinner">
                  <div className="spinner2"> </div>
                  <p>🤖Identificando el título más apropiado...</p>
                </div>
              ) : editingTitle ? (
                <input
                  type="text"
                  className="inputEditable"
                  value={title}
                  onBlur={() => setEditingTitle(false)}
                  onChange={(e) => setTitle(e.target.value)}
                  autoFocus
                />
              ) : (
                <>
                  <span>{title}</span>
                  {showEditIcon && (
                    <span className="edit-icon">
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        shake
                        style={{ color: "#5723aa" }}
                      />
                    </span>
                  )}
                </>
              )}
            </div>

            <h4>Contexto Resolutivo</h4>
            <div
              className="DescriptionMessageTicket"
              onClick={() => setEditingContexto(true)}
              onMouseEnter={() => setShowEditIconContexto(true)}
              onMouseLeave={() => setShowEditIconContexto(false)}
            >
              {cargandoContexto ? (
                <div className="centerSpinner">
                  <div className="spinner2"> </div>
                  <p>🤖Identificando el mejor contexto...</p>
                </div>
              ) : editingContexto ? (
                <input
                  type="text"
                  className="inputEditable"
                  value={contexto}
                  onBlur={() => setEditingContexto(false)}
                  onChange={(e) => setContexto(e.target.value)}
                  autoFocus
                />
              ) : (
                <>
                  <span>{contexto}</span>
                  {showEditIconContexto && (
                    <span className="edit-icon">
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        shake
                        style={{ color: "#5723aa" }}
                      />
                    </span>
                  )}
                </>
              )}
            </div>

            <h4>Plan de trabajo</h4>
            <div
              className="DescriptionMessageTicket"
              onClick={() => setEditingPasos(true)}
              onMouseEnter={() => setShowEditIconPasos(true)}
              onMouseLeave={() => setShowEditIconPasos(false)}
            >
              {cargandoPasos ? (
                <div className="centerSpinner">
                  <div className="spinner2"> </div>
                  <p>
                    🤖Identificando los pasos más eficientes para resolver la
                    solicitud...
                  </p>
                </div>
              ) : editingPasos ? (
                <input
                  className="inputEditable"
                  type="text"
                  value={pasos}
                  onBlur={() => setEditingPasos(false)}
                  onChange={(e) => setPasos(e.target.value)}
                  autoFocus
                />
              ) : (
                <>
                  <span>{pasos}</span>

                  {showEditIconPasos && (
                    <span className="edit-icon">
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        shake
                        style={{ color: "#5723aa" }}
                      />
                    </span>
                  )}
                </>
              )}
            </div>

            <h4>Área o Departamento</h4>
            <div
              className={
                responsables.length === 0 && areas !== "Identificando áreas..."
                  ? "DescriptionMessageTicketRed"
                  : "DescriptionMessageTicket"
              }
              onClick={() => setEditingAreas(true)}
              onMouseEnter={() => setShowEditIconAreas(true)}
              onMouseLeave={() => setShowEditIconAreas(false)}
            >
              {cargandoAreas ? (
                <div className="centerSpinner">
                  <div className="spinner2"> </div>
                  <p>
                    🤖Identificando el departamento más adecuado para resolver
                    la solicitud
                  </p>
                </div>
              ) : editingAreas ? (
                <select
                  className="selectEditable"
                  value={areas}
                  onBlur={() => setEditingAreas(false)}
                  onChange={(e) => {
                    setAreas(e.target.value);
                  }}
                  autoFocus
                >
                  <option value="seleccion manual">Selección Manual</option>
                  {userType === "cliente" ? (
                    areasOptions.map((area) => (
                      <option key={area.id} value={area.name}>
                        {area.name}
                      </option>
                    ))
                  ) : (
                    <>
                      {idAreas.map((areaId) => (
                        <option key={areaId} value={areaId}>
                          {areaId}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              ) : (
                <>
                  <span className="agenteResponsable">{areas}</span>
                  {showEditIconAreas && (
                    <span className="edit-icon">
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        shake
                        style={{ color: "#5723aa" }}
                      />
                    </span>
                  )}
                </>
              )}
            </div>

            <h4>Actividad a Realizar</h4>
            <div
              className={
                responsables.length === 0 &&
                competencias !== "identificando competencias..."
                  ? "DescriptionMessageTicketRed"
                  : "DescriptionMessageTicket"
              }
              onClick={() => {
                if (areas === "área por definir") {
                  alert(
                    "Debe seleccionar un área válida para poder desplegar la lista de opciones"
                  );
                } else {
                  setEditingCompetencias(true);
                }
              }}
              onMouseEnter={() => setShowEditIconCompetencias(true)}
              onMouseLeave={() => setShowEditIconCompetencias(false)}
            >
              {cargandoAreas ? (
                <div className="centerSpinner">
                  <div className="spinner2"> </div>
                  <p>
                    🤖Identificado la actividad que realizarán nuestros agentes
                  </p>
                </div>
              ) : editingCompetencias ? (
                <select
                  className="selectEditable"
                  value={competencias}
                  onBlur={() => setEditingCompetencias(false)}
                  onChange={(e) => setCompetencias(e.target.value)}
                  autoFocus
                >
                  <option value="Selección Manual">
                    Seleccione un área válida
                  </option>

                  {actividades.map((actividad) => (
                    <option value={actividad.id} key={actividad.id}>
                      {actividad.competencia}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <>
                  <span className="agenteResponsable">{competencias}</span>
                  {showEditIconCompetencias && (
                    <span className="edit-icon">
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        shake
                        style={{ color: "#5723aa" }}
                      />
                    </span>
                  )}
                </>
              )}
            </div>

            <h4>Ticket Asignado a:</h4>
            <div
              className={
                responsables.length === 0 &&
                competencias !== "identificando competencias..."
                  ? "DescriptionMessageTicketRed"
                  : "DescriptionMessageTicket"
              }
            >
              {cargandoResponsables ? (
                <div className="centerSpinner">
                  <div className="spinner2"> </div>
                  <p>
                    🤖Buscando el agente más adecuado para resolver la
                    solicitud...
                  </p>
                </div>
              ) : (
                <div>
                  {responsables.length === 0 ? (
                    <p>
                      ❌ No se encontró agente, seleccione un área manualmente y
                      una actividad y la IA seleccionará al agente
                      automáticamente
                    </p>
                  ) : (
                    responsables.map((responsable) => (
                      // Utiliza "responsable" directamente como key y contenido del párrafo
                      <div key={responsable}>
                        <p className="agenteResponsable">{responsable}</p>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null}

        <span className="fechaVencimiento">Fecha de vencimiento:</span>

        <input
          onInputCapture={(e) => setFechaVencimiento(e.target.value)}
          className="InputTime"
          type="date"
          min={getCurrentDate()} // Establece la fecha mínima al día actual
        />

        <span className="fechaVencimiento">Para:</span>

        {(userType === "admin" ||
          userType === "agent" ||
          userType === "super") && (
          <>
            <div className="autocomplete2">
              <ThemeProvider theme={theme}>
                <div className="autocomplete2">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={empresas}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      ".MuiAutocomplete-listbox": { // Estilos para la lista desplegable
                        textAlign: "center", // Centra el texto de los ítems
                      },
                      ".MuiAutocomplete-option": { // Estilos para cada opción dentro de la lista
                        justifyContent: "center", // Centra el contenido de los ítems (si están en un contenedor flex)
                      },
                    }}
                    onChange={handleAreaChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: "100%",
                          fontFamily: "Poppins",
                          borderRadius: "10px", // Este será sobreescrito por el borderRadius del fieldset
                          textAlign: "center",
                          ".MuiInputBase-root": {
                            height: "30px",
                            fontSize: "0.75rem",
                            padding: "0px 10px",
                            ".MuiOutlinedInput-input": {
                              padding: "0px",
                              height: "20px",
                              background: "#ffff", // Fondo rosado muy claro
                            },
                            background: "#ffff", // Asegura que el fondo del componente entero sea rosado muy claro
                          },
                          ".MuiInputLabel-root": {
                            fontSize: "0.75rem",
                            top: "-12px",

                            width: "fit-content",

                          },
                          ".MuiOutlinedInput-root": {
                            alignItems: "center",
                            borderRadius: "20px", // Aplica el borderRadius deseado
                            "&.Mui-focused fieldset": {
                              borderColor: "#FACBFF", // Borde morado claro cuando está enfocado
                            },
                            "& fieldset": {
                              borderColor: "#FACBFF", // Borde morado claro
                              borderRadius: "20px", // Asegura que el fieldset también tenga el borderRadius
                            },
                          },
                        }}
                        
                        label="¿Dirigido a?"
                      />
                    )}
                  />
                </div>
              </ThemeProvider>
            </div>
            <span className="fechaVencimiento">Contenido de la Solicitud:</span>
            <textarea
              type="text"
              placeholder="Ingresa de forma detallada el contenido de tu solicitud. Es importante que sea lo más claro posible para que la IA pueda identificar el contexto y los pasos a seguir"
              className="inputCreateTicket"
              onChange={(e) => setMensaje(e.target.value)}
            />
          </>
        )}

        {userType === "cliente" && (
          <>
            <div className="autocomplete2">
              <ThemeProvider theme={theme}>
                <div className="autocomplete2">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={empresas_clientes}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      borderRadius: "10px",
                      textAlign: "center",
                    }}
                    onChange={(event, newValue) => {
                      setSelectedEmpresa(newValue.business);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: "100%",
                          fontFamily: "Poppins",
                          borderRadius: "10px", // Este será sobreescrito por el borderRadius del fieldset
                          textAlign: "center",
                          ".MuiInputBase-root": {
                            height: "30px",
                            fontSize: "0.75rem",
                            padding: "0px 10px",
                            ".MuiOutlinedInput-input": {
                              padding: "0px",
                              height: "20px",
                              background: "#ffff", // Fondo rosado muy claro
                            },
                            background: "#ffff", // Asegura que el fondo del componente entero sea rosado muy claro
                          },
                          ".MuiInputLabel-root": {
                            fontSize: "0.75rem",
                            top: "-12px",

                            width: "fit-content",

                          },
                          ".MuiOutlinedInput-root": {
                            alignItems: "center",
                            borderRadius: "20px", // Aplica el borderRadius deseado
                            "&.Mui-focused fieldset": {
                              borderColor: "#FACBFF", // Borde morado claro cuando está enfocado
                            },
                            "& fieldset": {
                              borderColor: "#FACBFF", // Borde morado claro
                              borderRadius: "20px", // Asegura que el fieldset también tenga el borderRadius
                            },
                          },
                        }}
                        
                        label="¿Dirigido a?"
                      />
                    )}
                  />
                </div>
              </ThemeProvider>
            </div>
            <span className="fechaVencimiento">Contenido de la Solicitud:</span>
            <textarea
              type="text"
              placeholder="Ingresa de forma detallada el mensaje del ticket"
              className="inputCreateTicket"
              onChange={(e) => setMensaje(e.target.value)}
            />
          </>
        )}

        {!mostrarAceptarCancelar ? (
          <button className="btnAI" onClick={constructTicket}>
            Crear Solicitud
          </button>
        ) : (
          <>
            <div className="rowButtons">
              <button className="okButton" onClick={handleOpenModalAdjuntos}>
                Validar
              </button>
              <button className="cancelButton" onClick={handleCancelar}>
                Cancelar
              </button>
            </div>
          </>
        )}
      </div>
      <Modal
        overlayClassName={"modal-overlay"}
        className={"modal-content"}
        isOpen={modalAdjuntosIsOpen}
        onRequestClose={handleCloseModalAdjuntos}
      >
        <h2 className="titleModal">Iniciar Ticket</h2>
        {loadingTicket ? <div className="spinner2"></div> : null}
        <div className="descriptionModal">
          <p>¿Seguro que desea crear el ticket?</p>
        </div>

        <div className="row">
          <button className="okButton" onClick={handleAceptar}>
            {" "}
            Crear Ticket{" "}
          </button>
          <button className="cancelButton" onClick={handleCloseModalAdjuntos}>
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CreateTicket;
