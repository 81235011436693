import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import logoLogin from "./img/logo-login.png";

export const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMessage("Por favor ingrese un email válido");
      return;
    }

    if (password.length < 6) {
      setErrorMessage("La contraseña debe tener al menos 6 caracteres.");
      return;
    }

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        setLoading(false);
        document.querySelector(".formContainer").classList.add("fade-Out");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setErrorMessage("Por favor, verifica tu correo electrónico antes de iniciar sesión");
        setLoading(false);
      }
    } catch (err) {
      setErrorMessage("Usuario o contraseña incorrecta");
      setLoading(false);
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <img className="logoLogin" src={logoLogin} alt="" />
        <span className="titleLogin">Iniciar Sesión</span>
        <form onSubmit={handleSubmit}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            name="email"
            required={true}
            type="text"
            placeholder="Email"
          />
          <input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required={true}
            type="password"
            placeholder="Contraseña"
            name="pass"
          />
          <button type="submit" disabled={loading}>
            Iniciar Sesión
          </button>
          <div className="forgotPasswordLink">
            <span
              onClick={() => navigate('/forgotPassword')}
              style={{ textDecoration: 'underline', color: 'purple', cursor: 'pointer', fontSize: '10px', textAlign: 'center', margin: 'auto' }}
            >
              ¿Olvidaste tu contraseña?
            </span>
          </div>
        </form>
        {loading && <div className="spinner"></div>}
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};
