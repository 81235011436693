import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useState } from "react";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  margin: 0,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    margin: 0,
    backgroundColor: theme.palette.mode === "light" ? "#0ba54b" : "#308fe8",
  },
}));

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props) {

  return (
    <Box sx={{ position: "relative", margin: 0 }}>
    </Box>
  );
}

export default function CustomizedProgressBars(props) {
const [porcentaje, setPorcentaje] = useState(0);

var porcentajeMensajes = (props.nroMensajes * 100) / 7;

if (porcentajeMensajes >= 100) {
    porcentajeMensajes = 100;

} 
    
  return (
    <Box sx={{ flexGrow: 1 }}>
      <FacebookCircularProgress />
      <h1>{props.nroMensajes} / 7</h1>

      <br />
      <BorderLinearProgress variant="determinate" value={porcentajeMensajes} />
    </Box>
  );
}
