import React, { useContext, useState, useEffect } from "react";
import "./style.scss";
import logo from "./components/img/logo-login.png";
import { Home } from "./components/Home";
import { Register } from "./components/Register";
import { Login } from "./components/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import GestionarTickets from "./components/GestionarTickets";
import Configuracion from "./components/Configuracion";
import NuevoTicket from "./components/NuevoTicket";
import TicketDetails from "./components/TicketDetails";
import RegisterBusiness from "./components/registerBusiness/RegisterBusiness";
import Whatsapp from "./components/Whatsapp";
import RegisterUser from "./components/RegisterUser";
import CreateTicket from "./components/IA/CreateTicket";
import Colaboradores from "./components/Colaboradores";
import Recompensas from "./components/recompensas/Recompensas";
import Parametrizacion from "./components/Parametrizacion";
import Clientes from "./components/Clientes";
import Areas from "./components/Areas";
import NewTicket from "./components/NewTicket";
import Registro from "./components/Registro";
import ImageUploader from "./components/ImageUploader";
import MyProfile from "./components/myProfile/MyProfile";
import StartNow from "./components/register/StartNow";
import RegistroUsuarios from "./components/RegistroUsuarios";
import Stats from "./components/Stats";
import { VerificarEmail } from "./components/VerificarEmail";
import Invitaciones from "./components/invitacion/Invitaciones";
import Archivados from "./components/archivados/Archivados";
import TicketResume from "./components/IA/TicketResume";
import ReactGA from "react-ga4";
import { ForgotPassword } from "./components/ForgotPass";
import SelectBusiness from "./components/selectBusiness/SelectBusiness";

function App() {
  const { currentUser } = useContext(AuthContext);
  const { userType } = useContext(AuthContext);
  const { numberOfAgents } = useContext(AuthContext);
  const { numberOfClients } = useContext(AuthContext);
  const { businessDescription } = useContext(AuthContext);
  const { businessPhoto } = useContext(AuthContext);
  const { businessAdminEmail } = useContext(AuthContext);
  const { businessName } = useContext(AuthContext);
  const { department } = useContext(AuthContext);
  const { status } = useContext(AuthContext);
  const { category } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const appClass = loading ? "" : "fade-in";

  useEffect(() => {
    let timeoutId;
    if (userType && currentUser) {
      setLoading(false);
    } else {
      timeoutId = setTimeout(() => setLoading(false), 500);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [userType, currentUser]);

  if (loading) {
    return (
      <div className="spinner-container">
        <img className="logo-init-container" src={logo} alt="" />
        <div className="spinner"></div>
      </div>
    );
  }

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <div className={appClass}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <ProtectedRoute>
                  {" "}
                  <Home />{" "}
                </ProtectedRoute>
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route
              path="GestionarTickets"
              element={
                <ProtectedRoute>
                  <GestionarTickets />
                </ProtectedRoute>
              }
            />
            <Route
              path="Configuracion"
              element={
                <ProtectedRoute>
                  <Configuracion />
                </ProtectedRoute>
              }
            />
            <Route
              path="NuevoTicket"
              element={
                <ProtectedRoute>
                  <NuevoTicket />
                </ProtectedRoute>
              }
            />
            <Route
              path="colaboradores"
              element={
                <ProtectedRoute>
                  <Colaboradores />
                </ProtectedRoute>
              }
            />
            <Route
              path="clientes"
              element={
                <ProtectedRoute>
                  <Clientes />
                </ProtectedRoute>
              }
            />
            <Route
              path="newTicket"
              element={
                <ProtectedRoute>
                  <NewTicket />
                </ProtectedRoute>
              }
            />
            <Route
              path="areas"
              element={
                <ProtectedRoute>
                  <Areas />
                </ProtectedRoute>
              }
            />
            <Route
              path="archivados"
              element={
                <ProtectedRoute>
                  <Archivados />
                </ProtectedRoute>
              }
            />
            <Route
              path="parametrizacion"
              element={
                <ProtectedRoute>
                  <Parametrizacion />
                </ProtectedRoute>
              }
            />
            <Route
              path="ticketDetails"
              element={
                <ProtectedRoute>
                  <TicketDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="myprofile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="registerBusiness"
              element={
                <ProtectedRoute>
                  <RegisterBusiness />
                </ProtectedRoute>
              }
            />

            <Route
              path="stats"
              element={
                <ProtectedRoute>
                  <Stats />
                </ProtectedRoute>
              }
            />
            <Route
              path="invitaciones"
              element={
                <ProtectedRoute>
                  <Invitaciones />
                </ProtectedRoute>
              }
            />

            <Route
              path="ticketResume"
              element={
                <ProtectedRoute>
                  <TicketResume />
                </ProtectedRoute>
              }
            />
                        <Route
              path="selectBusiness"
              element={
                <ProtectedRoute>
                  <SelectBusiness />
                </ProtectedRoute>
              }
            />
            <Route
              path="recompensas"
              element={
                <ProtectedRoute>
                  <Recompensas />
                </ProtectedRoute>
              }
            />
            <Route path="registerUser" element={<RegisterUser />} />
            <Route path="createTicket" element={<CreateTicket />} />
            <Route path="registro" element={<Registro />} />
            <Route path="image" element={<ImageUploader />} />
            <Route path="startNow" element={<StartNow />} />
            <Route path="registrarusuarios" element={<RegistroUsuarios />} />
            <Route path="verificaremail" element={<VerificarEmail />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />

            <Route
              path="Whatsapp"
              element={
                <ProtectedRoute>
                  <Whatsapp />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
