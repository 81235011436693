import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faHouse,
  faRankingStar,
} from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";

function Sidebar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const { userType: userTypeFromContext } = useContext(AuthContext);
  const [userType, setUserType] = useState(null);
  const { businessName } = useContext(AuthContext);
  const { businessPhoto } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false); 

  useEffect(() => {
    setUserType(userTypeFromContext);
  }, []);

  useEffect(() => {
    const navMenu = document.getElementById("rectangle");

    if (menuOpen) {
      navMenu.classList.add("show");
    } else {
      navMenu.classList.remove("show");
    }
  }, [menuOpen]);

  useEffect(() => {
    if (!currentUser) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    setSubMenuOpen(false);

  };

  const renderSubMenu = () => {
    if (subMenuOpen) {
      return (
        <div>
          <NavLink className="subMenu" to="/solicitud-simple">Solicitud Simple</NavLink>
          <NavLink className="subMenu" to="/solicitud-periodica">Solicitud Periódica</NavLink>
        </div>
      );
    }
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen); // Cambiar el estado del submenú
  };

  const logoBusiness = useMemo(
    () => (
      <div>
        <img src={businessPhoto} className="imgSide" alt="" />
        <div className="nameBusiness">{businessName}</div>
      </div>
    ),
    [businessPhoto, businessName]
  );

  if (loading) {
    return <div className="spinner"></div>;
  } else {
    return (
      <div
        id="rectangle"
        onMouseOver={abrir}
        onMouseLeave={cerrar}
        className="rectangle"
      >
        <button className="hamburger-menu" onClick={handleMenuClick}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        <div style={{ visibility: isHovering ? "visible" : "hidden" }}>
          {logoBusiness}
        </div>
        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/"
          >
            {isHovering ? "Inicio" : <FontAwesomeIcon icon={faHouse} />}
          </NavLink>
        </li>

        {(userType === "admin" ||
          userType === "agent" ||
          userType === "super") && (
          <>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link-create"
                to="/newTicket"
                //onClick={toggleSubMenu} 
              >
                {isHovering ? (
                  "Crear Solicitud"
                ) : (
                  <FontAwesomeIcon icon={faCirclePlus} />
                )}
              </NavLink>
              {renderSubMenu()}

            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/GestionarTickets"
              >
                {isHovering ? (
                  "Gestionar Solicitudes"
                ) : (
                  <FontAwesomeIcon icon={faTicket} />
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/archivados"
              >
                {isHovering ? (
                  "Solicitudes Archivadas"
                ) : (
                  <FontAwesomeIcon icon={faBoxArchive} />
                )}
              </NavLink>
            </li>
            <li>
            <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/recompensas"
          >
            {isHovering ? "Recompensas" : <FontAwesomeIcon icon={faGift} />}
          </NavLink>
            </li>
          </>
          
        )}

        {userType === "cliente" && (
          <>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link-create"
                to="/newTicket"
              >
                {isHovering ? (
                  "Crear Solicitud"
                ) : (
                  <FontAwesomeIcon icon={faCirclePlus} />
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/GestionarTickets"
              >
                {isHovering ? (
                  "Gestionar Solicitudes"
                ) : (
                  <FontAwesomeIcon icon={faTicket} />
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/archivados"
              >
                {isHovering ? (
                  "Solicitudes Archivadas"
                ) : (
                  <FontAwesomeIcon icon={faBoxArchive} />
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/invitaciones"
              >
                {isHovering ? (
                  "Invitaciones"
                ) : (
                  <FontAwesomeIcon icon={faAddressBook} />
                )}
              </NavLink>
            </li>
          </>
        )}

        {userType === "admin" && (
          <>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/areas"
              >
                {isHovering ? "Áreas" : <FontAwesomeIcon icon={faSitemap} />}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/colaboradores"
              >
                {isHovering ? "Agentes" : <FontAwesomeIcon icon={faUser} />}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/clientes"
              >
                {isHovering ? (
                  "Clientes"
                ) : (
                  <FontAwesomeIcon icon={faBriefcase} />
                )}
              </NavLink>
            </li>

            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/parametrizacion"
              >
                {isHovering ? (
                  "Parámetros"
                ) : (
                  <FontAwesomeIcon icon={faClipboardCheck} />
                )}
              </NavLink>
            </li>

            <li>
              <NavLink
                style={({ isActive }) => ({
                  background: isActive ? "#5723aa" : "",
                })}
                className="link"
                to="/Stats"
              >
                {isHovering ? (
                  "Estadísticas"
                ) : (
                  <FontAwesomeIcon icon={faChartColumn} />
                )}
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/myProfile"
          >
            {isHovering ? "Configurar" : <FontAwesomeIcon icon={faHammer} />}
          </NavLink>
        </li>
        <li>
        </li>
        <li className="closeSessionMenu">
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="linkSession"
            to="/login"
            onClick={() => signOut(auth)}
          >
            {isHovering ? (
              "Cerrar Sesión"
            ) : (
              <FontAwesomeIcon icon={faPowerOff} />
            )}
          </NavLink>
        </li>
      </div>
    );

    function abrir() {
      let side = document.getElementById("rectangle");
      side.classList.add("active");
      setIsHovering(true);
    }

    function cerrar() {
      let side = document.getElementById("rectangle");
      side.classList.remove("active");
      setIsHovering(false);
      setMenuOpen(false);
      setSubMenuOpen(false);
    }
  }
}
export default Sidebar;
