import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../context/AuthContext";
import { onSnapshot } from "firebase/firestore";
import { doc, setDoc, getDoc, deleteDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import {
  getAgents,
  queryAgents,
  getClients,
  getCompetencias,
  getUserArea,
  queryCompetencias,
  getBusinessPartner,
  queryAreas,
  queryBusinessPartner,
  queryAgentCompetencias,
  getAgentCompetencias,
  queryClients,
  queryAreasAgent,
  getAreaAgent,
} from "../Functions";
import Autocomplete from "@mui/material/Autocomplete";
import { act } from "react-dom/test-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

function RbClientsAgents(props) {
  const { currentUser } = useContext(AuthContext);
  const [cargandoEmpresa, setCargandoEmpresa] = useState(false);
  const [cargandoAgente, setCargandoAgente] = useState(false);
  const [cargandoCompetencia, setCargandoCompetencia] = useState(false);
  const [cargandoAgregar, setCargandoAgregar] = useState(false);
  const [cargandoArea, setCargandoArea] = useState(false);
  const [agents, setAgents] = useState([]);
  const [areas, setAreas] = useState([]);
  const [businessPartner, setBusinessPartner] = useState([]);
  const [areasAgent, setAreasAgent] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [BusinessActive, setBusinessActive] = useState("");
  const [cilenteActive, setClienteActive] = useState("");
  const [AgentActive, setAgentActive] = useState("");
  const [areaActive, setAreaActive] = useState("");
  const [agentArea, setAgentArea] = useState("");
  const [competencias, setCompetencias] = useState([]);
  const [agentCompetencias, setAgentCompetencias] = useState([]);
  const { businessEmail } = useContext(AuthContext);
  const [selectedCompetencia, setSelectedCompetencia] = useState(null);

  //manejadores del cambio del autocomplete.

  const handleAreaChange = (event, newValue) => {
    setSelectedArea(newValue); // Actualiza manualmente el valor del estado
  };

  const handleCompetenciaChange = (event, newValue) => {
    setSelectedCompetencia(newValue); // Actualiza manualmente el valor del estado
  };

  const handleClienteChange = (event, newValue) => {
    setSelectedEmpresa(newValue); // Actualiza manualmente el valor del estado
  };

  // agrego agentes
  const handleAddCliente = async () => {
    setCargandoAgregar(true);
    try {
      /*  */

      await setDoc(
        doc(
          db,
          "business",
          businessEmail,
          "agents",
          selectedAgent,
          "businessPartner",
          selectedEmpresa.email
        ),
        {
          name: selectedEmpresa.name,
          email: selectedEmpresa.email,
        }
      );
      setCargandoAgregar(false);
    } catch (error) {
      console.log(error);
    }
  };

  // agregar todos los clientes al mismo tiempo

  const handleAddAllClients = async () => {
    setCargandoAgregar(true); // Comienza mostrando el indicador de carga
    try {
      const batch = writeBatch(db); // Usamos un batch para optimizar las escrituras en Firebase

      empresas.forEach((empresa) => {
        const businessDocRef = doc(
          db,
          "business",
          businessEmail,
          "agents",
          selectedAgent,
          "businessPartner",
          empresa.email
        );
        batch.set(businessDocRef, {
          name: empresa.name,
          email: empresa.email,
        });
      });

      await batch.commit(); // Ejecuta todas las operaciones del batch
      setCargandoAgregar(false); // Oculta el indicador de carga una vez completado
    } catch (error) {
      console.error("Error al agregar todos los clientes:", error);
      setCargandoAgregar(false); // Asegúrate de ocultar el indicador de carga incluso si hay un error
    }
  };

  //agrego las áreas asociadas al agente:

  const handleAddArea = async () => {
    setCargandoAgregar(true);
    try {
      await setDoc(
        doc(
          db,
          "business",
          businessEmail,
          "agents",
          AgentActive,
          "areas",
          selectedArea.id // area
        ),
        {
          area: selectedArea.name,
        }
      );

      setCargandoAgregar(false);
    } catch (error) {}
  };

  //agrego todas las áreas al mismo tiempo:

  const handleAddAllAreas = async () => {
    setCargandoAgregar(true); // Indicador de carga

    const batch = writeBatch(db); // Usamos un batch para optimizar las escrituras en Firebase

    areas.forEach((area) => {
      const businessDocRef = doc(
        db,
        "business",
        businessEmail,
        "agents",
        AgentActive,
        "areas",
        area.id
      );
      batch.set(businessDocRef, {
        area: area.name,
      });
    });

    try {
      await batch.commit(); // Ejecuta todas las operaciones del batch
      setCargandoAgregar(false); // Oculta el indicador de carga
    } catch (error) {
      console.error("Error al agregar todas las áreas:", error);
      setCargandoAgregar(false); // Manejo de errores
    }
  };

  const handleAddCompetencia = async () => {
    setCargandoCompetencia(true);
    //agrego el agente a la empresa del cliente...
    try {
      await setDoc(
        doc(
          db,
          "business",
          businessEmail,
          "agents",
          selectedAgent,
          "businessPartner",
          BusinessActive,
          "competencias",
          selectedCompetencia.competencia
        ),
        {
          competencia: selectedCompetencia.competencia,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddAllCompetencias = async () => {
    if (!selectedAgent || !BusinessActive || !agentArea) {
      console.log("Agente, negocio o área no seleccionados");
      return;
    }

    setCargandoCompetencia(true);
    const batch = writeBatch(db);

    competencias.forEach((competencia) => {
      const businessCompetenciaRef = doc(
        db,
        "business",
        businessEmail,
        "agents",
        selectedAgent,
        "businessPartner",
        BusinessActive,
        "competencias",
        competencia.id
      );
      batch.set(businessCompetenciaRef, {
        competencia: competencia.competencia,
      });
    });

    try {
      await batch.commit();
      console.log("Todas las competencias han sido agregadas exitosamente");
    } catch (error) {
      console.error("Error al agregar todas las competencias:", error);
    } finally {
      setCargandoCompetencia(false);
    }
  };

  // borro el cliente asociado al agente
  const handleDeleteCliente = async (businessEmail, agentActive, item) => {
    let userResponse = window.confirm(
      "¿Deseas devincular el cliente del agente?"
    );

    if (userResponse) {
      try {
        setCargandoEmpresa(true);
        deleteDoc(
          doc(
            db,
            "business",
            businessEmail,
            "agents",
            agentActive,
            "businessPartner",
            item
          )
        );
        setCargandoEmpresa(false);
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  // borro el cliente asociado al agente
  const handleDeleteArea = async (businessEmail, AgentActive, item) => {
    let userResponse = window.confirm(
      "¿Deseas desvincular el agente de esta área?"
    );

    if (userResponse) {
      try {
        deleteDoc(
          doc(
            db,
            "business",
            businessEmail,
            "agents",
            AgentActive,
            "areas",
            item
          )
        );
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  // borro la competencia asociada al agente
  const handleDeleteCompetencias = async (
    businessEmail,
    AgentActive,
    clienteActive,
    item
  ) => {
    let userResponse = window.confirm(
      "¿Deseas desvincular el agente de esta competencia?"
    );

    if (userResponse) {
      try {
        await deleteDoc(
          doc(
            db,
            "business",
            businessEmail,
            "agents",
            AgentActive,
            "businessPartner",
            clienteActive,
            "competencias",
            item
          )
        );
        console.log("Competencia eliminada correctamente");
      } catch (error) {
        console.error("Error al eliminar la competencia:", error);
      }
    }
  };

  const areaName = selectedEmpresa ? selectedEmpresa.name : "";

  // cargo clientes en el select ---------------------

  useEffect(() => {
    if (businessEmail) {
      setCargandoEmpresa(true);
      const q = queryClients(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getClients(querySnapshot);
        setEmpresas(items);
        setCargandoEmpresa(false);
      });
      setSelectedEmpresa(empresas[1]);
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  //cargo competencias en el select...... .

  useEffect(() => {
    if (businessEmail && areaActive) {
      setCargandoCompetencia(true);
      const q = queryCompetencias(businessEmail, areaActive);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getCompetencias(querySnapshot);
        setCompetencias(items);
        setCargandoCompetencia(false);
      });
      return unsubscribe;
    }
  }, [businessEmail, areaActive]);

  // cargo áreas en el select ---------------------

  useEffect(() => {
    if (businessEmail) {
      setCargandoArea(true);
      const q = queryAreas(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getUserArea(querySnapshot);
        setAreas(items);
        setCargandoArea(false);
      });
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  // cargo el área del agente

  const getAgentArea = async (email) => {
    const docRef = doc(db, "users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setAgentArea(docSnap.data().department);
    } else {
      console.log("Error encontrando el área a la que pertenece el agente");
    }
  };

  // 1 ......Busco los agentes registrados

  useEffect(() => {
    if (businessEmail) {
      const q = queryAgents(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAgents(querySnapshot);
        setAgents(items);
      });

      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  // 2 ......Busco las áreas a asignadas a los agentes

  useEffect(() => {
    // Asegurarse de que el efecto se ejecute solo cuando selectedAgent cambie y no esté vacío
    if (selectedAgent) {
      setCargandoArea(true); // Indicar inicio de carga
      const q = queryAreasAgent(selectedAgent, businessEmail); // Obtener la query basada en el agente seleccionado
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreaAgent(querySnapshot); // Obtener áreas del agente
        setAreasAgent(items); // Actualizar el estado con las áreas del agente seleccionado
        setCargandoArea(false); // Indicar fin de carga
      });
      return () => unsubscribe(); // Limpiar el observer al desmontar o cambiar el agente
    }
  }, [selectedAgent]); // Dependencia: selectedAgent

  // 3 ......busco los clientes asociados al agente *****

  useEffect(() => {
    if (selectedAgent !== "") {
      setCargandoEmpresa(true);
      setBusinessActive("");
      const q = queryBusinessPartner(selectedAgent, businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getBusinessPartner(querySnapshot);
        setBusinessPartner(items);
        setCargandoEmpresa(false);
      });
      return unsubscribe;
    }
  }, [AgentActive]);

  // 4 ......busco las competencias asociadas al agente *****

  useEffect(() => {
    if (selectedEmpresa !== "" && BusinessActive !== "" && areaActive !== "") {
      setCargandoCompetencia(true);
      const q = queryAgentCompetencias(
        BusinessActive,
        businessEmail,
        AgentActive
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAgentCompetencias(querySnapshot);
        setAgentCompetencias(items);
        setCargandoCompetencia(false);
      });
      return unsubscribe;
    } else {
      setAgentCompetencias([]);
    }
  }, [BusinessActive]);

  return (
    <div>
      <div className="bigCard2">
        <div className="bigCardContent">
          <div className="centerDivBigCard">
            <h1 className="titleBigCard">Relación Agente | Cliente</h1>
            <div className="divTextAgents">
              <span className="textAgents">
                Asigna de manera meticulosa a cada agente las empresas y
                competencias que gestionará en tu organización. Esto es crucial,
                puesto que estos parámetros serán empleados por la inteligencia
                artificial para identificar al agente más apto para atender cada
                solicitud.
              </span>
              <hr className="hrAgents" />
            </div>
            <div className="threeColumns">
              <div className="columnsDiv">
                <h3 className="titleColumns">Agentes</h3>
                {cargandoAgente ? (
                  <p>Cargando...</p>
                ) : (
                  <div className="businessPartners">
                    {agents.map((agent, index) => (
                      <div key={index}>
                        <li
                          onClick={(e) => {
                            setBusinessActive("");
                            setSelectedAgent(agent.email);
                            setAgentActive(agent.email);
                            getAgentArea(agent.email);
                          }}
                          className={
                            AgentActive === agent.email
                              ? "liAgent2Active"
                              : "liAgent2"
                          }
                        >
                          {" "}
                          🤖 {agent.name}
                        </li>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="columnsDiv">
                <h3 className="titleColumns">Áreas</h3>
                {selectedAgent === "" ? (
                  <p className="textAgents">
                    Selecciona un agente administrar las áreas asignadas
                  </p>
                ) : (
                  <div>
                    <Autocomplete
                      disablePortal
                      id="combo-box-areas"
                      options={areas} // Asegúrate de que este estado contenga las áreas a mostrar
                      getOptionLabel={(option) => option.name} // Asumiendo que cada área tiene una propiedad 'name'
                      sx={{ width: "100%", fontFamily: "Poppins" }}
                      onChange={handleAreaChange} // Función para manejar el cambio de selección
                      renderInput={(params) => (
                        <TextField {...params} label="Áreas" />
                      )}
                    />

                    {selectedArea && selectedArea !== "" && (
                      <button className="AddItemList" onClick={handleAddArea}>
                        Agregar
                      </button>
                    )}

                    <button className="AddAllItems" onClick={handleAddAllAreas}>
                      Asignar todas las areas
                    </button>

                    <div className="businessPartners">
                      {cargandoArea || cargandoAgregar ? (
                        <p>Cargando...</p>
                      ) : areasAgent.length > 0 ? (
                        areasAgent.map((bpartner, index) => (
                          <div key={index}>
                            <li
                              className={
                                areaActive === bpartner.area
                                  ? "liAreaActive"
                                  : "liArea"
                              }
                              onClick={() => setAreaActive(bpartner.area)}
                            >
                              🏛️ {bpartner.area}
                              <button
                                onClick={(e) =>
                                  handleDeleteArea(
                                    businessEmail,
                                    AgentActive,
                                    bpartner.area
                                  )
                                }
                                className="deleteListArea"
                              >
                                <FontAwesomeIcon icon={faDeleteLeft} />
                              </button>
                            </li>
                          </div>
                        ))
                      ) : (
                        <p>No hay áreas asignadas</p> // Mensaje cuando no hay áreas
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="columnsDiv">
                <h3 className="titleColumns">Clientes</h3>
                {areaActive === "" ? (
                  <p className="textAgents">Selecciona un agente</p>
                ) : (
                  <div>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={empresas} // Asegúrate de que este estado contenga las empresas a mostrar
                      getOptionLabel={(option) => option.name} // Asumiendo que cada cliente tiene una propiedad 'name'
                      sx={{ width: "100%", fontFamily: "Poppins" }}
                      onChange={handleClienteChange} // Función para actualizar el valor seleccionado
                      renderInput={(params) => (
                        <TextField {...params} label="Clientes" />
                      )}
                    />
                    {selectedEmpresa && selectedEmpresa !== "" && (
                      <button
                        className="AddItemList"
                        onClick={handleAddCliente}
                      >
                        Agregar
                      </button>
                    )}
                    <button
                      className="AddAllItems"
                      onClick={handleAddAllClients}
                    >
                      Asignar todos los clientes
                    </button>

                    <div className="businessPartners">
                      {cargandoEmpresa || cargandoAgregar ? (
                        <p>Cargando...</p>
                      ) : businessPartner.length > 0 ? (
                        businessPartner.map((bpartner, index) => (
                          <div key={index}>
                            <li
                              className={
                                BusinessActive === bpartner.email
                                  ? "liAgentActive"
                                  : "liAgent"
                              }
                              onClick={(e) => {
                                setBusinessActive(bpartner.email);
                                setClienteActive(bpartner.name);
                              }}
                            >
                              💼 {bpartner.name}
                              <button
                                onClick={(e) =>
                                  handleDeleteCliente(
                                    businessEmail,
                                    AgentActive,
                                    bpartner.email
                                  )
                                }
                                className="deleteListArea"
                              >
                                <FontAwesomeIcon icon={faDeleteLeft} />
                              </button>
                            </li>
                          </div>
                        ))
                      ) : (
                        <p>No hay clientes asignados a este agente</p> // Mensaje cuando no hay clientes
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="columnsDiv">
                <h3 className="titleColumns">Competencias</h3>
                {cilenteActive === "" ? (
                  <p className="textAgents">Selecciona una empresa y un área</p>
                ) : (
                  <div>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={competencias}
                      getOptionLabel={(option) => option.competencia}
                      sx={{ width: "100%", fontFamily: "Poppins" }}
                      onChange={handleCompetenciaChange} // Función para actualizar el valor seleccionado
                      renderInput={(params) => (
                        <TextField {...params} label="Competencias" />
                      )}
                    />

                    {selectedCompetencia && selectedCompetencia !== "" && (
                      <button
                        className="AddItemList"
                        onClick={handleAddCompetencia}
                      >
                        Agregar
                      </button>
                    )}

                    <button
                      className="AddAllItems"
                      onClick={handleAddAllCompetencias}
                    >
                      Asignar todas las competencias de {areaActive}
                    </button>

                    <p></p>
                    <p />
                    <div className="businessPartners">
                      {cargandoCompetencia ? (
                        <p>Cargando...</p>
                      ) : agentCompetencias.length > 0 ? (
                        agentCompetencias.map((competencia, index) => (
                          <div key={index}>
                            <li className="liAgent3">
                              ⭐ {competencia.competencia}
                              <button
                                onClick={(e) => {
                                  handleDeleteCompetencias(
                                    businessEmail,
                                    AgentActive,
                                    BusinessActive,
                                    competencia.competencia
                                  );
                                }}
                                className="deleteListArea"
                              >
                                <FontAwesomeIcon icon={faDeleteLeft} />
                              </button>
                            </li>
                          </div>
                        ))
                      ) : (
                        <p>No hay competencias asignadas a {cilenteActive}</p> // Mensaje cuando no hay competencias
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default RbClientsAgents;
