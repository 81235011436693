import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Boards from "./Boards";
import { getBoards } from "../Functions";
import "./Boards.css";
import Spinner from "./Spinner";

function SelectBusiness() {
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para el spinner

  useEffect(() => {
    console.log("Email de negocio:", businessEmail); // Verifica que businessEmail sea correcto
    getBoards(businessEmail).then(boards => {
      console.log("Tableros cargados:", boards);
      setBoards(boards);
      setLoading(false); // Desactiva el spinner después de cargar los tableros
    }).catch(error => {
      console.error("Error al cargar los tableros:", error);
      setLoading(false); // Desactiva el spinner si hay un error
    });
  }, [businessEmail]);

  return (
    <div className="selectBusiness Container">
      <Navbar
        name={currentUser.displayName}
        photo={currentUser.photoURL}
        caption="NavBar"
      />
      <main className="content">
        <section className="businessSection">
          <h2 className="businessName">Soportick</h2>
          {loading ? <Spinner /> : <Boards boards={boards} />}
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default SelectBusiness;
