import React, { useContext, useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { getFunctions, httpsCallable } from "firebase/functions";
import { sendMail } from "../SendMail";
import {
  TextField,
  Input,
  Select,
  MenuItem,
  Typography,
  Button,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/AuthContext";
import { getAreas, queryAreas, getAgents, queryAgents } from "../Functions";
import { deleteUser as removeUser } from "firebase/auth";
import {
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import Modal from "react-modal";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { runTransaction } from "firebase/firestore";
import sendEmail from "../SendMail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

Modal.setAppElement("#root");

function RbAgents() {
  const { currentUser } = useContext(AuthContext);
  const { businessName } = useContext(AuthContext);
  const [privilegios, setPrivilegios] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [agentName, setAgentName] = useState("");
  const [areas, setAreas] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedArea, setSelectedArea] = useState(areas[0]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [busqueda, setBusqueda] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteAgent, setDeleteAgent] = useState("");
  const itemsPerPage = 5;
  const { businessEmail } = useContext(AuthContext);

  const handleOpenModalDelete = () => {
    setModalIsOpen(true);
  };

  const handleCloseModalDelete = () => {
    setModalIsOpen(false);
  };

  function cleanEmail() {
    setAgentEmail("");
  }

  const handleDelete = async (agentEmail) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "business", businessEmail, "agents", agentEmail));

      await deleteDoc(doc(db, "agents", agentEmail));

      await deleteDoc(doc(db, "users", agentEmail)).then(() => {
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModalDelete();
    }
  };

  const handleReenviarInvitacion = async (email, name) => {
    let userResponse = window.confirm(
      "¿Desea reenviar la invitación a " + name + "?"
    );

    if (userResponse) {
      try {
        sendMail(
          email,
          "Invitación de registro como Agente en Soportick.com",
          `Hola ${name} te han invitado a registrarte en Soportick.com como agente en ${businessEmail}, para hacerlo, ingresa a https://app.soportick.com/registrarusuarios si ya estás registrado inicia sesión en la plataforma y ya podrás empezar a gestionar Tickets y ganar recompensas.`
        );
      } catch (error) {
        console.log(error);
      } finally {
        alert("¡Invitación enviada de forma satisfactoria!");
      }
    } else {
    }
  };

  const filteredAgents = agents.filter((agent) =>
  agent.name ? agent.name.toLowerCase().includes(busqueda.toLowerCase()) : false
);


  const currentItems = filteredAgents.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleTeam = async () => {
    setLoading(true);
    try {
      const userDocRef = doc(db, "agents", agentEmail);
      const userDocSnap = await getDoc(userDocRef);

      // Verifica si el usuario existe en la colección "agents"
      if (userDocSnap.exists()) {
        alert(
          "Este correo ya está registrado como agente. Pruebe agregando un correo distinto."
        );
      } else {
        // Si no existe, lo crea

        // Usar una transacción para realizar todas las escrituras simultáneamente
        await runTransaction(db, async (transaction) => {
          const commonData = {
            department: areaName,
            userType: privilegios,
            displayName: agentName,
            name: agentName,
            email: agentEmail,
            businessEmail: currentUser.email,
            businessName: businessName,
          };

          transaction.set(
            doc(db, "business", businessEmail, "agents", agentEmail),
            {
              ...commonData,
              category: "agent",
            }
          );

          transaction.set(doc(db, "users", agentEmail), {
            ...commonData,
            status: "pendiente",
            category: "agent",
          });

          transaction.set(userDocRef, commonData);
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      cleanEmail();
      sendMail(
        agentEmail,
        "Invitación de registro como Agente en Soportick.com",
        "Has sido invitado a registrarte como agente en Soportick.com. Para empezar a usar la plataforma debes registrarte en https://app.soportick.com/registrarusuarios si ya estás registrado inicia sesión en la plataforma y ya podrás empezar a gestionar Tickets y ganar recompensas."
      );
    }
  };

  const handleAreaChange = (event, newValue) => {
    setSelectedArea(newValue); // Actualiza manualmente el valor del estado
  };

  // La variable "areaName" almacena el nombre del área seleccionada en el Autocomplete
  const areaName = selectedArea ? selectedArea.name : "";

  useEffect(() => {
    if (businessEmail) {
      setLoading(true);
      const q = queryAreas(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreas(querySnapshot);
        setAreas(items);
        setLoading(false);
      });
      setSelectedArea(areas[0]); // Establece el primer valor del arreglo de áreas como predeterminado
      return unsubscribe;
    }
  }, [currentUser, businessEmail]);

  useEffect(() => {
    if (businessEmail) {
      setLoading(true);
      const q = queryAgents(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAgents(querySnapshot);
        setAgents(items);
      });
      return unsubscribe;
    }
  }, [currentUser, businessEmail]);

  return (
    <div>
      <div className="bigCard2">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">Agentes</h1>
            </div>
            <div className="divTextAgents">
              <span className="textAgents">
                Identifica quiénes serán tus colaboradores de equipo y asígnales
                privilegios, y un área de trabajo. Ellos recibirán un Email
                indicando que fueron invitados a Soportick y podrán acceder a la
                plataforma.
              </span>
              <hr className="hrAgents" />
            </div>

            <TextField
              id="outlined-basic"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: { fontFamily: "Poppins", fontSize: "calc(100vh/60)" },
              }}
              InputProps={{
                style: { fontFamily: "Poppins", fontSize: "calc(100vh/60)", height: "calc(100vh/20)" },
              }}
              label="Email"
              variant="outlined"
              value={agentEmail}
              onChange={(e) => {
                setAgentEmail(e.target.value);
              }}
            />

            <p></p>
            <TextField
              id="outlined-basic2"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: { fontFamily: "Poppins", fontSize: "calc(100vh/60)" },
              }}
              InputProps={{
                style: { fontFamily: "Poppins", fontSize: "calc(100vh/60)", height: "calc(100vh/20)" },
              }}
              label="Nombre y Apellido"
              variant="outlined"
              value={agentName}
              onChange={(e) => {
                setAgentName(e.target.value);
              }}
            />

            <p></p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={privilegios}
              style={{ width: "100%", fontFamily: "Poppins" }}
              onChange={(e) => {
                setPrivilegios(e.target.value);
              }}
            >
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"super"}>Supervisor</MenuItem>
              <MenuItem value={"user"}>Agente</MenuItem>
            </Select>
            <p></p>

            <p></p>
          </div>
          <input
            type="text"
            placeholder="Buscar Agente"
            onChange={(e) => setBusqueda(e.target.value)}
            onClick={() => setCurrentPage(0)}
            className="inputBusqueda2"
            autoComplete="off"
          />
          <div className="agents">
            {currentItems.map((agent, index) => (
              <div key={index} className="agentsGlobe">
                <div className="agentInfo">
                  🤖 <strong>Nombre:</strong> &nbsp;{agent.name} &nbsp;{" "}
                  <strong>Email:</strong> &nbsp; {agent.email} &nbsp;{" "}
                  <strong>Tipo:</strong>&nbsp; {agent.userType} &nbsp;{" "}
                </div>
                <button
                  className="BtnReenviarAgents"
                  onClick={() =>
                    handleReenviarInvitacion(agent.email, agent.name)
                  }
                >
                  Reenviar invitación
                </button>
                <button
                  onClick={() => {
                    handleOpenModalDelete();
                    setDeleteAgent(agent.email);
                  }}
                  className="deleteListArea"
                >
                  <FontAwesomeIcon icon={faDeleteLeft} />
                </button>
              </div>
            ))}
          </div>

          {agentEmail ? (
            <Box sx={{ "& > button": { m: 1 } }}>
              <LoadingButton
                size="small"
                style={{
                  backgroundColor: "#FF3D00",
                  color: "white",
                  width: "95%",
                }}
                onClick={handleTeam}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                <span>Agregar</span>
              </LoadingButton>
            </Box>
          ) : (
            ""
          )}
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={""}
            pageCount={Math.ceil(filteredAgents.length / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"ticketsNuevos__pagination"}
            activeClassName={"active"}
            previousClassName={"custom-previous-button"}
            nextClassName={"custom-next-button"}
            previousLinkClassName={"btnAnterior"}
            nextLinkClassName={"btnSiguiente"}
            pageClassName={"custom-page-number"}
            pageLinkClassName={"custom-page-link"}
          />

          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModalDelete}
          >
            <h2 className="titleModal">¿Desea Eliminar Cliente?</h2>
            <div className="descriptionModal">
              <p>
                Se borrará de todos los registros, aunque puede agregarlo
                nuevamente y enviar una invitación
              </p>
            </div>
            <div className="row">
              <button
                className="btnCloseModal"
                onClick={() => handleDelete(deleteAgent)}
              >
                Sí
              </button>
              <button
                className="btnCloseModal"
                onClick={handleCloseModalDelete}
              >
                No
              </button>
            </div>
          </Modal>

          {loading ? <div className="spinner"></div> : ""}
        </div>
        <hr />
      </div>
    </div>
  );
}

export default RbAgents;
